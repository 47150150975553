<template>
  <div class="step__block-wrapper">
    <div>
      <span>{{ item.title }}</span>
    </div>
    <div class="step__block-wrapper-right">
      <span>{{ item.unit }}</span>
      <input type="number" v-model.number="qty" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineModel, watch, onMounted, computed } from "vue";
import { DeliveryItem } from "@/app/domain/entities/delivery";
import { PricingService } from "@/app/services/pricing.service";
import { useNewEstimateStore } from "@/stores/new/estimateStore";

type DeliveryItemProps = {
  item: DeliveryItem;
};

const estimateStore = useNewEstimateStore();
const isHalfInstallation = computed(
  () => estimateStore.estimate.isHalfInstallation,
);

const props = defineProps<DeliveryItemProps>();
const qty = defineModel<number>("qty");

const pricingService = new PricingService();

onMounted(() => {
  const price = pricingService.getDeliveryPrices(props.item);
  props.item.update({ price });
});

watch(
  () => [qty.value, props.item.price, isHalfInstallation.value],
  () => {
    const sum = pricingService.calculateDeliveryPrice(props.item);
    props.item.update({ sum });
  },
  { immediate: true, deep: true },
);
</script>

<style lang="scss" scoped>
.step__block-extra-add {
  margin-top: 10px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 10px;
  color: rgba(64, 77, 99, 0.6);
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.step__block-extra-unit {
  padding-right: 10px;
}

.step__block-extra {
  /*padding: 20px;*/
  /*background-color: #fff;*/
  /*border-radius: 10px;*/
  /*min-height: 450px;*/
}

.step__block-extra-container {
  display: grid;
  row-gap: 5px;
}

.step__block-extra-block {
  display: grid;
  grid-template-columns: 1fr repeat(4, 50px);
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  column-gap: 20px;
}

.step__block-extra-block div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.step__block-extra-block div button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.step__block-extra-block div input {
  width: 50px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #f2f2f2;
  background: #fbfbfb;
  text-align: center;
  padding: 0 10px;
}

.step__block-extra-block div:first-child {
  align-items: flex-start;
}

.step__block-extra-close {
  color: rgba(64, 77, 99, 0.3);
}

.step__block-extra-block--head {
  font-weight: 600;
  padding-bottom: 15px;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 10px;
}

.step__block-list {
  padding: 15px 0;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: grid;
  row-gap: 5px;
}

.step__block-list--first {
  padding-top: 15px;
}

.step__block-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}

.step__block-wrapper-right {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.step__block-wrapper-right input {
  width: 100px;
  height: 35px;
  border-radius: 20px;
  background: #fff;
  padding: 0 17px;
}

.step__block-table {
  padding: 15px 0;
  display: grid;
  row-gap: 5px;
}

.step__block-item {
  display: grid;
  grid-template-columns: 1fr repeat(4, 50px);
  column-gap: 20px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-item--head {
  font-weight: 600;
}

.step__block-item-unit span {
  padding-right: 10px;
}

.step__block-item div:first-child {
  align-items: flex-start;
}

.step__block-item div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.step__block-field {
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  border-bottom: 1px solid #d2d8e3;
}

.step__block-field.step__block-field--top {
  padding-top: 0;
  border-top: none;
}

.step__block-field h3 {
  color: #404d63;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field--estimate h3 {
  font-size: 22px;
}

.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field--estimate .step__block-field-price {
  font-size: 22px;
}

.step__block-field-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
