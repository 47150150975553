import { Space } from "@/app/domain";
import {
    createMountsCGFFrameConfig,
    createMountsCGFBottomFrameSortConfig,
    createMountsCGFSideFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsMullionsBottomConfig,
    createMountsMullionsTopConfig,
    createMountsToptrackSlidingDoorConfig,
    createMountsMullionsHorizontalConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";
import { LineDividerCalculationService } from "@/app/services/line-dividers.service";
import { CGF350Code } from "@/app/constants";

const createSlidingDoorMountsToptrackSlidingDoorConfig = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    space: Space,
): IComponentConfig => {
    return createMountsToptrackSlidingDoorConfig().qty(1).build();
};

const createSlidingDoorMountsCGFSideFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const sides = 2;

    const qty = totalHeight * sides;

    return createMountsCGFSideFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const qty = totalWidth - doorWidth;

    return createMountsCGFBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsTopConfig = (
    space: Space,
): IComponentConfig => {
    const doorWidth = MountsService.getDoorTotalWidth(space);
    const sides = 2;

    const qty = doorWidth * sides;

    return createMountsMullionsTopConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsBottomConfig = (
    space: Space,
): IComponentConfig => {
    const doorWidth = MountsService.getDoorTotalWidth(space);
    const sides = 2;

    const qty = doorWidth * sides;

    return createMountsMullionsBottomConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsHorizontalConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);

    const horizontalLinesQty =
        LineDividerCalculationService.calculateHorizontalDividers(
            CGF350Code,
            totalHeight,
        );

    const sides = 2;

    const qty = totalWidth * horizontalLinesQty * sides;

    return createMountsMullionsHorizontalConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFFrameConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const cgfSidesQty = 4;
    const doorMultiplier = MountsService.isForDoubleDoor(space) ? 4 : 1;

    const qty = totalHeight * cgfSidesQty * doorMultiplier;

    return createMountsCGFFrameConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const sides = 2;

    const horizontalGasket = (totalWidth - doorWidth) * sides;
    const verticalGasket = totalHeight * sides;

    const qty = (horizontalGasket + verticalGasket) * sides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

export const CGF350SlidingDoorsConfigs = (space: Space) => [
    createSlidingDoorMountsCGFSideFrameSortConfig(space),
    createSlidingDoorMountsToptrackSlidingDoorConfig(space),
    createSlidingDoorMountsCGFBottomFrameSortConfig(space),
    createSlidingDoorMountsMullionsTopConfig(space),
    createSlidingDoorMountsMullionsBottomConfig(space),
    createSlidingDoorMountsMullionsHorizontalConfig(space),
    createSlidingDoorMountsCGFFrameConfig(space),
    createSlidingDoorMountsGlazingGasketConfig(space),
];
