<template>
  <select-dict
    v-model="installationPlaceCode"
    :select-list="installationPlaces"
  />
</template>

<script setup lang="ts">
import { defineModel } from "vue";

import SelectDict from "@/components/UI/SelectDict.vue";

import { useDictStore } from "@/stores/dictStore";

const dictStore = useDictStore();

const installationPlaceCode = defineModel<string>({ default: "" });
const installationPlaces = dictStore.getInstallationPlaces();
</script>

<style lang="scss" scoped></style>
