<template>
  <select-dict v-model="glassThinkness" :selectList="glassThinknessList" />
</template>

<script setup lang="ts">
import { computed, defineModel, ref, watch } from "vue";

import SelectDict from "@/components/UI/SelectDict.vue";

import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { useDoorsStore } from "@/stores/new/doors.store";

import { GlassThinknessService } from "@/app/services/glass-thinkess.service";
import { Dictionary } from "@/models/dictionary";

const estimateStore = useNewEstimateStore();
const doorStore = useDoorsStore();
const glassThinknessService = new GlassThinknessService();

const glassThinknessList = ref<Dictionary[]>([]);
const glassThinkness = defineModel<string>();

const installedSystemCode = computed(
  () => estimateStore.estimate.installedSystemCode,
);
const doorType = computed(() => doorStore.activeDoor?.structureType);

watch(
  () => [installedSystemCode.value, doorType.value],
  () => {
    glassThinknessList.value = glassThinknessService.getDoorGlassThinkness();
    // glassThinkness.value = "";
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped></style>
