<template>
  <select-dict
    v-model="overdoorMaterialType"
    :selectList="materialTypes"
    :select-first-item="true"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";

import SelectDict from "@/components/UI/SelectDict.vue";

import { useDictStore } from "@/stores/dictStore";
import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { useOverdoorStore } from "@/stores/new/overdoor.store";

const dictStore = useDictStore();
const estimateStore = useNewEstimateStore();
const overdoorStore = useOverdoorStore();

const overdoorMaterialType = computed({
  get: () => overdoorStore.overdoorMaterialType,
  set: (value) => (overdoorStore.overdoorMaterialType = value),
});

console.log("select", overdoorMaterialType);

const installedSystem = computed(
  () => estimateStore.estimate.installedSystemCode,
);
const materialTypes = dictStore.getFillerTypes(installedSystem.value);
</script>

<style lang="scss" scoped></style>
