type UpdateParams = Record<string, unknown>;

import { toRaw } from "vue";
import { Pricing } from "./pricing";
import { toReactive } from "@vueuse/core";

export interface IUpdateableComponent {
    update(data: UpdateParams): void;
}

export class UpdatableComponent implements IUpdateableComponent {
    /**
     * Updates the properties of the current instance with the provided data.
     *
     * @param data - An object containing key-value pairs to update the instance with.
     *               The keys should match the properties of the instance.
     *
     * If the key is "price" or "sum", the corresponding field is updated using the `update` method of the `Pricing` class.
     * Otherwise, the value of the field is directly set to the provided value.
     *
     * @remarks
     * - The method uses `toReactive` to get a reactive version of the instance for "price" and "sum" fields.
     * - For other fields, it uses `toRaw` to get the raw instance.
     */
    update(data: Record<string, unknown>) {
        Object.entries(data).forEach(([key, value]) => {
            if (!(key in this)) {
                return;
            }

            if (["price", "sum"].includes(key)) {
                const field = toReactive(this)[key] as Pricing;
                field.update(value as Record<string, number>);
                return;
            }

            const field = toRaw(this)[key];
            field.value = value;
        });
    }
}
