import { MountMullionsTopbottom1ValueCode } from "@/app/constants/mounts";
import {
    createMountsCGFFrameConfig,
    createMountsCGFBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsMullionsBottomConfig,
    createMountsMullionsTopConfig,
    createMountsMullionsHorizontalConfig,
    createMountsMullionsVerticalConfig,
    createMountsToptrackConfig,
} from "../../base";
import { Space } from "@/app/domain";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";
import { LineDividerCalculationService } from "@/app/services/line-dividers.service";
import { CGF400Code } from "@/app/constants";

const createSlidingDoorMountsToptrackConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTotalWidth(space);

    return createMountsToptrackConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const qty = totalWidth - doorWidth;

    return createMountsCGFBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsTopConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const sides = 2;

    const qty = totalWidth * sides;

    return createMountsMullionsTopConfig()
        .structureType(MountMullionsTopbottom1ValueCode)
        .qty(qty)
        .build();
};

const createSlidingDoorMountsMullionsBottomConfig = (
    space: Space,
): IComponentConfig => {
    const doorWidth = MountsService.getDoorTotalWidth(space);
    const sides = 2;

    const qty = doorWidth * sides;

    return createMountsMullionsBottomConfig()
        .structureType(MountMullionsTopbottom1ValueCode)
        .qty(qty)
        .build();
};

const createSlidingDoorMountsMullionsHorizontalConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);
    const horizontalLinesQty =
        LineDividerCalculationService.calculateHorizontalDividers(
            CGF400Code,
            totalHeight,
        );

    const sides = 2;

    const qty = totalWidth * horizontalLinesQty * sides;

    return createMountsMullionsHorizontalConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsVerticalConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);
    const doorSections = MountsService.isForDoubleDoor(space) ? 3 : 2;
    const sides = 2;

    const sectionWidthFt = totalWidth / 2;
    const qty = (sectionWidthFt - doorSections) * sides * totalHeight;

    return createMountsMullionsVerticalConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFFrameConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const doorSections = MountsService.isForDoubleDoor(space) ? 8 : 6;

    const qty = totalHeight * doorSections;

    return createMountsCGFFrameConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const sides = 2;

    const totalGaskets = totalWidth * sides;
    const doorGaskets = doorWidth * sides;

    const qty = totalGaskets - doorGaskets;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

export const CGF400SlidingDoorsConfigs = (space: Space) => [
    createSlidingDoorMountsToptrackConfig(space),
    createSlidingDoorMountsCGFBottomFrameSortConfig(space),
    createSlidingDoorMountsMullionsTopConfig(space),
    createSlidingDoorMountsMullionsBottomConfig(space),
    createSlidingDoorMountsMullionsHorizontalConfig(space),
    createSlidingDoorMountsMullionsVerticalConfig(space),
    createSlidingDoorMountsCGFFrameConfig(space),
    createSlidingDoorMountsGlazingGasketConfig(space),
];
