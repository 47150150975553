import { DoorType, MountType, SystemType } from "@/app/@types/shared";
import { Space } from "../domain";
import { MountHardware } from "../domain/entities/hardware";
import { MountHardwareStrategyRegistry } from "./mount-hardwares-strategies.service";

type CreateMountsHardwaresArgs = {
    installedSystemCode: string;
    activeDoorStructureType: string;
    activeMountStructureType: string;
    space: Space;
};

export class MountsService {
    static createMountsHardwares({
        installedSystemCode,
        activeDoorStructureType,
        activeMountStructureType,
        space,
    }: CreateMountsHardwaresArgs): MountHardware[] {
        if (!space) {
            return [];
        }

        const mountsStrategiesRegiest = new MountHardwareStrategyRegistry();

        const Strategy = mountsStrategiesRegiest.getStrategy(
            installedSystemCode as SystemType,
            activeDoorStructureType as DoorType,
            activeMountStructureType as MountType,
        );

        if (!Strategy) {
            return [];
        }

        const strategy = new Strategy(space);
        return strategy.create() as MountHardware[];
    }

    static getTotalWidth(space: Space): number {
        const partition = space.getActivePartition();
        return partition.dimensions.width.getLength().toFt().getValue();
    }

    static getTotalHeight(space: Space): number {
        const partition = space.getActivePartition();
        return partition.dimensions.height.getLength().toFt().getValue();
    }

    static getDoorTotalWidth(space: Space): number {
        const door = space.getActiveDoor();

        return door.dimensions.width.getLength().toFt().getValue();
    }

    static getDoorTotalHeight(space: Space): number {
        const door = space.getActiveDoor();

        return door.dimensions.height.getLength().toFt().getValue();
    }

    static getTopFrameWidth(space: Space): number {
        const overdoor = space.getActiveOverdoor();

        if (overdoor.isTransom()) {
            return this.getTotalWidth(space);
        }

        return this.getTotalWidth(space) - this.getDoorTotalWidth(space);
    }

    static getTransomHeight(space: Space): number {
        const overdoor = space.getActiveOverdoor();

        if (overdoor.isTransom()) {
            return overdoor.dimensions.height.getLength().toFt().getValue();
        }

        return 0;
    }

    static getMullionsQty = (space: Space): number => {
        const doorWidth = MountsService.getDoorTotalWidth(space);

        /**
         *  Mullions в данном случае - это:
         *  Направляющие профили, которые монтируются сверху проема
         *  По этим направляющим будут скользить двери
         *  Для раздвижных дверей требуется два параллельных профиля mullion:
         *
         *  Один профиль для передней двери
         *  Второй профиль для задней двери (которая движется параллельно первой)
         *
         *  Таким образом, для одной двери требуется 2 профиля mullion
         *
         */
        const mullionsQty = 2;

        return doorWidth * mullionsQty;
    };

    static getStipJointsQty = (space: Space): number => {
        const totalWidth = this.getTotalWidth(space);
        const doorWidth = this.getDoorTotalWidth(space);

        const doorSections = 3;

        return Math.round((totalWidth - doorWidth) / doorSections) - 1;
    };

    static getStipJointsWithoutDoorQty = (space: Space): number => {
        const totalWidth = this.getTotalWidth(space);
        const doorWidth = this.getDoorTotalWidth(space);

        const doorSections = 3;

        return Math.round(totalWidth - doorWidth) / doorSections;
    };

    static isForDoubleDoor = (space: Space): boolean => {
        const door = space.getActiveDoor();

        return door.isDoubleDoor();
    };

    static hasOverdoorTransom = (space: Space): boolean => {
        const overdoor = space.getActiveOverdoor();

        return overdoor.isTransom();
    };
}
