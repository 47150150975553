<template>
  <select-dict
    v-model="glassSort"
    :selectList="glassSorts"
    :select-first-item="true"
  />
</template>

<script setup lang="ts">
import { defineModel } from "vue";

import { useDictStore } from "@/stores/dictStore";
import SelectDict from "@/components/UI/SelectDict.vue";

const dictStore = useDictStore();

const glassSorts = dictStore.getGlassSorts();
const glassSort = defineModel<string>();
</script>

<style lang="scss" scoped></style>
