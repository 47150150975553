import { DealTypePredicates } from "@/app/predicates";
import { ref, Ref, toRaw } from "vue";

export interface IPricing {
    price: Ref<number>;
    priceB2C: Ref<number>;
    priceB2B: Ref<number>;

    getSystemPrice(system: string): IPricingValue;
    update(data: Record<string, number>): void;

    serialize(): Record<string, number>;
    deserialize(data: Record<string, number>): void;
}

export interface IPricingValue {
    price: number;

    getValue(): number;
    format(options?: Intl.NumberFormatOptions): string;
}

export class Pricing {
    price: Ref<number>;
    priceB2C: Ref<number>;
    priceB2B: Ref<number>;

    constructor(price = 0, priceB2C = 0, priceB2B = 0) {
        this.price = ref(price);
        this.priceB2C = ref(priceB2C);
        this.priceB2B = ref(priceB2B);
    }

    getSystemPrice(system: string): IPricingValue {
        const fieldName = this.getPriceKeyBySystem(system);
        const field = toRaw(this)[fieldName];

        if (!field) {
            return new PricingValue(0);
        }

        return new PricingValue(field.value);
    }

    update(data: Record<string, number>) {
        Object.entries(data).forEach(([key, value]) => {
            if (!(key in this)) {
                return;
            }

            const field = toRaw(this)[key];
            field.value = value;
        });
    }

    serialize(): Record<string, number> {
        return {
            price: this.price.value,
            priceB2C: this.priceB2C.value,
            priceB2B: this.priceB2B.value,
        };
    }

    deserialize(data: Record<string, number>) {
        this.update(data);
    }

    private getPriceKeyBySystem(system: string): string {
        if (DealTypePredicates.isCostDealType(system)) {
            return "price";
        }

        return `price${system.toUpperCase()}`;
    }
}

export class PricingValue implements IPricingValue {
    price: number;

    constructor(price: number) {
        this.price = price;
    }

    getValue(): number {
        return this.price;
    }

    format(options?: Intl.NumberFormatOptions): string {
        if (!this.price) return "0.00";
        return Number(this.price).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            ...options,
        });
    }
}
