import { computed } from "vue";
import { useDictStore } from "@/stores/dictStore";
import { useNewEstimateStore } from "@/stores/new/estimateStore";

import { Dictionary } from "@/models/dictionary";

import { ISelectionStrategy } from "../base/selection.strategy";
import {
    GlassThicknessCode1_2,
    GlassThicknessCode1_4,
    GlassThicknessCode3_8,
} from "@/app/constants";

export class DefaultGlassThicknessSelectionStrategy
    implements ISelectionStrategy
{
    private dictStore = useDictStore();
    private estimateStore = useNewEstimateStore();

    private installedSystemCode = computed(
        () => this.estimateStore.estimate.installedSystemCode,
    );

    select(): Dictionary[] {
        return this.dictStore.getGlassThickness(this.installedSystemCode.value);
    }
}

export class KompasSystemDoorGlassThicknessSelectionStrategy
    implements ISelectionStrategy
{
    private dictStore = useDictStore();
    private estimateStore = useNewEstimateStore();

    private installedSystemCode = computed(
        () => this.estimateStore.estimate.installedSystemCode,
    );

    select(): Dictionary[] {
        const glassThinkness = this.dictStore.getGlassThickness(
            this.installedSystemCode.value,
        );
        return glassThinkness.filter(
            (dict) => dict.code === GlassThicknessCode1_4,
        );
    }
}

export class KompasSystemSwingDoorGlassThicknessSelectionStrategy
    implements ISelectionStrategy
{
    private dictStore = useDictStore();
    private estimateStore = useNewEstimateStore();

    private installedSystemCode = computed(
        () => this.estimateStore.estimate.installedSystemCode,
    );

    select(): Dictionary[] {
        const glassThinkness = this.dictStore.getGlassThickness(
            this.installedSystemCode.value,
        );
        return glassThinkness.filter((dict) =>
            [GlassThicknessCode1_2, GlassThicknessCode3_8].includes(dict.code),
        );
    }
}
