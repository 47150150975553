import { isCGFSystem, isCGSSystem } from "@/app/predicates/systems.predicates";
import { ReactiveComponent } from "./base-component";

import { IComponentConfig } from "@/app/@types/base-component";
import { OverdoorAssetsCode } from "@/app/constants";

export class OverdoorAsset extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({
            ...config,
            type: "overdoor-asset",
            structureType: OverdoorAssetsCode,
        });
    }

    isEditable = (installedSystem: string): boolean => {
        return isCGFSystem(installedSystem) || isCGSSystem(installedSystem);
    };
}
