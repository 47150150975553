import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";

import { CalculationStrategy, PriceResult } from "../base/calculation.strategy";
import { ReactiveComponent } from "../../entities/base-component";

export class OverdoorTotalPriceCalculationStrategy extends CalculationStrategy {
    public calculate(component: ReactiveComponent): PriceResult {
        return {
            price: this.calculateComponentsPriceForType(
                component,
                CostDealType,
            ),
            priceB2C: this.calculateComponentsPriceForType(
                component,
                B2CDealType,
            ),
            priceB2B: this.calculateComponentsPriceForType(
                component,
                B2BDealType,
            ),
        };
    }

    private calculateComponentsPriceForType(
        component: ReactiveComponent,
        dealType: string,
    ) {
        const children = component.components;

        return children.reduce((total, child) => {
            const sum = child.sum.getSystemPrice(dealType).getValue();

            return total + sum;
        }, 0);
    }
}

export class OverdoorWithoutAssetsPriceCalculationStrategy extends CalculationStrategy {
    public calculate(component: ReactiveComponent): PriceResult {
        return {
            price: this.calculateComponentsPriceForType(
                component,
                CostDealType,
            ),
            priceB2C: this.calculateComponentsPriceForType(
                component,
                B2CDealType,
            ),
            priceB2B: this.calculateComponentsPriceForType(
                component,
                B2BDealType,
            ),
        };
    }

    private calculateComponentsPriceForType(
        component: ReactiveComponent,
        dealType: string,
    ) {
        const children = component.components.filter(
            (component) =>
                !["outOfSquare", "overdoor-asset"].includes(component.type),
        );

        return children.reduce((total, child) => {
            const sum = child.sum.getSystemPrice(dealType).getValue();

            return total + sum;
        }, 0);
    }
}
