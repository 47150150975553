import { OverheadConfigs } from "../../configs";
import { OverheadItem } from "../../entities/overhead";
import { CreateManyStrategy } from "../base/creation.strategy";

type OverheadItemsDefaultStrategyArgs = {
    areaFt: number;
};

// prettier-ignore
export class OverheadItemsDefaultStrategy extends CreateManyStrategy {
    constructor({ areaFt }: OverheadItemsDefaultStrategyArgs) {
        super(OverheadConfigs.OverheadItemsDefaultConfigs(areaFt), OverheadItem);
    }
}

// prettier-ignore
export class OverheadItemsWithoutDeliveryStrategy extends CreateManyStrategy {
    constructor({ areaFt }: OverheadItemsDefaultStrategyArgs) {
        super(OverheadConfigs.OverheadItemsWithoutDeliveryConfigs(areaFt), OverheadItem);
    }
}

// prettier-ignore
export class OverheadItemsWithoutInstallationStrategy extends CreateManyStrategy {
    constructor({ areaFt }: OverheadItemsDefaultStrategyArgs) {
        super(OverheadConfigs.OverheadItemsWithoutInstallationConfigs(areaFt), OverheadItem);
    }
}

// prettier-ignore
export class OverheadItemsWithoutDeliveryInstallationStrategy extends CreateManyStrategy {
    constructor({ areaFt }: OverheadItemsDefaultStrategyArgs) {
        super(OverheadConfigs.OverheadItemsWithoutInstallationConfigs(areaFt), OverheadItem);
    }
}

// prettier-ignore
export class TreviRomanoOverheadItemsStrategy extends CreateManyStrategy {
    constructor({ areaFt }: OverheadItemsDefaultStrategyArgs) {
        super(OverheadConfigs.OverheadItemsWithoutQtyConfigs(areaFt), OverheadItem);
    }
}
