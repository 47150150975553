import { useDictStore } from "@/stores/dictStore";

import { PricingStrategy, PriceResult } from "../base/pricing.strategy";
import { OutOfSquareCode } from "@/app/constants";

export class OutOfSquarePricingStrategy extends PricingStrategy {
    private dictStore = useDictStore();

    public getPrices(): PriceResult {
        const pricesFromDict =
            this.dictStore.findOutOfSquarePrice(OutOfSquareCode);

        if (!this.validatePricesFromDict(pricesFromDict)) {
            return this.getDefaultPrices();
        }

        return {
            price: pricesFromDict.price!,
            priceB2C: pricesFromDict.priceB2C!,
            priceB2B: pricesFromDict.priceB2B!,
        };
    }
}
