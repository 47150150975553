import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { SummaryBlock as SummaryBlockItem } from "@/models/summary";

import { useNewEstimateStore } from "./estimateStore";
import { useDoorsStore } from "./doors.store";
import { usePartitionStore } from "./partition.store";
import { useSpacesStore } from "./spaces.store";
import { useOverdoorStore } from "./overdoor.store";
import { useDeliveryStore } from "./delivery.store";
import { useExtraStore } from "./extra.store";
import { useOverheadStore } from "./overhead.store";
import { useMountsStore } from "./mounts.store";
import { SummaryService } from "@/services/summaryService";

export const useActionsStrore = defineStore(
    "actions",
    () => {
        const newEstimateStore = useNewEstimateStore();
        const spacesStore = useSpacesStore();
        const partitionsStore = usePartitionStore();
        const doorsStore = useDoorsStore();
        const overdoorsStore = useOverdoorStore();
        const deliveryStore = useDeliveryStore();
        const extraStore = useExtraStore();
        const mountsStore = useMountsStore();
        const overheadsStore = useOverheadStore();

        const estimate = computed(() => newEstimateStore.estimate);

        const activeSpace = computed(() => spacesStore.activeSpace);
        const spaces = computed(() => spacesStore.spaces);
        const delivery = computed(() => deliveryStore.activeDelivery);

        const summary = ref<SummaryBlockItem | null>(null);

        const resetAllStores = () => {
            newEstimateStore.$reset();
            spacesStore.$reset();
            partitionsStore.$reset();
            doorsStore.$reset();
            overdoorsStore.$reset();
            deliveryStore.$reset();
            extraStore.$reset();
            mountsStore.$reset();
            overheadsStore.$reset();
        };

        const addAnotherPartition = () => {
            partitionsStore.$reset();
            doorsStore.$reset();
            overdoorsStore.$reset();
            mountsStore.$reset();
        };

        const addAnotherSpace = () => {
            spacesStore.addSpace();
            addAnotherPartition();
        };

        const removePartition = (type: string, code: string) => {
            activeSpace.value?.removeComponent(code, type);
            reloadSummary();
        };

        const reloadSummary = () => {
            summary.value = SummaryService.fromEstimate(
                estimate.value,
                spaces.value,
                delivery.value,
            );
        };

        return {
            resetAllStores,
            removePartition,
            reloadSummary,
            summary,
            addAnotherPartition,
            addAnotherSpace,
        };
    },
    { persist: false },
);
