import { Hardware } from "../domain/entities/hardware";
import { DoorHardwareStrategyRegistry } from "./door-hardwares-strategies.service";
import { DoorType } from "../@types/shared";
import { DoorCut } from "../domain";
import { DoorCutsStrategyRegistry } from "./door-cuts-strategies.service";

export class DoorService {
    static createDoorHardwares(doorType: string): Hardware[] {
        const doorsHardwaresRegistry = new DoorHardwareStrategyRegistry();

        const Strategy = doorsHardwaresRegistry.getStrategy(
            doorType as DoorType,
        );

        if (!Strategy) {
            return [];
        }

        const strategy = new Strategy();
        return strategy.create() as unknown as Hardware[];
    }

    static createDoorCuts(doorType: string): DoorCut[] {
        const doorsHardwaresRegistry = new DoorCutsStrategyRegistry();

        const Strategy = doorsHardwaresRegistry.getStrategy(
            doorType as DoorType,
        );

        if (!Strategy) {
            return [];
        }

        const strategy = new Strategy();
        return strategy.create() as unknown as DoorCut[];
    }
}
