<template>
  <span class="step__block-field-price">
    {{ price?.format() || "0.00" }}
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { IPricing } from "@/app/domain";

type PriceValueProps = {
  price: IPricing;
};

const props = defineProps<PriceValueProps>();

const estimateStore = useNewEstimateStore();
const dealType = computed(() => estimateStore.estimate.dealTypeCode);

const price = computed(() => props.price?.getSystemPrice(dealType.value));
</script>

<style lang="scss" scoped>
.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
</style>
