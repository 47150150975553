import { SystemType } from "../@types/shared";
import { OverheadItem } from "../domain/entities/overhead";
import { OverheadItemsStrategyRegistry } from "./overhead-strategies.service";

export class OverheadService {
    static createOverheadItems(
        areaFt: number,
        systemCode: string,
        hasInstallation: boolean,
        hasDelivery: boolean,
    ): OverheadItem[] {
        const overheadStrategiesRegisty = new OverheadItemsStrategyRegistry();

        const installationCode =
            overheadStrategiesRegisty.createInstallationKey(hasInstallation);
        const deliveryCode =
            overheadStrategiesRegisty.createDeliveryKey(hasDelivery);

        const Strategy = overheadStrategiesRegisty.getStrategy(
            systemCode as SystemType,
            installationCode,
            deliveryCode,
        );

        if (!Strategy) {
            return [];
        }

        const strategy = new Strategy({ areaFt });

        return strategy.create() as OverheadItem[];
    }

    static calcOhDrawingQty(areaSqFt: number): number {
        const baseQty = 2;
        const areaMaxSq = 100;
        const areaIncrement = 100;

        return this.calcAdditionalQty(
            areaSqFt,
            baseQty,
            areaMaxSq,
            areaIncrement,
        );
    }

    static calcOhCuttingQty(areaSqFt: number): number {
        const baseQty = 2;
        const areaMaxSq = 100;
        const areaIncrement = 100;

        return this.calcAdditionalQty(
            areaSqFt,
            baseQty,
            areaMaxSq,
            areaIncrement,
        );
    }

    static calcOhPackaingQty(areaSqFt: number): number {
        const baseQty = 2;
        const areaMaxSq = 100;
        const areaIncrement = 100;

        return this.calcAdditionalQty(
            areaSqFt,
            baseQty,
            areaMaxSq,
            areaIncrement,
        );
    }

    static calcOhParkingQty(areaSqFt: number): number {
        const baseQty = 1;
        const areaMaxSq = 300;
        const areaIncrement = 200;

        return this.calcAdditionalQty(
            areaSqFt,
            baseQty,
            areaMaxSq,
            areaIncrement,
        );
    }

    static calcOhMeasureQty(areaSqFt: number): number {
        const baseQty = 3;
        const areaMaxSq = 300;
        const areaIncrement = 200;

        return this.calcAdditionalQty(
            areaSqFt,
            baseQty,
            areaMaxSq,
            areaIncrement,
        );
    }

    private static calcAdditionalQty(
        areaSqFt: number,
        baseQty: number,
        areaMaxSq: number,
        areaIncrement: number,
    ): number {
        const needAdditionalQty = areaSqFt > areaMaxSq;
        const exceedingArea = areaSqFt - areaMaxSq;
        const qtyForExceedingArea = Math.ceil(exceedingArea / areaIncrement);

        const additionalQty = needAdditionalQty ? qtyForExceedingArea : 0;

        return baseQty + additionalQty;
    }
}
