import {
    Door,
    Estimate,
    Hardware,
    Overdoor,
    OverdoorMaterial,
    Partition,
    ReactiveComponentDimensions,
    Space,
} from "@/app/domain";
import { IDimensions, ComponentDimensions } from "@/app/@types/dimensions";
import {
    getFromDictByCode,
    getTitleFromDictByCode,
    useDictStore,
} from "../stores/dictStore";
import { createPinia, setActivePinia } from "pinia";
import { SummaryBlock, SummaryHeader, SummaryLine } from "../models/summary";
import {
    addTextToText,
    formatDateTime,
    formatDate,
    generateCode,
} from "../utils/common";

import { usePriceStore } from "../stores/priceStore";

import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";
import { OutOfSquare } from "@/app/domain/entities/out-of-square";
import { OverdoorAsset } from "@/app/domain/entities/overdoor-asset";
import { Mounts } from "@/app/domain/entities/mounts";
import { MountHardware } from "@/app/domain/entities/hardware";
import { Overheads } from "@/app/domain/entities/overhead";
import { Delivery, DeliveryItem } from "@/app/domain/entities/delivery";
import { Extra, ExtraItem } from "@/app/domain/entities/extra";
import { isRef } from "vue";

setActivePinia(createPinia());
const dicts = useDictStore();
export class SummaryService {
    static blockFactory = (data: object = null): SummaryBlock => {
        return <SummaryBlock>{
            ...(<SummaryBlock>{
                code: generateCode(),
                type: null,
                objectType: null,
                objectCode: null,
                style: null,
                title: "",
                description: "",
                header: this.headerFactory(),
                footer: this.headerFactory(),
                lines: [],
                subBlocks: [],
            }),
            ...data,
        };
    };

    static lineFactory = (data: object = null): SummaryLine => {
        return <SummaryLine>{
            ...(<SummaryLine>{
                code: generateCode(),
                type: null,
                objectType: null,
                objectCode: null,
                style: null,
                title: null,
                qty: null,
                price: null,
                priceB2C: null,
                priceB2B: null,
                sum: null,
                sumB2C: null,
                sumB2B: null,
            }),
            ...data,
        };
    };

    static headerFactory = (data: object = null): SummaryHeader => {
        return <SummaryHeader>{
            ...this.lineFactory({
                ...{
                    type: "header",
                    priceTitle: null,
                    qtyTitle: null,
                    sumTitle: null,
                },
                ...data,
            }),
        };
    };

    static headerFactoryQtySum = (data: object = null): SummaryHeader => {
        const priceCode = usePriceStore().data.currentPriceKey;
        return this.headerFactory({
            ...{
                title: "",
                qtyTitle: "Q-ty",
                priceTitle: priceCode,
                sumTitle: "SUM",
            },
            ...data,
        });
    };

    static fromEstimate = (
        estimate: Estimate,
        spaces: Space[],
        delivery: Delivery,
    ): SummaryBlock => {
        const dictStore = useDictStore();
        const estimateSummary = this.blockFactory({
            type: "estimate",
            title:
                `System: ${getTitleFromDictByCode(
                    dictStore.getSystemTypes(),
                    estimate.installedSystemCode,
                )} - PRODUCT DESCRIPTION and AMOUNT` +
                " Track#: " +
                estimate.trackCode +
                " Estimate#: " +
                estimate.estimateNo,
            description:
                "DESCRIPTIONS FOR MANAGERS and copying to the HUB SPOT",
        });

        // add blocks
        estimateSummary.subBlocks?.push(this.getGeneralInfoBlock(estimate));

        // TODO: Refactor
        const activeSpace = spaces[0];
        const hasSpaces = spaces.length > 0;

        if (!hasSpaces) {
            return estimateSummary;
        }

        const extra = activeSpace.getExtra() as unknown as Extra;
        const overheads = activeSpace.getOverheads() as unknown as Overheads;

        for (const space of spaces) {
            const spaceSummary = this.blockFactory({
                type: "space",
                objectType: space.type,
                objectCode: space.id,
                title: space.title,
            });

            const partitionSummary = this.getStructure(space);

            if (partitionSummary) {
                spaceSummary.subBlocks?.push(partitionSummary);
            }

            estimateSummary.subBlocks?.push(spaceSummary);
        }

        if (overheads && overheads.components.length > 0) {
            estimateSummary.subBlocks?.push(
                this.getOverheadStructure(overheads),
            );
        }

        if (delivery && delivery.components.length > 0) {
            estimateSummary.subBlocks?.push(
                this.getDeliveryStructure(estimate, delivery, extra),
            );
        }

        return estimateSummary;
    };

    static getGeneralInfoBlock = (info: Estimate): SummaryBlock => {
        let estimatorValue = "";
        if (typeof info.estimatorCode === "object") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            estimatorValue = info.estimatorCode?.code;
        } else {
            estimatorValue = info?.estimatorCode;
        }
        const infoBlock = this.blockFactory({
            type: "info",
            objectType: "info",
            objectCode: info?.code,
            title: "General Information",
            header: null,
            lines: [
                this.lineFactory({
                    type: "keyval",
                    title: "Estimate started",
                    value: formatDateTime(<any>info.started),
                }),
                this.lineFactory({
                    type: "keyval",
                    title: "Price Date",
                    value: formatDate(<number>info.priceDate),
                }),
                this.lineFactory({
                    type: "keyval",
                    title: "Usage",
                    value: getFromDictByCode(
                        dicts.getInstallationUsage(),
                        info.installationUsageCode,
                    )?.title,
                }),
                this.lineFactory({
                    type: "keyval",
                    title: "Finish (color)",
                    value: getFromDictByCode(
                        dicts.getFinishColors(),
                        info.colorCode,
                    )?.title,
                }),
                this.lineFactory({ type: "empty" }),
                this.lineFactory({
                    type: "keyval",
                    title: "First and Last name (in full)",
                    value: info?.client?.name,
                }),
                this.lineFactory({
                    type: "keyval",
                    title: "Business name (if applicable)",
                    value: info?.client?.businessName,
                }),
                this.lineFactory({
                    type: "keyval",
                    title: "Email",
                    value: info?.client?.email,
                }),
                this.lineFactory({
                    type: "keyval",
                    title: "Phone",
                    value: info?.client?.phone,
                }),
                this.lineFactory({
                    type: "keyval",
                    title: "Billing address",
                    value: info?.client?.shippingAddress,
                }),
                this.lineFactory({
                    type: "keyval",
                    title: "Shipping Address",
                    value:
                        addTextToText(
                            info?.shippingState ?? "",
                            info?.shippingZip ?? "",
                            ", ",
                        ) +
                        " " +
                        info?.client?.shippingAddress,
                }),
                this.lineFactory({ type: "empty" }),
                this.lineFactory({
                    type: "keyval",
                    title: "Delivery",
                    value: info?.delivery === "true" ? "yes" : "no",
                }),
                this.lineFactory({
                    type: "keyval",
                    title: "Installation",
                    value: info?.installation === "true" ? "yes" : "no",
                }),
                this.lineFactory({
                    type: "keyval",
                    title: "Manager",
                    value: info?.managerCode?.title,
                }),
                this.lineFactory({
                    type: "keyval",
                    title: "Estimator",
                    value: estimatorValue,
                }),
            ],
        });

        return infoBlock;
    };

    static getStructure = (space: Space) => {
        const partition = space.getActivePartition();

        if (!partition) {
            // return this.blockFactory({
            //     type: "space",
            //     objectType: space.type,
            //     objectCode: space.code,
            //     title: space.title,
            //     header: this.headerFactoryQtySum({
            //         title: space.title,
            //     }),
            // });

            return null;
        }

        const summaryBlocks = this.getPartitionStructure(partition) || [];

        const doors = (partition.getAllDoors() as unknown as Door[]) || [];

        const overdoors =
            (partition.getAllOverdoors() as unknown as Overdoor[]) || [];
        const mount = partition.getActiveMount() as unknown as Mounts;

        for (const door of doors) {
            summaryBlocks.subBlocks?.push(this.getDoorStructure(door));
        }

        for (const overdoor of overdoors) {
            if (overdoor.isFiller()) {
                summaryBlocks.subBlocks?.push(
                    this.getFillerStructure(overdoor),
                );
            } else if (overdoor.isTransom()) {
                summaryBlocks.subBlocks?.push(
                    this.getTransomStructure(overdoor),
                );
            } else {
                summaryBlocks.subBlocks?.push(
                    this.blockFactory({
                        type: "filler",
                        code: overdoor.id,
                        title: "Filler and transom: none",
                        header: this.headerFactoryQtySum({
                            title: "Filler and transom: none",
                        }),
                    }),
                );
            }
        }

        if (mount && mount.components.length > 0) {
            summaryBlocks.subBlocks?.push(this.getMountsStructure(mount));
        }

        return summaryBlocks;
    };

    static getPartitionStructure = (partition: Partition): SummaryBlock => {
        const block = this.blockFactory({
            type: "partition",
            title: partition.title,
            objectType: partition.type,
            objectCode: partition.id,
            header: this.headerFactoryQtySum({
                title: partition.title,
                objectType: partition.type,
                objectCode: partition.id,
            }),
            lines: [
                this.lineFactory({
                    type: "keyval",
                    title: [
                        "Partition;",
                        "Dimensions:",
                        dimensionsToText(partition.dimensions),
                        " ",
                        "Square:",
                        calculateClearArea(partition.dimensions),
                        " ",
                        "Ft.",
                    ].join(" "),
                }),

                this.lineFactory({
                    type: "qtysum",
                    title: [
                        "Glass:",
                        getFromDictByCode(
                            dicts.getGlassThickness(),
                            partition.getActiveGlass()?.structureType,
                        )?.title,
                        getFromDictByCode(
                            dicts.getGlassSorts(),
                            partition.getActiveGlass()?.structureType,
                        )?.title,
                    ].join(" "),
                    price: partition.price
                        .getSystemPrice(CostDealType)
                        .getValue(), // цену partition берем из суммы элементов
                    priceB2C: partition.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: partition.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    qty: partition.qty,
                    sum: partition.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: partition.sum
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    sumB2B: partition.sum
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                }),
            ],
        });

        const outOfSquare = partition.getActiveOutOfSquare()!;

        if (outOfSquare.qty > 0) {
            block.lines.push(
                this.lineFactory({
                    type: "qtysum",
                    title: "Out of square",
                    qty: outOfSquare.qty,
                    price: outOfSquare.price
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    priceB2C: outOfSquare.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: outOfSquare.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    sum: outOfSquare.sum
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    sumB2C: outOfSquare.sum
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    sumB2B: outOfSquare.sum
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                }),
            );
        }
        return block;
    };

    static getDoorStructure = (door: Door): SummaryBlock => {
        const activeGlassPartition = door.getActiveGlassPartition();
        const getActiveGlassThinknessPartition = door.getActiveGlassThinkness();

        // no door
        if (door.isDoorNone()) {
            return this.blockFactory({
                type: "door",
                title: "DOOR",
                objectType: door.type,
                objectCode: door.id,
                header: this.headerFactoryQtySum({
                    title:
                        "Door: " +
                        getFromDictByCode(
                            dicts.getDoorTypes(),
                            door.structureType,
                        )?.title,
                    objectType: door.type,
                    objectCode: door.id,
                }),
                lines: [],
            });
        }

        // any door
        const doorBlock: SummaryBlock = this.blockFactory({
            type: "door",
            title: "DOOR",
            objectType: door.type,
            objectCode: door.id,
            header: this.headerFactoryQtySum({
                title:
                    "Door: " +
                    getFromDictByCode(dicts.getDoorTypes(), door.structureType)
                        ?.title,
                objectType: door.type,
                objectCode: door.id,
            }),
            lines: [
                this.lineFactory({
                    type: "keyval",
                    title: [
                        "Door type:",
                        getFromDictByCode(
                            dicts.getDoorTypes(),
                            door.structureType,
                        )?.title,
                    ].join(" "),
                }),
                this.lineFactory({
                    type: "keyval",
                    title: [
                        "Dimensions:",
                        dimensionsToText(door.dimensions),
                    ].join(" "),
                }),

                this.lineFactory({
                    type: "qtysum",
                    title: [
                        "Glass:",
                        getFromDictByCode(
                            dicts.getGlassSorts(),
                            activeGlassPartition?.structureType,
                        )?.title,
                        getFromDictByCode(
                            dicts.getGlassThickness(),
                            getActiveGlassThinknessPartition?.structureType,
                        )?.title,
                    ].join(" "),
                    price: activeGlassPartition?.price
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    priceB2C: activeGlassPartition?.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: activeGlassPartition?.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    qty: activeGlassPartition?.qty,
                    sum: activeGlassPartition?.sum
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    sumB2C: activeGlassPartition?.sum
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    sumB2B: activeGlassPartition?.sum
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                }),
            ],
        });

        for (const hardware: Hardware of door.getActiveHardwares()) {
            doorBlock.lines.push(
                this.lineFactory({
                    type: "qtysum",
                    title: [
                        getFromDictByCode(
                            dicts.getHardwareTypes("door"),
                            hardware.typeCode,
                        )?.title + ":",
                        getFromDictByCode(
                            dicts.getHardwareValues(hardware.typeCode),
                            hardware.structureType,
                        )?.title,
                    ].join(" "),
                    qty: hardware.qty,
                    price: hardware.price
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    priceB2C: hardware.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: hardware.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    sum: hardware.sum.getSystemPrice(B2BDealType).getValue(),
                    sumB2C: hardware.sum.getSystemPrice(B2CDealType).getValue(),
                    sumB2B: hardware.sum.getSystemPrice(B2BDealType).getValue(),
                }),
            );
        }
        return doorBlock;
    };

    static getFillerStructure = (overdoor: Overdoor): SummaryBlock => {
        const material =
            overdoor.getMaterial()[0] as unknown as OverdoorMaterial;
        const outOfSquare = overdoor.getOutOfSquare() as unknown as OutOfSquare;
        const asset = overdoor.getAsset() as unknown as OverdoorAsset;

        const block: SummaryBlock = this.blockFactory({
            type: "filler",
            title: "Filler",
            objectType: overdoor.type,
            objectCode: overdoor.id,
            header: this.headerFactoryQtySum({
                title: "Filler",
                objectType: overdoor.type,
                objectCode: overdoor.id,
            }),
            lines: [
                this.lineFactory({
                    type: "qtysum",
                    title: [
                        "Type:",
                        getFromDictByCode(
                            dicts.getStructureTypes("filler"),
                            overdoor.typeCode,
                        )?.title,
                    ].join(" "),
                }),
                this.lineFactory({
                    type: "qtysum",
                    title: [
                        "Dimensions:",
                        dimensionsToText(overdoor.dimensions),
                    ].join(" "),
                }),
                this.lineFactory({
                    type: "qtysum",
                    title: [
                        "Material:",
                        getFromDictByCode(
                            dicts.getAluminumMaterialTypes(),
                            material?.structureType,
                        )?.title,
                    ].join(" "),
                    price: material?.price
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    priceB2C: material?.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: material?.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    qty: material?.qty,
                    sum: material?.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: material?.sum
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    sumB2B: material?.sum
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                }),
                this.lineFactory({
                    type: "qtysum",
                    title: "Out of square",
                    price: outOfSquare.price
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    priceB2C: outOfSquare.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: outOfSquare.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    qty: outOfSquare.qty,
                    sum: outOfSquare.sum
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    sumB2C: outOfSquare.sum
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    sumB2B: outOfSquare.sum
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                }),
                this.lineFactory({
                    type: "qtysum",
                    title: "Assets",
                    price: asset.price.getSystemPrice(CostDealType).getValue(),
                    priceB2C: asset.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: asset.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    qty: asset.qty,
                    sum: asset.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: asset.sum.getSystemPrice(B2CDealType).getValue(),
                    sumB2B: asset.sum.getSystemPrice(B2BDealType).getValue(),
                }),
            ],
        });

        return block;
    };

    static getTransomStructure = (transom: Overdoor): SummaryBlock => {
        const [glass, glassThickness] =
            transom.getMaterial() || ([] as unknown as OverdoorMaterial[]);

        console.log(transom.getMaterial());

        const block: SummaryBlock = this.blockFactory({
            type: "transom",
            title: "Transom",
            objectType: transom.type,
            objectCode: transom.id,
            header: this.headerFactoryQtySum({
                title: "Transom",
                objectType: transom.type,
                objectCode: transom.id,
            }),
            lines: [
                this.lineFactory({
                    type: "keyval",
                    title: [
                        "Type",
                        getFromDictByCode(
                            dicts.getTransomTypes(),
                            transom.typeCode,
                        )?.title,
                    ].join(" "),
                }),
                this.lineFactory({
                    type: "keyval",
                    title: [
                        "Dimensions:",
                        dimensionsToText(transom.dimensions),
                    ].join(" "),
                }),
            ],
        });

        if (glass && glassThickness) {
            this.lineFactory({
                type: "qtysum",
                title: [
                    "Glass:",
                    getFromDictByCode(
                        dicts.getGlassSorts(),
                        glass.structureType,
                    )?.title,
                    getFromDictByCode(
                        dicts.getGlassThickness(),
                        glassThickness.structureType,
                    )?.title,
                ].join(" "),
                price: transom.price.getSystemPrice(CostDealType).getValue(),
                priceB2C: transom.price.getSystemPrice(B2CDealType).getValue(),
                priceB2B: transom.price.getSystemPrice(B2BDealType).getValue(),
                qty: transom.qty,
                sum: transom.sum.getSystemPrice(CostDealType).getValue(),
                sumB2C: transom.sum.getSystemPrice(B2CDealType).getValue(),
                sumB2B: transom.sum.getSystemPrice(B2BDealType).getValue(),
            });
        }

        // for (const hardware: HardwareItem of transom.hardwares) {
        //     block.lines.push(
        //         this.lineFactory({
        //             type: "qtysum",
        //             title: [
        //                 getFromDictByCode(
        //                     dicts.getHardwareTypes("transom"),
        //                     hardware.hardwareTypeCode,
        //                 )?.title + ":",
        //                 getFromDictByCode(
        //                     dicts.getHardwareValues(hardware.hardwareTypeCode),
        //                     hardware.hardwareValueCode,
        //                 )?.title,
        //             ].join(" "),
        //             price: CalculateService.getSubitemByCode(
        //                 calculationItem,
        //                 hardware.code,
        //             )?.price,
        //             priceB2C: CalculateService.getSubitemByCode(
        //                 calculationItem,
        //                 hardware.code,
        //             )?.priceB2C,
        //             priceB2B: CalculateService.getSubitemByCode(
        //                 calculationItem,
        //                 hardware.code,
        //             )?.priceB2B,
        //             qty: CalculateService.getSubitemByCode(
        //                 calculationItem,
        //                 hardware.code,
        //             ).qty,
        //             sum: CalculateService.getSubitemByCode(
        //                 calculationItem,
        //                 hardware.code,
        //             ).sum,
        //             sumB2C: CalculateService.getSubitemByCode(
        //                 calculationItem,
        //                 hardware.code,
        //             ).sumB2C,
        //             sumB2B: CalculateService.getSubitemByCode(
        //                 calculationItem,
        //                 hardware.code,
        //             ).sumB2B,
        //         }),
        //     );
        // }

        return block;
    };

    static getMountsStructure = (mounts: Mounts): SummaryBlock => {
        const hardwares = mounts.getHardwares() as unknown as MountHardware[];

        const block: SummaryBlock = this.blockFactory({
            type: "mounts",
            title: "CHANEL, TRACKS, PROFILES",
            objectType: mounts.type,
            objectCode: mounts.id,
            header: this.headerFactoryQtySum({
                title: "CHANEL, TRACKS, PROFILES",
                objectType: mounts.type,
                objectCode: mounts.id,
            }),
            lines: [],
        });
        for (const hardware of hardwares) {
            block.lines.push(
                this.lineFactory({
                    type: "qtysum",
                    title: [
                        getFromDictByCode(
                            dicts.getHardwareTypes("mounts"),
                            hardware.typeCode,
                        )?.title + ":",
                        getFromDictByCode(
                            dicts.getHardwareValues(hardware.typeCode),
                            hardware.structureType,
                        )?.title,
                    ].join(" "),
                    qty: hardware.qty,
                    price: hardware.price
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    priceB2C: hardware.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: hardware.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                    sum: hardware.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: hardware.sum.getSystemPrice(B2CDealType).getValue(),
                    sumB2B: hardware.sum.getSystemPrice(B2BDealType).getValue(),
                }),
            );
        }
        return block;
    };

    static getOverheadStructure = (overheads: Overheads): SummaryBlock => {
        const items = overheads.getOverheadItems() as unknown as OverheadItem[];

        const block: SummaryBlock = this.blockFactory({
            type: "overhead",
            title: "Overhead costs",
            objectType: overheads.type,
            header: this.headerFactoryQtySum({
                title: "Overhead costs",
                objectType: overheads.type,
            }),
            lines: [],
        });
        for (const item of items) {
            block.lines.push(
                this.lineFactory({
                    type: "qtysum",
                    title: [
                        getFromDictByCode(
                            dicts.getAdditionalCosts(),
                            item.typeCode,
                        )?.title + ":",
                        getFromDictByCode(
                            dicts.getAdditionalCostValues(item.typeCode),
                            item.structureType,
                        )?.title,
                    ].join(" "),
                    price: item.price.getSystemPrice(CostDealType).getValue(),
                    priceB2C: item.price.getSystemPrice(B2CDealType).getValue(),
                    priceB2B: item.price.getSystemPrice(B2BDealType).getValue(),
                    qty: item.qty,
                    sum: item.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: item.sum.getSystemPrice(B2CDealType).getValue(),
                    sumB2B: item.sum.getSystemPrice(B2BDealType).getValue(),
                }),
            );
        }
        return block;
    };

    static getDeliveryStructure = (
        estimate: Estimate,
        delivery: Delivery,
        extra: Extra,
    ): SummaryBlock => {
        const deliveryItems =
            (delivery?.getDeliveryItems() as unknown as DeliveryItem[]) || [];
        const extraItems =
            (extra?.getExtraItems() as unknown as ExtraItem[]) || [];

        const block: SummaryBlock = this.blockFactory({
            type: "delivery",
            objectType: delivery.type,
            title: "Delivery and installation",
            header: this.headerFactoryQtySum({
                title: "Delivery and installation",
                objectType: delivery.type,
            }),
            lines: [
                this.lineFactory({
                    type: "keyval",
                    title: "Address(state)",
                    value: [estimate.shippingState, estimate.shippingZip].join(
                        ", ",
                    ),
                }),
                this.lineFactory({
                    type: "keyval",
                    title: "Address",
                    value: estimate.client.shippingAddress,
                }),
            ],
        });

        block.lines.push(this.lineFactory({ type: "empty" }));

        for (const item of deliveryItems) {
            block.lines.push(
                this.lineFactory({
                    type: "qtysum",
                    title: [
                        getFromDictByCode(
                            dicts.getAdditionalCosts("delivery"),
                            item.typeCode,
                        )?.title + ":",
                        getFromDictByCode(
                            dicts.getAdditionalCostValues(item.typeCode),
                            item.structureType,
                        )?.title,
                    ].join(" "),
                    qty: item.qty,
                    price: item.price.getSystemPrice(CostDealType).getValue(),
                    priceB2C: item.price.getSystemPrice(B2CDealType).getValue(),
                    priceB2B: item.price.getSystemPrice(B2BDealType).getValue(),
                    sum: item.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: item.sum.getSystemPrice(B2CDealType).getValue(),
                    sumB2B: item.sum.getSystemPrice(B2BDealType).getValue(),
                }),
            );
        }

        block.lines.push(this.lineFactory({ type: "empty" }));

        for (const extraItem of extraItems) {
            block.lines.push(
                this.lineFactory({
                    type: "qtysum",
                    title: getFromDictByCode(
                        dicts.getHardwareValues(),
                        extraItem.structureType,
                    )?.title,
                    qty: extraItem.qty,
                    price: extraItem.price
                        .getSystemPrice(CostDealType)
                        .getValue(),
                    priceB2C: extraItem.price
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    priceB2B: extraItem.price
                        .getSystemPrice(B2BDealType)
                        .getValue(),

                    sum: extraItem.sum.getSystemPrice(CostDealType).getValue(),
                    sumB2C: extraItem.sum
                        .getSystemPrice(B2CDealType)
                        .getValue(),
                    sumB2B: extraItem.sum
                        .getSystemPrice(B2BDealType)
                        .getValue(),
                }),
            );
        }

        return block;
    };
}

export const oneDimensionToText = (dimension: IDimensions): string => {
    const parts: any[] = [];

    const ft = isRef(dimension.ft) ? dimension.ft.value : dimension.ft;
    const inch = isRef(dimension.inch) ? dimension.inch.value : dimension.inch;
    const fractionDividend = isRef(dimension.fractionDividend)
        ? dimension.fractionDividend.value
        : dimension.fractionDividend;
    const fractionDivisor = isRef(dimension.fractionDivisor)
        ? dimension.fractionDivisor.value
        : dimension.fractionDivisor;

    if (ft + inch + fractionDividend + fractionDivisor === 0) {
        return "0";
    }
    if (ft !== null) {
        parts.push(ft?.toString() + "'");
    }
    if (inch !== null) {
        parts.push(
            inch?.toString() + (fractionDividend + fractionDivisor ? '"' : ""),
        );
    }
    if (fractionDividend + fractionDivisor !== 0) {
        parts.push(
            fractionDividend?.toString() +
                "/" +
                fractionDivisor?.toString() +
                '"',
        );
    }
    return parts.join(" ");
};

export const dimensionsToText = (dimensions: ComponentDimensions): string => {
    return (
        oneDimensionToText(dimensions.width) +
        "W x " +
        oneDimensionToText(dimensions.height) +
        "H"
    );
};

export const calculateClearArea = (
    dimensions: ReactiveComponentDimensions,
): number => {
    const heightInches =
        dimensions.height.ft.value * 12 + dimensions.height.inch.value;
    const widthInches =
        dimensions.width.ft.value * 12 + dimensions.width.inch.value;

    const areaInSquareInches = widthInches * heightInches;
    const areaInSquareFeet = areaInSquareInches / 144;

    return parseFloat(areaInSquareFeet.toFixed(2));
};
