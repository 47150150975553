import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";

import { CalculationStrategy, PriceResult } from "../base/calculation.strategy";
import { Space } from "../../entities/space";

export class SubtotalCalculationStrategy extends CalculationStrategy {
    public calculate(
        spaces: Space[],
        [multiplicator = 1, halfInstallationWithDiscount],
    ): PriceResult {
        return {
            price:
                this.calculateComponentsPriceForType(
                    spaces,
                    CostDealType,
                    halfInstallationWithDiscount,
                ) * multiplicator,
            priceB2C:
                this.calculateComponentsPriceForType(
                    spaces,
                    B2CDealType,
                    halfInstallationWithDiscount,
                ) * multiplicator,
            priceB2B:
                this.calculateComponentsPriceForType(
                    spaces,
                    B2BDealType,
                    halfInstallationWithDiscount,
                ) * multiplicator,
        };
    }

    private calculateComponentsPriceForType(
        spaces: Space[],
        dealType: string,
        halfInstallationWithDiscount: number,
    ) {
        const subtotal = spaces.reduce((total, space) => {
            const sum = space.sum.getSystemPrice(dealType).getValue();

            return total + sum;
        }, 0);

        return subtotal + halfInstallationWithDiscount;
    }
}
