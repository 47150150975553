import {
    DoorNoneCode,
    KompasDoubleFramedDoorCode,
    KompasSingleFramedDoorCode,
} from "../constants";

export const isDoorNone = (type: string) => type === DoorNoneCode;

export const isKompasDoor = (type: string) => {
    return [KompasSingleFramedDoorCode, KompasDoubleFramedDoorCode].includes(
        type,
    );
};
