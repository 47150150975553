import { useDictStore } from "@/stores/dictStore";
import { IComponentConfig } from "@/app/@types/base-component";
import { GlassThicknessCode3_8 } from "@/app/constants";

export type ConfigBuilder = {
    structureType(value: string): ConfigBuilder;
    build(): IComponentConfig;
};

export const createOverdoorGlassMaterialConfig = () => {
    const dictStore = useDictStore();
    const glassSorts = dictStore.getGlassSorts();

    const defaultGlassSort = glassSorts.at(0);

    if (!defaultGlassSort) {
        return createOverdoorMaterialConfig();
    }

    return createOverdoorMaterialConfig().structureType(defaultGlassSort.code);
};

export const createOverdoorGlassMaterialThinknessConfig = () => {
    const dictStore = useDictStore();
    const glassThinkness = dictStore.getGlassThickness();
    const defaultGlassThinkness = glassThinkness.at(0);

    if (!defaultGlassThinkness) {
        return createOverdoorMaterialConfig();
    }

    return createOverdoorMaterialConfig().structureType(GlassThicknessCode3_8);
};

export const createOverdoorAluminiumMaterialConfig = () => {
    const dictStore = useDictStore();

    const aluminiumSorts = dictStore.getAluminumMaterialTypes();
    const defaultAluminiumSort = aluminiumSorts.at(0);

    if (!defaultAluminiumSort) {
        return createOverdoorMaterialConfig();
    }

    return createOverdoorMaterialConfig().structureType(
        defaultAluminiumSort.code,
    );
};

const createOverdoorMaterialConfig = (): ConfigBuilder => {
    const config: IComponentConfig = {
        qty: 1,
    };

    const builder = {
        structureType(value: string): ConfigBuilder {
            config.structureType = value;
            return builder;
        },
        build() {
            return config;
        },
    };

    return builder;
};
