import { Space } from "@/app/domain/entities/space";
import {
    ConfigBuilder,
    createMountsCGFBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsCGFTopFrameSortConfig,
    createMountsCGFSideFrameSortConfig,
    createMountsMullionsMiddleConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";
import { LineDividerCalculationService } from "@/app/services/line-dividers.service";
import { CGF350Code } from "@/app/constants";

const createSlidingDoorMountsCGFTopFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(space);

    return createMountsCGFTopFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFSideFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const sides = 2;

    const qty = totalHeight * sides;

    return createMountsCGFSideFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const qty = totalWidth - doorWidth;

    return createMountsCGFBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);
    const sides = 2;

    const horizontalGasket = totalWidth * sides;
    const verticalGasket = totalHeight * sides;

    const qty = (horizontalGasket + verticalGasket) * sides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsMiddleConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);
    const horizontalLinesQty =
        LineDividerCalculationService.calculateHorizontalDividers(
            CGF350Code,
            totalHeight,
        );

    const sides = 2;
    const qty = totalWidth * horizontalLinesQty * sides;

    return createMountsMullionsMiddleConfig().qty(qty).build();
};

export const CGF350NoDoorsConfigs = (space: Space) => [
    createSlidingDoorMountsCGFSideFrameSortConfig(space),
    createSlidingDoorMountsCGFTopFrameSortConfig(space),
    createSlidingDoorMountsCGFBottomFrameSortConfig(space),
    createSlidingDoorMountsGlazingGasketConfig(space),
    createSlidingDoorMountsMullionsMiddleConfig(space),
];
