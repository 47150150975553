import {
    CGFSystemCodes,
    CGS200Code,
    OverdoorFillerCode,
    OverdoorNoneCode,
    OverdoorTransomCode,
} from "@/app/constants";
import { ReactiveComponent } from "./base-component";
import { OverdoorMaterial } from "./overdoor-material";
import { ReactiveComponentDimensions } from "./dimensions";
import { Partition } from "./partition";
import { Door } from "./door";

import { IComponentConfig } from "@/app/@types/base-component";
import { IDimensions } from "@/app/@types/dimensions";

export class Overdoor extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "overdoor" });
    }

    resetDimensions = () => {
        this.update({ dimensions: new ReactiveComponentDimensions() });
    };

    setDimensionsFromPartition = (partition: Partition) => {
        if (
            !this.dimensions.height.isEmpty() &&
            !this.dimensions.width.isEmpty()
        ) {
            return;
        }

        const widthFromPartition = partition.dimensions.width.copy();
        const heightFromPartition = partition.dimensions.height.copy();

        widthFromPartition.update({ ft: 0 });
        heightFromPartition.update({ ft: 0 });

        this.dimensions.width.update(widthFromPartition);
        this.dimensions.height.update(heightFromPartition);
    };

    setTransomDimensions = (partition: Partition, door: Door) => {
        if (
            !this.dimensions.height.isEmpty() &&
            !this.dimensions.width.isEmpty()
        ) {
            return;
        }

        const remainingHeight = this.caclulateRemainingOverdoorHeight(
            partition,
            door,
        );

        this.dimensions.width.update(partition.dimensions.width);
        this.dimensions.height.update(remainingHeight.getValues());
    };

    replaceMaterial = (materials: OverdoorMaterial[]): void => {
        this.replaceComponents("overdoor-material", materials);
    };

    isNoneOverdoor = (): boolean => {
        return this.structureType?.value === OverdoorNoneCode;
    };

    isGlassOverdoor = (): boolean => {
        const materials = this.getMaterials();
        return materials.every((material) => material.isGlassMaterial());
    };

    isAluminiumOverdoor = (): boolean => {
        const materials = this.getMaterials();
        return materials.every((material) => material.isAlumiumMaterial());
    };

    isEditable = (): boolean => {
        return this.structureType?.value !== OverdoorNoneCode;
    };

    isFiller = (): boolean => {
        return this.structureType?.value === OverdoorFillerCode;
    };

    isTransom = (): boolean => {
        return this.structureType?.value === OverdoorTransomCode;
    };

    getMaterial = () => {
        return this.components.filter(
            (component) => component.type === "overdoor-material",
        );
    };

    getOutOfSquare = () => {
        return this.components.find(
            (component) => component.type === "outOfSquare",
        );
    };

    getAsset = () => {
        return this.components.find(
            (component) => component.type === "overdoor-asset",
        );
    };

    hasOverdoorAssets = (installedSystemCode: string): boolean => {
        return [CGFSystemCodes, CGS200Code].includes(installedSystemCode);
    };

    private getMaterials = (): OverdoorMaterial[] => {
        const materials: unknown[] =
            this.getComponentsByType("overdoor-material");

        return materials as OverdoorMaterial[];
    };

    private caclulateRemainingOverdoorHeight = (
        partition: Partition,
        door: Door,
    ): IDimensions => {
        return partition.dimensions.height.substract(door.dimensions.height);
    };
}
