import { Space } from "@/app/domain/entities/space";
import {
    createMountsBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsSideFrameSortConfig,
    createMountsStripesConfig,
    createMountsTopFrameSortConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";

const createSlidingDoorMountsTopFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(space);

    return createMountsTopFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsSideFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totaleHeight = MountsService.getTotalHeight(space);
    const sides = 2;

    const qty = totaleHeight * sides;

    return createMountsSideFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsBottomFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTotalWidth(space);

    return createMountsBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);

    const sides = 2;

    const horizontalGaskets = totalWidth * sides;
    const verticalGaskets = totalHeight * sides;

    const qty = (horizontalGaskets + verticalGaskets) * sides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

const createSlidingDoorMountsStripesConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getStipJointsWithoutDoorQty(space);

    return createMountsStripesConfig().qty(qty).build();
};

export const CGS200NoDoorsConfigs = (space: Space) => [
    createSlidingDoorMountsTopFrameSortConfig(space),
    createSlidingDoorMountsSideFrameSortConfig(space),
    createSlidingDoorMountsBottomFrameSortConfig(space),
    createSlidingDoorMountsGlazingGasketConfig(space),
    createSlidingDoorMountsStripesConfig(space),
];
