<template>
  <DimensionsTable v-model="activeDoor!.dimensions" />
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { DimensionsTable } from "@/shared";
import { useDoorsStore } from "@/stores/new/doors.store";
import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { isKompasSystem } from "@/app/predicates/systems.predicates";
import { usePartitionStore } from "@/stores/new/partition.store";

const doorsStore = useDoorsStore();
const estimateStore = useNewEstimateStore();
const partitionStore = usePartitionStore();

const activePartition = computed(() => partitionStore.activePartitions);
const activeDoor = computed(() => doorsStore.activeDoor);
const installedSystemCode = computed(
  () => estimateStore.estimate.installedSystemCode,
);

const SINGLE_DOOR_WIDTH_FT = 3;
const DOUBLE_DOOR_WIDTH_FT = 6;
const KOMPAS_DOOR_HEIGHT_FT = 7;

watch(
  () => activeDoor.value?.structureType,
  () => {
    if (activeDoor.value?.isDoorNone()) {
      return activeDoor.value?.resetDimensions();
    }

    activeDoor.value?.initDoorHeightFromPartition(activePartition.value!);

    if (activeDoor.value?.isSingleDoor()) {
      return activeDoor.value?.updateDoorWidth(SINGLE_DOOR_WIDTH_FT);
    }

    if (activeDoor.value?.isDoubleDoor()) {
      return activeDoor.value?.updateDoorWidth(DOUBLE_DOOR_WIDTH_FT);
    }

    if (isKompasSystem(installedSystemCode.value)) {
      return activeDoor.value?.updateDoorHeight(KOMPAS_DOOR_HEIGHT_FT);
    }
  },
  { immediate: true },
);

watch(
  () => activePartition.value?.dimensions,
  () => {
    if (!activeDoor.value) {
      return;
    }

    activePartition.value?.substractDoorIfExistAndUpdateDimensions(
      activeDoor.value,
    );
  },
  { immediate: true, deep: true },
);
</script>

<style lang="scss" scoped></style>
