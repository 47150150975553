<template>
  <select-dict
    v-model="structureType"
    :selectList="extraItems"
    :is-extra="true"
  />
</template>

<script setup lang="ts">
import { defineModel } from "vue";

import { HardwareNoneTypeCode } from "@/app/constants/hardwares";
import SelectDict from "@/components/UI/SelectDict.vue";
import { useDictStore } from "@/stores/dictStore";

const dictStore = useDictStore();

const structureType = defineModel<string>();
const extraItems = dictStore
  .getHardwareValues()
  .filter((item) => item.title !== HardwareNoneTypeCode);
</script>

<style lang="scss" scoped></style>
