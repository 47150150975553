import { IComponentConfig } from "@/app/@types/base-component";
import { ReactiveComponent } from "./base-component";

export class ExtraItem extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "extra-item" });
    }
}

export class Extra extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "extra" });
    }

    getExtraItems = () => {
        return this.components.filter(
            (component) => component.type === "extra-item",
        );
    };
}
