import {
    IComponentConfig,
    IStructureComponent,
} from "@/app/@types/base-component";

import { ReactiveComponent } from "../../entities/base-component";

type ComponentConstructor = new (
    config: IComponentConfig,
) => IStructureComponent;

export interface ICreateOneStrategy {
    create(): IStructureComponent;
}

export interface ICreateManyStrategy {
    create(): IStructureComponent[];
}

export class CreateOneStrategy implements ICreateOneStrategy {
    constructor(private config: IComponentConfig) {}

    create(): IStructureComponent {
        return new ReactiveComponent(this.config);
    }
}

export class CreateManyStrategy implements ICreateManyStrategy {
    constructor(
        private config: IComponentConfig[],
        private Component: ComponentConstructor,
    ) {}

    create(): IStructureComponent[] {
        return this.config.map((config) => {
            return new this.Component(config);
        });
    }
}
