import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { DoorNoneCode, MountPerimeterCode, NoMountCode } from "@/app/constants";

import { useSpacesStore } from "./spaces.store";
import { MountHardware } from "@/app/domain/entities/hardware";
import { MountsService } from "@/app/services/mounts.service";
import { useNewEstimateStore } from "./estimateStore";
import { useDoorsStore } from "./doors.store";
import { Mounts } from "@/app/domain/entities/mounts";
import { isSteelSystem } from "@/app/predicates/systems.predicates";
import { usePartitionStore } from "./partition.store";

export const useMountsStore = defineStore(
    "mounts-store",
    () => {
        const spacesStore = useSpacesStore();
        const partitionsStore = usePartitionStore();
        const estimateStore = useNewEstimateStore();
        const doorsStore = useDoorsStore();

        const activePartition = computed(
            () => partitionsStore.activePartitions,
        );
        const activeSpace = computed(() => spacesStore.activeSpace);
        const activeDoor = computed(() => doorsStore.activeDoor);

        const installedSystem = computed(
            () => estimateStore.estimate.installedSystemCode,
        );

        const activeMount = ref<Mounts | null>(null);
        const activeMountsHardwares = ref<MountHardware[]>([]);

        const hasActiveMounts = computed(() => Boolean(activeMount.value));

        const $reset = () => {
            activeMount.value = null;
            activeMountsHardwares.value = [];
        };

        const addMount = () => {
            if (!activePartition.value) {
                return;
            }

            const mount = new Mounts({
                structureType: isSteelSystem(installedSystem.value)
                    ? NoMountCode
                    : MountPerimeterCode,
            });

            activePartition.value.addComponent(mount);
            activeMount.value = mount;
        };

        const addMountHardware = () => {
            if (!activeSpace.value || !activeDoor.value) {
                return null;
            }

            const hardwares = MountsService.createMountsHardwares({
                installedSystemCode: installedSystem.value,
                activeDoorStructureType:
                    activeDoor.value?.structureType || DoorNoneCode,
                activeMountStructureType:
                    activeMount.value?.structureType || NoMountCode,
                space: activeSpace.value,
            });

            activeMount.value?.replaceMountHardwares(hardwares);
            activeMountsHardwares.value = hardwares;
        };

        return {
            addMountHardware,
            addMount,
            $reset,
            activeMount,
            activeMountsHardwares,
            hasActiveMounts,
        };
    },
    { persist: true },
);
