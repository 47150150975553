import { IComponentConfig } from "@/app/@types/base-component";
import {
    createMountsBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsStripesConfig,
    createMountsTopFrameSortConfig,
} from "../../base";
import { Space } from "@/app/domain/entities/space";
import { MountsService } from "@/app/services/mounts.service";

const createSlidingDoorMountsTopFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTotalWidth(space);
    return createMountsTopFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsBottomFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTotalWidth(space);

    return createMountsBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const sides = 4;

    const qty = totalWidth * sides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

const createSlidingDoorMountsStripesConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getStipJointsWithoutDoorQty(space);

    return createMountsStripesConfig().qty(qty).build();
};

export const CGS200NoDoorsConfigs = (space: Space) => [
    createSlidingDoorMountsTopFrameSortConfig(space),
    createSlidingDoorMountsBottomFrameSortConfig(space),
    createSlidingDoorMountsGlazingGasketConfig(space),
    createSlidingDoorMountsStripesConfig(space),
];
