<template>
  <div class="step">
    <div class="step__left">
      <div class="step__left-scroll">
        <div class="step__left-wrapper">
          <div :class="{ 'cursor-not-allowed': isEstimateReadonly }">
            <div
              class="step__left-content"
              :class="{ 'content-readonly': isEstimateReadonly }"
            >
              <!-- <input
                class="step__left-input"
                type="text"
                @input="renameEstimate"
                :value="estimate.info.title"
                placeholder="Enter Project Name..."
              /> -->

              <EstimateTitleField />
              <h2 class="step__left-title">
                <span>Step {{ $route.params.id }}</span>
                {{ arraySteps[$route.params.id - 1] }}
                <span class="step__left-title-spase">{{ getSpace() }}</span>
                <span>{{ getPartition() }}</span>
              </h2>
              <div class="step__left-progressbar">
                <div
                  :style="`width: calc((100% / ${arraySteps.length})*${+$route
                    .params.id})`"
                ></div>
              </div>
              <OneStep ref="oneStep" v-if="$route.params.id === '1'" />
              <TwoStep ref="twoStep" v-if="$route.params.id === '2'" />
              <ThreeStep ref="threeStep" v-if="$route.params.id === '3'" />
              <FourStep ref="fourStep" v-if="$route.params.id === '4'" />
              <FiveStep ref="fiveStep" v-if="$route.params.id === '5'" />
              <SixStep ref="sixStep" v-if="$route.params.id === '6'" />
              <SevenStep ref="sevenStep" v-if="$route.params.id === '7'" />
              <EightStep v-if="$route.params.id === '8'" />
            </div>
            <QuestionControl
              :typeAddButton="typeAddButton"
              @step="changeStep"
              @action="doAction"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="step__right">
      <div class="step__right-scroll">
        <InfoEstimate ref="infoEstimate" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch, ref, onMounted, onBeforeUnmount, Ref } from "vue";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { useNotification } from "@kyvg/vue3-notification";

import QuestionControl from "@/components/QuestionControl.vue";
import InfoEstimate from "@/components/InfoEstimate.vue";
import OneStep from "@/components/stepsData/OneStep.vue";
import TwoStep from "@/components/stepsData/TwoStep.vue";
import ThreeStep from "@/components/stepsData/ThreeStep.vue";
import FourStep from "@/components/stepsData/FourStep.vue";
import FiveStep from "@/components/stepsData/FiveStep.vue";
import SixStep from "@/components/stepsData/SixStep.vue";
import SevenStep from "@/components/stepsData/SevenStep.vue";
import EightStep from "@/components/stepsData/EightStep.vue";

import { useEstimateStore } from "@/stores/estimateStore";
import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { useSpacesStore } from "@/stores/new/spaces.store";
import { usePartitionStore } from "@/stores/new/partition.store";
import { useDoorsStore } from "@/stores/new/doors.store";
import { useUiStore } from "@/stores/uiStore";

import { Estimate } from "@/models/estimate";

import { EstimateTitleField } from "@/modules/estimate";
import { useOverdoorStore } from "@/stores/new/overdoor.store";
import { useActionsStrore } from "@/stores/new/actions.store";

const { notify } = useNotification();

const route = useRoute();
const router = useRouter();
const arraySteps: string[] = useUiStore().steps;

const estimateStore = useEstimateStore();

const newEstimateStore = useNewEstimateStore();
const spacesStore = useSpacesStore();
const partitionsStore = usePartitionStore();
const doorsStore = useDoorsStore();
const overdoorsStore = useOverdoorStore();

const actionsStore = useActionsStrore();

const uiStore = useUiStore();

const oneStep = ref(null);
const twoStep = ref(null);
const threeStep = ref(null);
const fourStep = ref(null);
const fiveStep = ref(null);
const sixStep = ref(null);
const sevenStep = ref(null);
const infoEstimate = ref(null);

// const isEstimateReadonly = estimateStore.getEstimateReadonly();
const isEstimateReadonly = newEstimateStore.getEstimateReadonly();

const isExtraMode: boolean = false;
let typeAddButton: string = "Transom";

onMounted(() => {
  if (route.params.id === 0 || route.params.id > 8) {
    router.push(`/notFound`);
  }
  // window.addEventListener("beforeunload", leaving);
});

onBeforeUnmount(() => {
  // window.removeEventListener("onbeforeunload", leaving);
});

onBeforeRouteLeave((to, from) => {
  // return window.confirm(
  //     "Do you really want to leave? you have unsaved changes!",
  // );
});

function getPartition() {
  // if (estimateStore.estimate.spaces[0].structures[0]) {
  //   return estimateStore.estimate.spaces[0].structures[0].partition.title;
  // }

  return partitionsStore.activePartitions?.title || "";
}

function getSpace() {
  // if (estimateStore.estimate.spaces[0]) {
  //   return estimateStore.estimate.spaces[0].title;
  // }

  return spacesStore.activeSpace?.title || "";
}

function leaving(event) {
  event.preventDefault();
  return null;
  //return "";
}

function changeTypeAddButton(type) {
  typeAddButton = type;
}

function saveCurrentPageToLocal() {
  const currentPage = +route.params.id;
  let currentPageRef: Ref =
    currentPage > 0 && currentPage < 8 ? getPageRefByNo(currentPage) : null;

  if (
    currentPageRef &&
    currentPageRef.value &&
    currentPageRef.value.hasOwnProperty("savePage")
  ) {
    currentPageRef.value.savePage();
  }
}

function doAction(actionName: string) {
  //const allowedActions = ['addDoor', 'addFiller', 'addSpace', 'addPartition', 'addMounts', 'saveDraft'];
  console.debug("action captured", actionName);
  switch (actionName) {
    case "addSpace":
      actionsStore.addAnotherSpace();
      setCalculationStep(2, false);
      break;
    case "addPartition":
      actionsStore.addAnotherPartition();
      setCalculationStep(2, false);
      break;
    case "addDoor":
      if (threeStep.value) {
        doorsStore.addDoor();
      }
      break;
    case "addFiller":
      if (fourStep.value) {
        overdoorsStore.addOverdoor();
      }
      break;
    // case "addMounts":
    //   break;
    // case "addDeliveryExtra":
    //   sevenStep.value.addExtra();
    //   break;
    // case "saveDeliveryExtra":
    //   sevenStep.value.saveExtra();
    //   break;

    case "saveDraft":
      saveCurrentPageToLocal();
      estimateStore.saveLocal();
      estimateStore.save();
      // router.push({name:'calculationTable'});
      notify({
        title: "Save as a draft",
      });
      break;
    case "saveOfficial":
      saveCurrentPageToLocal();
      estimateStore.setEstimateNo();
      estimateStore.saveWithStatus("OFFICIAL");
      router.push({ name: "calculationTable" });
      break;
    case "saveQuote":
      saveCurrentPageToLocal();
      estimateStore.saveWithStatus("QUOTE");
      router.push({ name: "calculationTable" });
      break;
    case "saveOrder":
      saveCurrentPageToLocal();
      estimateStore.saveWithStatus("ORDER");
      uiStore.setSelectedTableRow(estimateStore.activeEstimateCode);
      router.push({ name: "calculationTable" });
      break;
    case "saveDone":
      saveCurrentPageToLocal();
      estimateStore.saveWithStatus("DONE");
      uiStore.setSelectedTableRow(estimateStore.activeEstimateCode);
      router.push({ name: "calculationTable" });
      break;

    case "cloneEstimate":
      estimateStore.cloneEstimate();
    default:
      console.error("invalid action", actionName);
  }
}

function changeStep(direction: string) {
  switch (direction) {
    case "prev":
      if (+route.params.id > 1) {
        // do not validate when step back
        setCalculationStep(<number>route.params.id - 1, false);
      } else {
        router.push(`/calculation`);
      }
      break;
    case "next":
      if (+route.params.id < arraySteps.length) {
        setCalculationStep(+route.params.id + 1);
      }
      break;
    default:
      console.error("invalid step direction", direction);
  }
}

function setCalculationStep(nextStep: number, doValidation: boolean = true) {
  const currentPage = +route.params.id;

  newEstimateStore.updateProgressInfo({
    validatedPage: currentPage,
    activePage: nextStep,
  });

  estimateStore.updateProgressInfo({
    validatedPage: currentPage,
    activePage: nextStep,
  });

  const currentPageRef: Ref = getPageRefByNo(currentPage);

  const hasValidation = currentPageRef?.value?.hasOwnProperty("validatePage");

  const errorMessage = hasValidation
    ? currentPageRef.value.validatePage()
    : null;

  console.log("errorMessage", errorMessage);

  if (errorMessage) {
    return alert(
      errorMessage ||
        "Validation error\nSome of the required fields are not filled correctly",
    );
  }

  router.push(`/calculation/${nextStep}`);
  actionsStore.reloadSummary();

  // let validated = !doValidation;
  // const pageReloadNeeded = +route.params.id === nextStep && nextStep !== 8;
  // let validatedPageNo = 0;
  // let currentPageRef: Ref =
  //   currentPage > 0 && currentPage < 8 ? getPageRefByNo(currentPage) : null;
  // let nextPageRef: Ref =
  //   currentPage > 0 && currentPage < 8 ? getPageRefByNo(currentPage) : null;
  // const progressInfo: ProgressInfo = estimateStore.estimate.progress;

  // if (
  //   doValidation &&
  //   currentPageRef &&
  //   currentPageRef.value &&
  //   currentPageRef.value.hasOwnProperty("validatePage")
  // ) {
  //   validated = currentPageRef.value.validatePage();
  // }

  // if (
  //   currentPageRef &&
  //   currentPageRef.value &&
  //   currentPageRef.value.hasOwnProperty("savePage")
  // ) {
  //   currentPageRef.value.savePage();
  // }

  // if (
  //   pageReloadNeeded &&
  //   currentPageRef &&
  //   currentPageRef.value &&
  //   currentPageRef.value.hasOwnProperty("reloadPage")
  // ) {
  //   currentPageRef.value.reloadPage();
  // }

  // validatedPageNo =
  //   progressInfo.validatedPage > currentPage
  //     ? progressInfo.validatedPage
  //     : currentPage;

  // estimateStore.updateProgressInfo({
  //   validatedPage: currentPage,
  //   activePage: nextStep,
  // });

  // if (validated) {
  //   infoEstimate.value.reloadSummary();
  //   router.push(`/calculation/${nextStep}`);
  // } else {
  //   alert(
  //     "Validation error\nSome of the required fields are not filled correctly",
  //   );
  //   console.debug("estimate validation error");
  // }
}

function getPageRefByNo(pageNo: number): Ref {
  switch (pageNo) {
    case 1:
      return oneStep;
    case 2:
      return twoStep;
    case 3:
      return threeStep;
    case 4:
      return fourStep;
    case 5:
      return fiveStep;
    case 6:
      return sixStep;
    case 7:
      return sevenStep;
    default:
      return null;
  }
}

watch(
  () => uiStore.pageChangeFlag,
  () => {
    console.debug("watch pageChangeFlag", uiStore.newPageNo);
    if (uiStore.newPageNo > 0 && uiStore.newPageNo < 8) {
      setCalculationStep(uiStore.newPageNo, false);
    }
  },
);

function renameEstimate(e) {
  if (route.params.id && +route.params.id === 1) {
    oneStep.value.renameEstimate(e.target.value);
  } else {
    estimateStore.renameEstimate(e.target.value);
  }
}
</script>

<style scoped>
.step__right {
  padding: 40px 30px;
  height: 99vh;
  overflow: auto;
}

.step__right-scroll {
  width: 100%;
  min-width: 720px;
}

.step__left-scroll {
  overflow-x: auto;
  background: #f3f3f6;
}

.step__left-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 20px 45px 20px;
  height: 99vh;
  width: 720px;
  overflow-y: auto;
}

.step {
  display: grid;
  grid-template-columns: 720px 1fr;
  height: 100%;
}

.step__left {
  background: #f3f3f6;
  height: 100%;
}

.step__left-input {
  color: rgba(64, 77, 99, 1);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d2d8e3;
  width: 100%;
}

.step__left-title {
  color: #404d63;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.step__left-title span {
  font-weight: 400;
}

.step__left-title-spase {
  padding-right: 15px;
  padding-left: 10px;
}

.step__left-progressbar {
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  height: 6px;
  margin-bottom: 20px;
}

.step__left-progressbar div {
  border-radius: 10px;
  background-color: #2491eb;
  height: 100%;
  transition: 0.5s ease;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
</style>
