import { IComponentConfig } from "@/app/@types/base-component";
import { ReactiveComponent } from "./base-component";
import { ref, Ref } from "vue";

export type OverheadConfig = IComponentConfig & {
    unit: string;
};

export class OverheadItem extends ReactiveComponent {
    unit: Ref<string>;

    constructor(config: OverheadConfig) {
        super({ ...config, type: "overhead-item" });

        this.unit = ref(config.unit);
    }
}

export class Overheads extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "overheads" });
    }

    replaceOverheadItems = (overheads: OverheadItem[]): void => {
        this.replaceComponents("overhead-item", overheads);
    };

    getOverheadItems = () => {
        return this.components.filter(
            (component) => component.type === "overhead-item",
        );
    };
}
