import { useDictStore } from "@/stores/dictStore";
import { SystemPredicates } from "@/app/predicates";

import { PricingStrategy, PriceResult } from "../base/pricing.strategy";

const KOMPAS_DLB_MULTIPLICATOR = 2;
const DEFAULT_MULTIPLICATOR = 1;

export class GlassPricingStrategy extends PricingStrategy {
    private dictStore = useDictStore();

    public getPrices(...args: unknown[]): PriceResult {
        const [installedSystemCode, glassSortCode, glassThicknessCode] =
            args as [string, string, string];

        const multiplicator = SystemPredicates.isKompasDlb(installedSystemCode)
            ? KOMPAS_DLB_MULTIPLICATOR
            : DEFAULT_MULTIPLICATOR;

        const pricesFromDict = this.dictStore.findGlassPrice(
            glassSortCode,
            glassThicknessCode,
        );

        if (!this.validatePricesFromDict(pricesFromDict)) {
            return this.getDefaultPrices();
        }

        return {
            price: pricesFromDict.price! * multiplicator,
            priceB2C: pricesFromDict.priceB2C! * multiplicator,
            priceB2B: pricesFromDict.priceB2B! * multiplicator,
        };
    }
}
