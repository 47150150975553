import { Dictionary } from "@/models/dictionary";

export interface IPricingStrategy {
    getPrices(...args: unknown[]): PriceResult;
}

export interface PriceResult {
    price: number;
    priceB2C: number;
    priceB2B: number;
}

export abstract class PricingStrategy implements IPricingStrategy {
    abstract getPrices(...args: unknown[]): PriceResult;

    getDefaultPrices() {
        return {
            price: 0,
            priceB2C: 0,
            priceB2B: 0,
        };
    }

    validatePricesFromDict(pricesFromDict: Dictionary): boolean {
        if (!pricesFromDict) {
            return false;
        }

        const pricesFields = ["price", "priceB2C", "priceB2B"];

        return pricesFields.every(
            (field) => pricesFromDict[field] !== undefined,
        );
    }
}
