import { GlassMaterialCode, SheetMaterialCode } from "@/app/constants";
import { ReactiveComponent } from "./base-component";

import { IComponentConfig } from "@/app/@types/base-component";

export class OverdoorMaterial extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "overdoor-material" });
    }

    isGlassMaterial = (): boolean => {
        return this.structureType?.value === GlassMaterialCode;
    };

    isAlumiumMaterial = (): boolean => {
        return this.structureType?.value === SheetMaterialCode;
    };
}
