import { ReactiveComponent } from "../../entities/base-component";

export type CalculationStrategyConfig = {
    dealType: string;
};

export interface ICalculationStrategy {
    calculate(
        component: ReactiveComponent | ReactiveComponent[],
        ...args: unknown[]
    ): PriceResult;
}

export interface PriceResult {
    price: number;
    priceB2C: number;
    priceB2B: number;
}

export abstract class CalculationStrategy implements ICalculationStrategy {
    abstract calculate(
        component: ReactiveComponent | ReactiveComponent[],
        ...args: unknown[]
    ): PriceResult;

    protected getDefaultResult(): PriceResult {
        return {
            price: 0,
            priceB2C: 0,
            priceB2B: 0,
        };
    }

    protected validateResult(result: number): boolean {
        return result !== undefined;
    }
}
