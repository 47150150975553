import { IComponentConfig } from "@/app/@types/base-component";
import {
    createMountsCGFBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsCGFTopFrameSortConfig,
} from "../../base";
import { MountsService } from "@/app/services/mounts.service";
import { Space } from "@/app/domain";

const createSlidingDoorMountsCGFTopFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(space);

    return createMountsCGFTopFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const qty = totalWidth - doorWidth;

    return createMountsCGFBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const sides = 4;

    const qty = totalWidth * sides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

export const CGF300NoDoorsConfigs = (space: Space) => [
    createSlidingDoorMountsCGFTopFrameSortConfig(space),
    createSlidingDoorMountsCGFBottomFrameSortConfig(space),
    createSlidingDoorMountsGlazingGasketConfig(space),
];
