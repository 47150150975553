import { Space } from "@/app/domain/entities/space";
import {
    createMountsCGFFrameConfig,
    createMountsCGFBottomFrameSortConfig,
    createMountsCGFSideFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsCGFTopFrameSortConfig,
} from "../../base";
import { MountsService } from "@/app/services/mounts.service";
import { IComponentConfig } from "@/app/@types/base-component";

const createSlidingDoorMountsCGFTopFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(space);

    return createMountsCGFTopFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFSideFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const sides = 2;

    const qty = totalHeight * sides;

    return createMountsCGFSideFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const qty = totalWidth - doorWidth;

    return createMountsCGFBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFFrameConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);
    const doorMultiplier = MountsService.isForDoubleDoor(space) ? 6 : 4;
    const sides = 2;

    const verticalProfiles = totalHeight * doorMultiplier;
    const horizontalProfiles = doorWidth * sides;

    const qty = verticalProfiles + horizontalProfiles;

    return createMountsCGFFrameConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);
    const hasOverdoorTransom = MountsService.hasOverdoorTransom(space);

    const totalSides = 2;

    /**
     * Если над дверью есть transom (фрамуга), то для такой двери нужно учитывать только одну сторону
     * Так как возможно для фрамуги уже есть `glazing gasket` и ее не нужно ставить заново
     * */
    const doorSides = hasOverdoorTransom ? 1 : 2;

    const doorDeduction = doorWidth * doorSides;
    const verticalGaskets = totalHeight * totalSides;
    const horizontalGaskets = totalWidth * totalSides - doorDeduction;

    const qty = (verticalGaskets + horizontalGaskets) * totalSides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

export const CGF300SwingDoorsConfigs = (space: Space) => [
    createSlidingDoorMountsCGFTopFrameSortConfig(space),
    createSlidingDoorMountsCGFSideFrameSortConfig(space),
    createSlidingDoorMountsCGFBottomFrameSortConfig(space),
    createSlidingDoorMountsCGFFrameConfig(space),
    createSlidingDoorMountsGlazingGasketConfig(space),
];
