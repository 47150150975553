<template>
  <StepTotalPrice
    v-if="overdoorWithoutAssetsSum"
    title="Total"
    :price="overdoorWithoutAssetsSum"
  />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { Pricing } from "@/app/domain";
import { StepTotalPrice } from "@/shared";
import { PricingService } from "@/app/services/pricing.service";

import { useOverdoorStore } from "@/stores/new/overdoor.store";

const overdoorStore = useOverdoorStore();
const pricingService = new PricingService();

const activeOverdoor = computed(() => overdoorStore.activeOverdoor);
const overdoorWithoutAssetsSum = ref<Pricing>();

watch(
  () => activeOverdoor.value?.components,
  () => {
    const sum = pricingService.calculateOverdoorWithoutAssetsPrice();

    overdoorWithoutAssetsSum.value = new Pricing(
      sum.price,
      sum.priceB2B,
      sum.priceB2C,
    );
  },
  { immediate: true, deep: true },
);
</script>
