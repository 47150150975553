import { MountHardware } from "../../entities/hardware";
import { Space } from "../../entities/space";
import { CreateManyStrategy } from "../base/creation.strategy";

import {
    CGF300PerimeterConfigs,
    CGF350PerimeterConfigs,
    CGF400PerimeterConfigs,
    CGS200PerimeterConfigs,
    KompasPerimeterConfigs,
    TreviPerimeterConfigs,
    RomanoPerimeterConfigs,
} from "@/app/domain/configs/mounts/perimeter";

import {
    CGF300TopbottomConfigs,
    CGF350TopbottomConfigs,
    CGF400TopbottomConfigs,
    CGS200TopbottomConfigs,
    KompasTopbottomConfigs,
} from "@/app/domain/configs/mounts/topbottom";

/**
 * System: CGF300
 * Mount Type: Perimeter
 *  */

export class CGF300PerimeterNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF300PerimeterConfigs.noDoors(space!), MountHardware);
    }
}

export class CGF300PerimeterSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF300PerimeterConfigs.slidingDoors(space!), MountHardware);
    }
}

export class CGF300PerimeterSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF300PerimeterConfigs.swingDoors(space!), MountHardware);
    }
}

/**
 * System: CGF350
 * Mount Type: Perimeter
 *  */

export class CGF350PerimeterNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF350PerimeterConfigs.noDoors(space!), MountHardware);
    }
}

export class CGF350PerimeterSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF350PerimeterConfigs.slidingDoors(space!), MountHardware);
    }
}

export class CGF350PerimeterSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF350PerimeterConfigs.swingDoors(space!), MountHardware);
    }
}

/**
 * System: CGF400
 * Mount Type: Perimeter
 *  */

export class CGF400PerimeterNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF400PerimeterConfigs.noDoors(space!), MountHardware);
    }
}

export class CGF400PerimeterSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF400PerimeterConfigs.slidingDoors(space!), MountHardware);
    }
}

export class CGF400PerimeterSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF400PerimeterConfigs.swingDoors(space!), MountHardware);
    }
}

/**
 * System: CGS200
 * Mount Type: Perimeter
 *  */

export class CGS200PerimeterNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGS200PerimeterConfigs.noDoors(space!), MountHardware);
    }
}

export class CGS200PerimeterSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGS200PerimeterConfigs.slidingDoors(space!), MountHardware);
    }
}

export class CGS200PerimeterSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGS200PerimeterConfigs.swingDoors(space!), MountHardware);
    }
}

/**
 * System: Kompas
 * Mount Type: Perimeter
 *  */

export class KompasPerimeterNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(KompasPerimeterConfigs.noDoors(space!), MountHardware);
    }
}

export class KompasPerimeterSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(KompasPerimeterConfigs.slidingDoors(space!), MountHardware);
    }
}

export class KompasPerimeterSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(KompasPerimeterConfigs.swingDoors(space!), MountHardware);
    }
}

/**
 * System: Trevi
 * Mount Type: No mount
 *  */

export class TreviNoMountAnyDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(TreviPerimeterConfigs.anyDoors(space!), MountHardware);
    }
}

/**
 * System: Romano
 * Mount Type: No mount
 *  */

export class RomanoNoMountAnyDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(RomanoPerimeterConfigs.anyDoors(space!), MountHardware);
    }
}

/**
 * System: CGF300
 * Mount Type: Topbottom
 *  */

export class CGF300TopbottomNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF300TopbottomConfigs.noDoors(space!), MountHardware);
    }
}

export class CGF300TopbottomSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF300TopbottomConfigs.slidingDoors(space!), MountHardware);
    }
}

export class CGF300TopbottomSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF300TopbottomConfigs.swingDoors(space!), MountHardware);
    }
}

/**
 * System: CGF350
 * Mount Type: Topbottom
 *  */

export class CGF350TopbottomNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF350TopbottomConfigs.noDoors(space!), MountHardware);
    }
}

export class CGF350TopbottomSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF350TopbottomConfigs.slidingDoors(space!), MountHardware);
    }
}

export class CGF350TopbottomSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF350TopbottomConfigs.swingDoors(space!), MountHardware);
    }
}

/**
 * System: CGF400
 * Mount Type: Topbottom
 *  */

export class CGF400TopbottomNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF400TopbottomConfigs.noDoors(space!), MountHardware);
    }
}

export class CGF400TopbottomSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF400TopbottomConfigs.slidingDoors(space!), MountHardware);
    }
}

export class CGF400TopbottomSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGF400TopbottomConfigs.swingDoors(space!), MountHardware);
    }
}

/**
 * System: CGS200
 * Mount Type: Topbottom
 *  */

export class CGS200TopbottomNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGS200TopbottomConfigs.noDoors(space!), MountHardware);
    }
}

export class CGS200TopbottomSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGS200TopbottomConfigs.slidingDoors(space!), MountHardware);
    }
}

export class CGS200TopbottomSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(CGS200TopbottomConfigs.swingDoors(space!), MountHardware);
    }
}

/**
 * System: Kompas
 * Mount Type: Topbottom
 *  */

export class KompasTopbottomNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(KompasTopbottomConfigs.noDoors(space!), MountHardware);
    }
}

export class KompasTopbottomSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(KompasTopbottomConfigs.slidingDoors(space!), MountHardware);
    }
}

export class KompasTopbottomSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(space: Space) {
        super(KompasPerimeterConfigs.swingDoors(space!), MountHardware);
    }
}
