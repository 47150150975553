<template>
  <input v-if="user.isSalesManager" v-model="authManager.title" />
  <select-input-dict
    v-else
    label="title"
    v-model="managerCode"
    :select-list="managers"
    :options="managers"
  />
</template>

<script setup lang="ts">
import { computed, defineModel, watch } from "vue";

import SelectInputDict from "@/components/UI/SelectInputDict.vue";

import { useDictStore } from "@/stores/dictStore";
import { useUserStore } from "@/stores/userStore";

const dictStore = useDictStore();
const user = useUserStore();
const isSalesManager = computed(() => user.isSalesManager);

const managers = dictStore.getManagers();
const authManager = computed(
  () => managers.filter((item) => item.code === user.userCode)[0],
);

const managerCode = defineModel<string>({
  default: "",
});

watch(
  () => isSalesManager,
  () => {
    if (isSalesManager.value) {
      managerCode.value = authManager.value.code;
    }
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped></style>
