import "reflect-metadata";
import "@/lib/axios";
import "./bootstrap";

import { createApp, ref } from "vue";
import { createPinia } from "pinia";

import vSelect from "vue-select";
import Notifications from "@kyvg/vue3-notification";

import App from "./App.vue";
import router from "./router";

import "./assets/css/normalize.css";
import "./assets/css/reset.css";
import "./assets/css/style.css";
import { createPersistedState } from "pinia-plugin-persistedstate";
import { PiniaSerializerService } from "./app/services/pinia-serializer.service";

const app = createApp(App);
const pinia = createPinia();

pinia.use(
    createPersistedState({
        debug: true,

        serializer: {
            serialize: PiniaSerializerService.serialize,
            deserialize: PiniaSerializerService.deserialize,
        },
    }),
);

app.component("v-select", vSelect);
app.use(pinia);
app.use(router);
app.use(Notifications);

app.mount("#app");
