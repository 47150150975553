<template>
  <div class="step__block-wrapper">
    <div class="step__block-container step__block-container--top">
      <div></div>
      <div></div>
      <div class="step__block-div">
        <span>Q-ty</span>
      </div>
      <div>
        <span>{{ dealType }}</span>
      </div>
      <div>
        <span>SUM</span>
      </div>
    </div>
    <MountHardwareItem
      v-for="hardware in activeMountHardwares"
      :key="hardware.structureType"
      :hardware="hardware"
      v-model:structureType="hardware.structureType"
      v-model:qty="hardware.qty"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { useMountsStore } from "@/stores/new/mounts.store";

import MountHardwareItem from "./MountHardwareItem.vue";

const mountsStore = useMountsStore();
const estimateStore = useNewEstimateStore();

const dealType = computed(() => estimateStore.estimate.dealTypeCode);

const activeMountHardwares = computed(() => mountsStore.activeMountsHardwares);
</script>

<style lang="scss" scoped>
.step__block-wrapper {
  display: grid;
  row-gap: 5px;
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
}

.step__block-count {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  column-gap: 15px;
}

.step__block-container {
  display: grid;
  grid-template-columns: 120px 280px 92px repeat(2, 1fr);
  column-gap: 15px;
}

.step__block-wrapper--bottom .step__block-container {
  grid-template-columns: 180px 220px 72px repeat(2, 1fr);
}

.step__block-container--top {
  margin-bottom: 10px;
}

.step__block-container div:first-child {
  align-items: flex-start;
}

.step__block-container div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.step__block-container div.step__block-div {
  align-items: center;
}

.step__block-container div input {
  text-align: center;
  padding: 0 10px;
  width: 70px;
  height: 35px;
  border-radius: 20px;
  background: #fff;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-field {
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  border-bottom: 1px solid #d2d8e3;
}

.step__block-field h3 {
  color: #404d63;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step__block-field--hardware .step__block-field-block {
  justify-content: flex-start;
  column-gap: 10px;
}

.step__block-doortype {
  display: grid;
  grid-template-columns: minmax(0, max-content) 316px;
  column-gap: 26px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 15px;
}

.step__block-doortype--transform {
  padding-top: 15px;
}

.step__block-doortype div:first-child {
  align-self: center;
  justify-self: center;
}

.select {
  margin-right: 20px;
}
</style>
