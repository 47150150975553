<template>
  <div class="step__block-doortype" v-if="activeOverdoor">
    <div>
      <span>Transom or filler:</span>
    </div>
    <div>
      <OverdoorTypeSelect v-model="activeOverdoor.structureType" />
    </div>
  </div>
  <div v-if="isOverdoorEditable" class="step__block-field">
    <div class="step__block-field-block">
      <h3>{{ activeOverdoor?.structureType }} Dimensions:</h3>
    </div>
    <DimensionsError
      :dimensions="activeOverdoor?.dimensions"
      :validator="
        (overdoorDimensions) =>
          ValidationService.validateOverdoorDimensions(
            overdoorDimensions,
            activeDoor!.dimensions,
            activePartition!.dimensions,
          )
      "
    />
  </div>
  <OverdoorDimensionsTable
    v-if="isOverdoorEditable && activeOverdoor"
    v-model="activeOverdoor.dimensions"
  />
  <OverdoorMaterialPriceTable v-if="isOverdoorEditable" />

  <!-- Render total without assets, out of square only when render assets, out of square -->
  <OverdoorWithoutAssetsPrice v-if="isTransom && hasOverdoorAssets" />

  <div class="step__block-partitionglass-total" v-if="hasOverdoorAssets">
    <div class="step__block-partitionglass-line"></div>

    <OverdoorOutOfSquarePriceTable />
    <OverdoorAssetsPriceTable />
  </div>

  <OverdoorTotalPrice v-if="isOverdoorEditable && activeOverdoor" />
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";

import {
  OverdoorDimensionsTable,
  OverdoorTypeSelect,
  OverdoorMaterialPriceTable,
  OverdoorOutOfSquarePriceTable,
  OverdoorAssetsPriceTable,
  OverdoorWithoutAssetsPrice,
  OverdoorTotalPrice,
} from "@/modules/overdoor";
import { useOverdoorStore } from "@/stores/new/overdoor.store";
import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { DimensionsError } from "@/shared";
import { ValidationService } from "@/app/services/validation.service";
import { useDoorsStore } from "@/stores/new/doors.store";
import { usePartitionStore } from "@/stores/new/partition.store";

defineExpose({ validatePage });

function validatePage() {
  return ValidationService.validateOverdoor(
    activeOverdoor.value,
    activeDoor.value,
    activePartition.value,
  );
}

const overdoorStore = useOverdoorStore();
const estimateStore = useNewEstimateStore();
const doorsStore = useDoorsStore();
const paritionStore = usePartitionStore();

const activeDoor = computed(() => doorsStore.activeDoor);
const activePartition = computed(() => paritionStore.activePartitions);

const activeOverdoor = computed(() => overdoorStore.activeOverdoor);
const dealType = computed(() => estimateStore.estimate.dealTypeCode);

const isOverdoorEditable = computed(() => activeOverdoor.value?.isEditable());
const isTransom = computed(() => activeOverdoor.value?.isTransom());

const hasOverdoorAssets = computed(
  () => activeOverdoor.value?.hasOverdoorAssets(dealType.value),
);

const hasActiveOverdoor = computed(() => overdoorStore.hasActiveOverdoor);

onMounted(() => {
  if (hasActiveOverdoor.value) {
    return;
  }

  overdoorStore.addOverdoor();
  overdoorStore.addOverdoorOutOfSquare();
  overdoorStore.addOverdoorAsset();
});

onMounted(() => {
  if (hasActiveOverdoor.value) {
    return;
  }

  if (!activeOverdoor.value) {
    return;
  }

  const overdoorType = overdoorStore.detectOverdoorType();
  activeOverdoor.value.structureType = overdoorType;
});
</script>

<style scoped>
.step__block-fillertype-container {
  display: grid;
  row-gap: 5px;
  padding: 15px 0;
}

.step__block-fillertype-container .step__block-fillertype-wrap:first-child {
  padding-bottom: 5px;
}

.step__block-fillertype {
  color: #404d63;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-fillertype-wrap div:first-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.step__block-fillertype-wrap div {
  align-self: center;
}

.step__block-fillertype-wrap {
  display: grid;
  grid-template-columns: 70px 316px repeat(2, 1fr);
  column-gap: 50px;
}

.step__block-hardwareset {
  padding: 15px 0;
  display: grid;
  row-gap: 5px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-hardwareset-item {
  display: grid;
  grid-template-columns: 100px 1fr;
  column-gap: 31px;
}

.step__block-hardwareset-item div:first-child {
  align-self: center;
}

.step__block-doortype {
  display: grid;
  grid-template-columns: minmax(0, max-content) 316px;
  column-gap: 26px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 15px;
}

.step__block-doortype--transform {
  padding-top: 15px;
}

.step__block-doortype div:first-child {
  align-self: center;
  justify-self: center;
}

.step__block-field {
  display: flex;
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  border-bottom: 1px solid #d2d8e3;
}

.step__block-field h3 {
  color: #404d63;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.step__block-field--hardware .step__block-field-block {
  justify-content: flex-start;
  column-gap: 10px;
}

.step__block-dimensions {
  padding: 15px 0;
}

.step__block-dimensions-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step__block-dimensions-right {
  display: flex;
  column-gap: 92px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-dimensions-right div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.step__block-dimensions-right div:last-child {
  width: 65px;
}

.step__block-dimensions {
  padding: 15px 0;
}

.step__block-dimensions-left {
  display: grid;
  grid-template-columns: minmax(0, 46px) repeat(2, 1fr);
  column-gap: 10px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-dimensions-units {
  display: grid;
  grid-template-columns: repeat(3, 60px);
  padding-bottom: 11px;
}

.step__block-dimensions-units div {
  padding-left: 11px;
}

.step__block-dimensions-container {
  display: grid;
  grid-template-columns: repeat(4, 60px);
}

.step__block-dimensions-container div {
  background-color: #fff;
  height: 35px;
}

.step__block-dimensions-container div input {
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 7px;
}

.step__block-dimensions-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.step__block-dimensions-block--table:first-child
  .step__block-dimensions-container
  div {
  border-bottom: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table .step__block-dimensions-container div {
  border-right: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table
  .step__block-dimensions-container
  div:last-child {
  border-right: none;
}

.step__block-partitionglass {
  padding: 15px 0;
}

.step__block-partitionglass-block {
  display: grid;
  grid-template-columns: 35% 150px repeat(2, 1fr);
  column-gap: 15px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  column-gap: 15px;
}

.step__block-partitionglass-block--selects {
  padding-top: 10px;
}

.step__block-partitionglass-subblock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.step__block-partitionglass-subblock--first {
  align-items: flex-start;
}

.step__block-partitionglass-subblock--unit {
  padding-right: 10px;
}

.step__block-partitionglass-subblock input {
  width: 50px;
  height: 35px;
  border-radius: 20px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
}

.step__block-partitionglass-total {
  padding: 15px 0;
  display: grid;
  row-gap: 15px;
}

.step__block-partitionglass-line {
  width: 100%;
  height: 1px;
  background-color: #d2d8e3;
}

.step__block-dimensions-warning {
  align-self: flex-start;
  justify-self: flex-start;
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: #ff6255;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 25px;
}
</style>
