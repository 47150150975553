import { useDictStore } from "@/stores/dictStore";

import { PricingStrategy, PriceResult } from "../base/pricing.strategy";
import { isKompasSystem } from "@/app/predicates/systems.predicates";

export class MountHardwarePricingStrategy extends PricingStrategy {
    private dictStore = useDictStore();

    public getPrices(...args: unknown[]): PriceResult {
        const [installedSystemCode, hardwareTypeCode, hardwareStructureType] =
            args as string[];

        const multiplicator = isKompasSystem(installedSystemCode) ? 2 : 1;

        const pricesFromDict = this.dictStore.findMountHardwarePrice(
            hardwareTypeCode,
            hardwareStructureType,
        );

        if (!this.validatePricesFromDict(pricesFromDict)) {
            return this.getDefaultPrices();
        }

        return {
            price: pricesFromDict.price! * multiplicator,
            priceB2C: pricesFromDict.priceB2C! * multiplicator,
            priceB2B: pricesFromDict.priceB2B! * multiplicator,
        };
    }
}
