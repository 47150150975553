import { StateTree } from "pinia";

import {
    Door,
    DoorCut,
    GlassPartition,
    GlassThinknessPartition,
    Overdoor,
    OverdoorMaterial,
    Partition,
    ReactiveComponent,
    Space,
} from "../domain";
import { Extra } from "../domain/entities/extra";
import { Hardware, MountHardware } from "../domain/entities/hardware";
import { OutOfSquare } from "../domain/entities/out-of-square";
import { OverdoorAsset } from "../domain/entities/overdoor-asset";
import { OverheadItem, Overheads } from "../domain/entities/overhead";
import { Delivery, DeliveryItem } from "../domain/entities/delivery";
import { Mounts } from "../domain/entities/mounts";

export class PiniaSerializerService {
    static serialize = (state: StateTree): string => {
        const serializedStore = Object.keys(state).reduce((acc, key) => {
            const value = state[key];

            if (value === undefined) {
                return acc;
            }

            if (Array.isArray(value)) {
                return {
                    ...acc,
                    [key]: value.map((v) => {
                        if (v instanceof ReactiveComponent) {
                            return v.serialize();
                        }

                        return v;
                    }),
                };
            }

            if (value instanceof ReactiveComponent) {
                return {
                    ...acc,
                    [key]: value.serialize(),
                };
            }

            return {
                ...acc,
                [key]: value,
            };
        }, {});

        return JSON.stringify(serializedStore);
    };

    static deserialize = (data: string): StateTree => {
        const deserializedStore = JSON.parse(data);

        return Object.keys(deserializedStore).reduce((acc, key) => {
            const value = deserializedStore[key];

            if (value instanceof Object && "structureType" in value) {
                return {
                    ...acc,
                    [key]: this.createComponent(value),
                };
            }

            if (value instanceof Array && "structureType" in (value[0] ?? {})) {
                return {
                    ...acc,
                    [key]: value.map((v) => this.createComponent(v)),
                };
            }

            return {
                ...acc,
                [key]: value,
            };
        }, {});
    };

    private static createComponent = (value: any) => {
        const ComponentClass = this.getComponentClassFromType(value.type);

        const component = new ComponentClass(value);
        const parent = new ComponentClass(value.parent);

        value.components?.forEach((child: any) => {
            const c = this.createComponent(child);
            component.addComponent(c);
        });

        component.setParent(parent);

        return component;
    };

    private static getComponentClassFromType(type: string) {
        const classes = {
            space: Space,
            partition: Partition,
            door: Door,
            "door-cut": DoorCut,
            extra: Extra,
            glassPartition: GlassPartition,
            glassThinknessPartition: GlassThinknessPartition,
            hardware: Hardware,
            mounts: Mounts,
            "mount-hardware": MountHardware,
            outOfSquare: OutOfSquare,
            "overdoor-asset": OverdoorAsset,
            "overdoor-material": OverdoorMaterial,
            overdoor: Overdoor,
            overheads: Overheads,
            "overhead-item": OverheadItem,
            delivery: Delivery,
            "delivery-item": DeliveryItem,
        };

        return classes[type] || ReactiveComponent;
    }
}
