import { DeliveryItem } from "../domain/entities/delivery";
import { DeliverItemsCreationStrategy } from "../domain/strategies/creation/delivery-items.strategy";

export class DeliveryService {
    static createDeliveryItems(): DeliveryItem[] {
        const strategy = new DeliverItemsCreationStrategy();

        return strategy.create() as unknown as DeliveryItem[];
    }

    static calculateHalfInstallation = (
        installation: number,
        discountPercent: number,
    ): { withDiscount: number; withoutDiscount: number } => {
        const halfInstallationSum = installation / 2;
        const halfInstallationWithDiscount =
            (halfInstallationSum / discountPercent) * 100;

        return {
            withDiscount: halfInstallationWithDiscount,
            withoutDiscount: halfInstallationSum,
        };
    };
}
