import { CGF300NoDoorsConfigs } from "./cgf300/mounts-no-door.config";
import { CGF300SlidingDoorsConfigs } from "./cgf300/mounts-sliding-door.config";
import { CGF300SwingDoorsConfigs } from "./cgf300/mounts-swing-door.config";

import { CGF350NoDoorsConfigs } from "./cgf350/mounts-no-door.config";
import { CGF350SlidingDoorsConfigs } from "./cgf350/mounts-sliding-door.config";
import { CGF350SwingDoorsConfigs } from "./cgf350/mounts-swing-door.config";

import { CGF400NoDoorsConfigs } from "./cgf400/mounts-no-door.config";
import { CGF400SlidingDoorsConfigs } from "./cgf400/mounts-sliding-door.config";
import { CGF400SwingDoorsConfigs } from "./cgf400/mounts-swing-door.config";

import { CGS200NoDoorsConfigs } from "./cgs200/mounts-no-door.config";
import { CGS200SlidingDoorsConfigs } from "./cgs200/mounts-sliding-door.config";
import { CGS200SwingDoorsConfigs } from "./cgs200/mounts-swing-door.config";

import { KompasNoDoorsConfigs } from "./kompas/mounts-no-door.config";
import { KompasSlidingDoorsConfigs } from "./kompas/mounts-sliding-door.config";
import { KompasSwingDoorsConfigs } from "./kompas/mounts-swing-door.config";

import { RomanoAnyDoorsConfigs } from "./romano/mounts-any-door.config";
import { TreviAnyDoorsConfigs } from "./trevi/mounts-any-door.config";

export const CGF300TopbottomConfigs = {
    noDoors: CGF300NoDoorsConfigs,
    slidingDoors: CGF300SlidingDoorsConfigs,
    swingDoors: CGF300SwingDoorsConfigs,
};

export const CGF350TopbottomConfigs = {
    noDoors: CGF350NoDoorsConfigs,
    slidingDoors: CGF350SlidingDoorsConfigs,
    swingDoors: CGF350SwingDoorsConfigs,
};

export const CGF400TopbottomConfigs = {
    noDoors: CGF400NoDoorsConfigs,
    slidingDoors: CGF400SlidingDoorsConfigs,
    swingDoors: CGF400SwingDoorsConfigs,
};

export const CGS200TopbottomConfigs = {
    noDoors: CGS200NoDoorsConfigs,
    slidingDoors: CGS200SlidingDoorsConfigs,
    swingDoors: CGS200SwingDoorsConfigs,
};

export const KompasTopbottomConfigs = {
    noDoors: KompasNoDoorsConfigs,
    slidingDoors: KompasSlidingDoorsConfigs,
    swingDoors: KompasSwingDoorsConfigs,
};

export const RomanoTopbottomConfigs = {
    anyDoors: RomanoAnyDoorsConfigs,
};

export const TreviTopbottomConfigs = {
    anyDoors: TreviAnyDoorsConfigs,
};
