<template>
  <select-dict
    v-model="hardwareValueTypeCode"
    :select-first-item="true"
    :select-list="hardwareValues"
  />
</template>

<!-- @select-changed="
      (key, value) =>
        validateHardwaresList(hardware.hardwareTypeCode, key, value)
    " -->

<script setup lang="ts">
import { computed, defineModel } from "vue";
import SelectDict from "@/components/UI/SelectDict.vue";
import { useDictStore } from "@/stores/dictStore";

type HardwareValueSelectProps = {
  hardwareTypeCode: string;
};

const props = defineProps<HardwareValueSelectProps>();
const hardwareValueTypeCode = defineModel<string>();

const dictStore = useDictStore();
const hardwareValues = computed(() =>
  dictStore.getHardwareValues(props.hardwareTypeCode),
);
</script>

<style lang="scss" scoped></style>
