import { ZodError, ZodSchema } from "zod";
import { Door, Overdoor, Partition } from "../domain";
import { PartitionSchema } from "../domain/validation/partition.schema";
import { ComponentDimensions } from "../@types/dimensions";
import {
    DoorMoreThenPartition,
    DoorSchema,
} from "../domain/validation/doors.schema";
import { EstimateSchema } from "../domain/validation/estimate.schema";
import {
    DoorWithOverdoorMoreThenPartition,
    OvedoorSchema,
    OverdoorMoreThenDoor,
} from "../domain/validation/overdoor.schema";

export class ValidationService {
    static validate(schema: ZodSchema, data: unknown): string | null {
        try {
            schema.parse(data);
            return null;
        } catch (error) {
            if (error instanceof ZodError) {
                return error.errors.map((err) => err.message).join("<br/>");
            }
        }
    }

    static validateEstimate = (data: unknown): string | null => {
        const error = ValidationService.validate(EstimateSchema, data);

        if (error) {
            return "Validation error\nSome of the required fields are not filled correctly";
        }

        return null;
    };

    static validatePartition = (
        partition: Partition | undefined | null,
    ): string | null => {
        if (!partition) {
            return "Partition is required.";
        }

        const error = this.validatePartitionDimensions(partition.dimensions);

        return error;
    };

    static validateOverdoor = (
        overdoor: Overdoor | undefined | null,
        door: Door | undefined | null,
        partition: Partition | undefined | null,
    ): string | null => {
        if (!overdoor) {
            return null;
        }

        if (overdoor.isNoneOverdoor()) {
            return null;
        }

        const error = this.validateOverdoorDimensions(
            overdoor.dimensions,
            door?.dimensions,
            partition?.dimensions,
        );

        return error;
    };

    static validateDoor = (
        door: Door | undefined | null,
        partition: Partition | undefined | null,
    ): string | null => {
        if (!door) {
            return null;
        }

        if (door.isDoorNone()) {
            return null;
        }

        const error = this.validateDoorDimensions(
            door.dimensions,
            partition?.dimensions,
        );

        return error;
    };

    static validateOverdoorDimensions = (
        overdoorDimensions: ComponentDimensions,
        doorDimensions: ComponentDimensions,
        partitionDimensions: ComponentDimensions,
    ): string | null => {
        const schemaError = this.validate(OvedoorSchema, {
            dimensions: {
                width: overdoorDimensions.width.getLength().toFt().getValue(),
                height: overdoorDimensions.height.getLength().toFt().getValue(),
            },
        });

        console.log("schemaError", schemaError);

        // const overdoorWidthDiff = overdoorDimensions.width.compare(
        //     doorDimensions.width,
        // );

        // const overdoorAndDoorWidth = doorDimensions.width.add(
        //     overdoorDimensions.width,
        // );

        // const overdoorAndDoorWidthDiff =
        //     partitionDimensions.width.compare(overdoorAndDoorWidth);

        // try {
        //     OverdoorMoreThenDoor.parse(overdoorWidthDiff);
        //     DoorWithOverdoorMoreThenPartition.parse(overdoorAndDoorWidthDiff);
        // } catch (error) {
        //     console.log(error);
        //     if (error instanceof ZodError) {
        //         return error.errors.map((err) => err.message).join("\n");
        //     }

        //     return "";
        // }

        return schemaError;
    };

    static validateDoorDimensions = (
        doorDimensions: ComponentDimensions,
        partitionDimensions: ComponentDimensions,
    ): string | null => {
        const schemaError = this.validate(DoorSchema, {
            dimensions: {
                width: doorDimensions.width.getLength().toFt().getValue(),
                height: doorDimensions.height.getLength().toFt().getValue(),
            },
        });

        const doorWithDiff = doorDimensions.width.compare(
            partitionDimensions.width,
        );

        try {
            DoorMoreThenPartition.parse(doorWithDiff);
        } catch (error) {
            if (error instanceof ZodError) {
                return error.errors.map((err) => err.message).join("\n");
            }

            return "";
        }

        return schemaError;
    };

    static validatePartitionDimensions = (
        dimensions: ComponentDimensions,
    ): string | null => {
        const error = this.validate(PartitionSchema, {
            dimensions: {
                width: dimensions.width.getLength().toFt().getValue(),
                height: dimensions.height.getLength().toFt().getValue(),
            },
        });

        return error;
    };
}
