<template>
  <select-dict
    v-model="mountStructureType"
    :select-list="mountsTypes"
    :select-first-item="true"
  />
</template>

<script setup lang="ts">
import { defineModel } from "vue";
import { useDictStore } from "@/stores/dictStore";

import SelectDict from "@/components/UI/SelectDict.vue";

const dictStore = useDictStore();

const mountStructureType = defineModel<string>();
const mountsTypes = dictStore.getStructureTypes("mounts");
</script>

<style lang="scss" scoped></style>
