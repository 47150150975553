<template>
  <button @click="onButtonClick">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15 5L5 15"
        stroke="#404D63"
        stroke-opacity="0.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 5L15 15"
        stroke="#404D63"
        stroke-opacity="0.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>

<script setup lang="ts">
import { defineProps } from "vue";

import { ExtraItem } from "@/app/domain";
import { useExtraStore } from "@/stores/new/extra.store";

type RemoveExtraButtonProps = {
  extraItem: ExtraItem;
};

const extraStore = useExtraStore();
const props = defineProps<RemoveExtraButtonProps>();

const onButtonClick = () => {
  extraStore.removeExtraItem(props.extraItem);
};
</script>

<style lang="scss" scoped></style>
