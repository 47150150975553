import { z } from "zod";
import { ValidationUtils } from "./utils";

export const DoorSchema = z.object({
    dimensions: ValidationUtils.nonEmptyDimensions().and(
        ValidationUtils.maxDoorHeight(),
    ),
});

export const DoorMoreThenPartition = z
    .number()
    .lte(0, "Door width greater then partition");
