import { Space } from "@/app/domain/entities/space";
import {
    createMountsCGFFrameConfig,
    createMountsCGFBottomFrameSortConfig,
    createMountsCGFSideFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsMullionsBottomConfig,
    createMountsMullionsTopConfig,
    createMountsToptrackSlidingDoorConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";

const createSlidingDoorMountsToptrackSlidingDoorConfig = (
    space: Space,
): IComponentConfig => {
    return createMountsToptrackSlidingDoorConfig().qty(1).build();
};

const createSlidingDoorMountsCGFSideFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTotalHeight(space) * 2;

    return createMountsCGFSideFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const qty = totalWidth - doorWidth;

    return createMountsCGFBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsTopConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getMullionsQty(space);

    return createMountsMullionsTopConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsBottomConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getMullionsQty(space);

    return createMountsMullionsBottomConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFFrameConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const cgfSidesQty = 4;
    const doorMultiplier = MountsService.isForDoubleDoor(space) ? 4 : 1;

    const qty = totalHeight * cgfSidesQty * doorMultiplier;

    return createMountsCGFFrameConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);
    const sides = 2;

    const verticalGaskets = totalHeight * sides;
    const horizontalGaskets = (totalWidth - doorWidth) * sides;

    const qty = (verticalGaskets + horizontalGaskets) * sides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

export const CGF300SlidingDoorsConfigs = (space: Space) => [
    createSlidingDoorMountsCGFSideFrameSortConfig(space),
    createSlidingDoorMountsToptrackSlidingDoorConfig(space),
    createSlidingDoorMountsCGFBottomFrameSortConfig(space),
    createSlidingDoorMountsMullionsTopConfig(space),
    createSlidingDoorMountsMullionsBottomConfig(space),
    createSlidingDoorMountsCGFFrameConfig(space),
    createSlidingDoorMountsGlazingGasketConfig(space),
];
