import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";
import { ReactiveComponent } from "../../entities/base-component";
import { CalculationStrategy, PriceResult } from "../base/calculation.strategy";
import { Partition } from "../../entities/partition";
import { isRef } from "vue";

export class GlassCalculationStrategy extends CalculationStrategy {
    calculate(component: ReactiveComponent): PriceResult {
        const partition = component.getParent();

        if (!partition) {
            return this.getDefaultResult();
        }

        return {
            price: this.calculatePriceForType(component, CostDealType),
            priceB2C: this.calculatePriceForType(component, B2BDealType),
            priceB2B: this.calculatePriceForType(component, B2CDealType),
        };
    }

    private calculatePriceForType(
        component: ReactiveComponent,
        dealType: string,
    ) {
        const partition = component.getParent()! as Partition;
        const totalAreaSq = isRef(partition.totalAreaSq)
            ? partition.totalAreaSq.value
            : partition.totalAreaSq;

        const partitionAreaFt = totalAreaSq?.toSquareFt().getValue();

        const price = component.price.getSystemPrice(dealType).getValue();

        return price * partitionAreaFt;
    }
}
