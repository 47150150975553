<template>
  <div class="step__block-dimensions">
    <DimensionsTable v-model="activeOverdoor!.dimensions" />
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { DimensionsTable } from "@/shared";
import { useOverdoorStore } from "@/stores/new/overdoor.store";
import { useDoorsStore } from "@/stores/new/doors.store";
import { usePartitionStore } from "@/stores/new/partition.store";
import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { isSteelSystem } from "@/app/predicates/systems.predicates";

const overdoorStore = useOverdoorStore();
const doorsStore = useDoorsStore();
const partitionsStore = usePartitionStore();
const estimateStore = useNewEstimateStore();

const activePartition = computed(() => partitionsStore.activePartitions);
const activeDoor = computed(() => doorsStore.activeDoor);
const activeOverdoor = computed(() => overdoorStore.activeOverdoor);
const overdoorType = computed(() => activeOverdoor.value?.structureType);
const installedSystem = computed(
  () => estimateStore.estimate.installedSystemCode,
);

const hasActiveOverdoor = computed(() => overdoorStore.hasActiveOverdoor);

watch(
  () => overdoorType,
  () => {
    if (activeOverdoor.value?.isNoneOverdoor()) {
      return activeOverdoor.value?.resetDimensions();
    }

    if (activeOverdoor.value?.isFiller()) {
      return activeOverdoor.value?.resetDimensions();
    }

    if (activeOverdoor.value?.isTransom()) {
      if (activeDoor.value?.isDoorNone()) {
        return activeOverdoor.value?.setDimensionsFromPartition(
          activePartition.value!,
        );
      } else {
        return activeOverdoor.value?.setTransomDimensions(
          activePartition.value!,
          activeDoor.value!,
        );
      }
    }
  },
  { immediate: true },
);

watch(
  () => activeOverdoor.value?.dimensions,
  () => {
    if (!activePartition.value || !activeOverdoor.value) {
      return;
    }

    if (activeOverdoor.value.isTransom()) {
      activePartition.value?.substractOverdoorIfExistAndUpdateDimensions(
        activeOverdoor.value,
      );
    }

    if (
      activeOverdoor.value.isFiller() &&
      isSteelSystem(installedSystem.value)
    ) {
      activePartition.value?.substractOverdoorIfExistAndUpdateDimensions(
        activeOverdoor.value,
      );
    }
  },
  { immediate: true, deep: true },
);
</script>

<style lang="scss" scoped></style>
