import { z } from "zod";
import { ValidationUtils } from "./utils";

export const OvedoorSchema = z.object({
    dimensions: ValidationUtils.nonEmptyDimensions().and(
        ValidationUtils.maxOverdoorHeight(),
    ),
});

export const OverdoorMoreThenDoor = z
    .number()
    .lte(0, "Transom width bigger then active door");

export const DoorWithOverdoorMoreThenPartition = z
    .number()
    .lte(0, "Filler width greater then partition");
