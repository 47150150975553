<template>
  <select-dict v-model="installedSystemCode" :select-list="systemTypes" />
</template>

<script setup lang="ts">
import { defineModel } from "vue";

import SelectDict from "@/components/UI/SelectDict.vue";
import { useDictStore } from "@/stores/dictStore";

const dictStore = useDictStore();

const installedSystemCode = defineModel<string>({ default: "" });
const systemTypes = dictStore.getSystemTypes();
</script>

<style lang="scss" scoped></style>
