import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";

import { ReactiveComponent } from "../../entities/base-component";
import { CalculationStrategy, PriceResult } from "../base/calculation.strategy";
import { Pricing } from "../../entities/pricing";

export class DiscountSumCalculationStrategy extends CalculationStrategy {
    calculate = (
        component: ReactiveComponent,
        [discoutPercent, subtotal],
    ): PriceResult => {
        return {
            price: this.calculatePriceForType(
                discoutPercent,
                subtotal,
                CostDealType,
            ),
            priceB2C: this.calculatePriceForType(
                discoutPercent,
                subtotal,
                B2CDealType,
            ),
            priceB2B: this.calculatePriceForType(
                discoutPercent,
                subtotal,
                B2BDealType,
            ),
        };
    };

    private calculatePriceForType(
        discountPercent: number,
        subtotal: Pricing,
        dealType: string,
    ): number {
        const price = subtotal?.getSystemPrice(dealType).getValue();

        return Math.round((price * discountPercent) / 100);
    }
}

export class DiscountPercentCalculationStrategy extends CalculationStrategy {
    calculate = (
        component: ReactiveComponent,
        [discoutPercent, subtotal],
    ): PriceResult => {
        return {
            price: this.calculatePriceForType(
                discoutPercent,
                subtotal,
                CostDealType,
            ),
            priceB2C: this.calculatePriceForType(
                discoutPercent,
                subtotal,
                B2CDealType,
            ),
            priceB2B: this.calculatePriceForType(
                discoutPercent,
                subtotal,
                B2BDealType,
            ),
        };
    };

    private calculatePriceForType(
        discountSum: number,
        subtotal: Pricing,
        dealType: string,
    ): number {
        const price = subtotal?.getSystemPrice(dealType).getValue();

        return Math.round((discountSum * 100) / price);
    }
}
