<template>
  <select-dict v-model="shippingState" :select-list="states" />
</template>

<script setup lang="ts">
import { defineModel } from "vue";

import SelectDict from "@/components/UI/SelectDict.vue";

import { useDictStore } from "@/stores/dictStore";

const dictStore = useDictStore();

const shippingState = defineModel<string>({ default: "" });
const states = dictStore.getUsStates();
</script>

<style lang="scss" scoped></style>
