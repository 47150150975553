<template>
  <select-input v-model="clientName" :select-list="clients" />
</template>

<script setup lang="ts">
import { defineModel } from "vue";

import SelectInput from "@/components/UI/SelectInput.vue";
import { useDictStore } from "@/stores/dictStore";

const dictStore = useDictStore();

const clientName = defineModel<string>({ default: "" });
const clients = dictStore.getClients();

console.log("clients", clients);
</script>

<style lang="scss" scoped></style>
