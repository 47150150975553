import { IComponentConfig } from "@/app/@types/base-component";
import {
    createMountsBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsStripesConfig,
    createMountsToptrackConfig,
} from "../../base";
import { Space } from "@/app/domain/entities/space";
import { MountsService } from "@/app/services/mounts.service";

const createSlidingDoorMountsToptrackConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTotalWidth(space);

    return createMountsToptrackConfig().qty(qty).build();
};

const createSlidingDoorMountsBottomFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const qty = totalWidth - doorWidth;

    return createMountsBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);
    const hasTransom = MountsService.hasOverdoorTransom(space);
    const sides = 2;

    const doorDeduction = doorWidth - (hasTransom ? 0 : doorWidth);

    const qty = (totalWidth * sides - doorDeduction) * sides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

const createSlidingDoorMountsStripesConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getStipJointsQty(space);

    return createMountsStripesConfig().qty(qty).build();
};

export const CGS200SlidingDoorsConfigs = (space: Space) => [
    createSlidingDoorMountsToptrackConfig(space),
    createSlidingDoorMountsBottomFrameSortConfig(space),
    createSlidingDoorMountsGlazingGasketConfig(space),
    createSlidingDoorMountsStripesConfig(space),
];
