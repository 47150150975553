import { defineStore } from "pinia";
import { ref, computed } from "vue";

import { Delivery, DeliveryItem } from "@/app/domain/entities/delivery";
import { DeliveryService } from "@/app/services/delivery.service";

export const useDeliveryStore = defineStore(
    "delivery-store",
    () => {
        const activeDelivery = ref<Delivery | null>(null);
        const activeDeliveryItems = ref<DeliveryItem[]>([]);

        const $reset = () => {
            activeDelivery.value = null;
            activeDeliveryItems.value = [];
        };

        const hasActiveDelivery = computed(
            () =>
                activeDelivery.value !== null &&
                activeDeliveryItems.value.length > 0,
        );

        const addDelivery = () => {
            const delivery = new Delivery({
                qty: 1,
            });

            const deliverItems = DeliveryService.createDeliveryItems();
            delivery.replaceDeliveryItems(deliverItems);

            activeDelivery.value = delivery;
            activeDeliveryItems.value = deliverItems;
        };

        return {
            activeDelivery,
            activeDeliveryItems,
            hasActiveDelivery,
            addDelivery,
            $reset,
        };
    },
    { persist: true },
);
