<template>
  <select-dict
    v-model="dealTypeCode"
    :select-list="dealTypes"
    :select-first-item="true"
  >
  </select-dict>
</template>

<script setup lang="ts">
import { defineModel, computed } from "vue";

import SelectDict from "@/components/UI/SelectDict.vue";

import { useDictStore } from "@/stores/dictStore";
import { useUserStore } from "@/stores/userStore";

const dictStore = useDictStore();
const user = useUserStore();

const dealTypeCode = defineModel<string>({ default: "" });

const dealTypes = computed(() => {
  const dealTypes = dictStore.getDealTypes();

  if (user.isSalesManager) {
    return dealTypes.filter(function (value) {
      if (value.code != "cost") {
        return value;
      }
    });
  }

  return dealTypes;
});
</script>

<style lang="scss" scoped></style>
