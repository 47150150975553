<template>
  <div class="step__block-field step__block-field--top">
    <div class="step__block-field-block">
      <h3>Overhead cost for:</h3>
      <span class="step__block-field-price">
        {{ spaceTotalAreaFt?.toFt().format() }} sq. ft
      </span>
    </div>
  </div>
  <OverheadItemsTable v-if="hasActiveOverhead" />
  <OverheadTotalPrice v-if="hasActiveOverhead" />
</template>

<script setup lang="ts">
import { useOverheadStore } from "@/stores/new/overhead.store";
import { computed, onMounted } from "vue";

import { useSpacesStore } from "@/stores/new/spaces.store";
import { OverheadItemsTable, OverheadTotalPrice } from "@/modules/overhead";

const overheadStore = useOverheadStore();
const spaceStore = useSpacesStore();

const activeSpace = computed(() => spaceStore.activeSpace);
const spaceTotalAreaFt = computed(() => activeSpace.value?.totalAreaSqFt);

const hasActiveOverhead = computed(() => overheadStore.hasActiveOverhead);

defineExpose({
  validatePage,
});

function validatePage() {
  return null;
}

onMounted(() => {
  if (hasActiveOverhead.value) {
    return;
  }

  // activeSpace.value?.calculateTotalAreaSqFt();

  overheadStore.addOverhead();
  overheadStore.addOverheadItems();
});
</script>

<style scoped>
.step__block-table {
  padding: 15px 0;
  display: grid;
  row-gap: 15px;
}

.step__block-item {
  display: grid;
  grid-template-columns: 1fr 8em repeat(2, minmax(0, 55px));
  column-gap: 30px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-item--head {
  font-weight: 600;
}

.step__block-item-unit span {
  padding-right: 10px;
}

.step__block-item div:first-child {
  align-items: flex-start;
}

.step__block-item div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.step__block-field {
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  border-bottom: 1px solid #d2d8e3;
}

.step__block-field.step__block-field--top {
  padding-top: 0;
  border-top: none;
}

.step__block-field h3 {
  color: #404d63;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step__block-field--hardware .step__block-field-block {
  justify-content: flex-start;
  column-gap: 10px;
}
</style>
