import { Space } from "@/app/domain/entities/space";
import {
    createMountsCGFFrameConfig,
    createMountsCGFBottomFrameSortConfig,
    createMountsCGFSideFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsMullionsHorizontalConfig,
    createMountsCGFTopFrameSortConfig,
} from "../../base";
import { MountsService } from "@/app/services/mounts.service";
import { IComponentConfig } from "@/app/@types/base-component";
import { LineDividerCalculationService } from "@/app/services/line-dividers.service";
import { CGF350Code } from "@/app/constants";

const createSlidingDoorMountsCGFTopFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(space);

    return createMountsCGFTopFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFSideFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const sides = 2;

    const qty = totalHeight * sides;

    return createMountsCGFSideFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const qty = totalWidth - doorWidth;

    return createMountsCGFBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsHorizontalConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);
    const sides = 2;

    const horizontalLinesQty =
        LineDividerCalculationService.calculateHorizontalDividers(
            CGF350Code,
            totalHeight,
        );

    const qty = totalWidth * horizontalLinesQty * sides;

    return createMountsMullionsHorizontalConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFFrameConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);
    const isDoubleDoor = MountsService.isForDoubleDoor(space);

    const doorSides = isDoubleDoor ? 6 : 4;
    const totalSides = 2;

    const horizontalProfiles = totalHeight * doorSides;
    const verticalProfiles = doorWidth * totalSides;

    const qty = horizontalProfiles + verticalProfiles;

    return createMountsCGFFrameConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    /**
     * Если над дверью есть transom (фрамуга), то для такой двери нужно учитывать только одну сторону
     * Так как возможно для фрамуги уже есть `glazing gasket` и ее не нужно ставить заново
     * */
    const doorSides = MountsService.hasOverdoorTransom(space) ? 1 : 2;
    const totalSides = 2;

    const doorDeduction = doorWidth * doorSides;
    const horizontalGaskets = totalWidth * totalSides - doorDeduction;
    const verticalGaskets = totalHeight * totalSides;

    const qty = (horizontalGaskets + verticalGaskets) * totalSides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

export const CGF350SwingDoorsConfigs = (space: Space) => [
    createSlidingDoorMountsCGFTopFrameSortConfig(space),
    createSlidingDoorMountsCGFSideFrameSortConfig(space),
    createSlidingDoorMountsCGFBottomFrameSortConfig(space),
    createSlidingDoorMountsMullionsHorizontalConfig(space),
    createSlidingDoorMountsCGFFrameConfig(space),
    createSlidingDoorMountsGlazingGasketConfig(space),
];
