import { Space } from "@/app/domain";
import {
    createMountsCGFFrameConfig,
    createMountsCGFBottomFrameSortConfig,
    createMountsCGFSideFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsMullionsHorizontalConfig,
    createMountsCGFTopFrameSortConfig,
    createMountsMullionsVerticalConfig,
} from "../../base";
import { MountsService } from "@/app/services/mounts.service";
import { IComponentConfig } from "@/app/@types/base-component";
import { LineDividerCalculationService } from "@/app/services/line-dividers.service";
import { CGF400Code } from "@/app/constants";

const createSlidingDoorMountsCGFTopFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(space);

    return createMountsCGFTopFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFSideFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const sides = 2;

    const qty = totalHeight * sides;

    return createMountsCGFSideFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const qty = totalWidth - doorWidth;

    return createMountsCGFBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsHorizontalConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);
    const sides = 2;

    const horizontalLineQty =
        LineDividerCalculationService.calculateHorizontalDividers(
            CGF400Code,
            totalHeight,
        );

    const qty = totalWidth * horizontalLineQty * sides;

    return createMountsMullionsHorizontalConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsVerticalConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);
    const isDoubleDoor = MountsService.isForDoubleDoor(space);

    /**
     * Total Sections = 3, потому что вся конструкция двери делится на 3 равные части (правда только для single дверей)
     * На этой картинке (https://newcrystalia-api.wandry.com.ua/storage/blocks/wp-contentuploads2020052502-2jpg_2502-2jpg.jpg)
     * видно, что конструкция двери состоит из трех частей, две по бокам статические, одна по середине двигается
     *
     * Но для двух дверей по-идее должно быть другое кол-во секций, потому что на этой картинке
     * https://newcrystalia-api.wandry.com.ua/storage/blocks/wp-contentuploads202012210220-opened-minjpg_210220-opened-minjpg.jpg
     *
     * видно, что есть две части, которые не двигаются, и две части которые двигаются
     * */

    const totalSectios = 3;
    const totalSides = 2;
    const doorSections = isDoubleDoor ? 3 : 2;
    const horizontalLineQty = totalWidth / totalSectios - doorSections;

    const qty = horizontalLineQty * totalSides * totalHeight;

    return createMountsMullionsVerticalConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFFrameConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const isDoubleDoor = MountsService.isForDoubleDoor(space);
    const doorSides = isDoubleDoor ? 6 : 4;

    const qty = totalHeight * doorSides;

    return createMountsCGFFrameConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);
    const hasTransom = MountsService.hasOverdoorTransom(space);

    const totalSides = 2;
    const doorSides = hasTransom ? 1 : 2;

    const verticalGasktes = totalHeight * totalSides;
    const horizontalGaskets = totalWidth * totalSides - doorWidth * doorSides;

    const qty = (verticalGasktes + horizontalGaskets) * totalSides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

export const CGF400SwingDoorsConfigs = (space: Space) => [
    createSlidingDoorMountsCGFTopFrameSortConfig(space),
    createSlidingDoorMountsCGFSideFrameSortConfig(space),
    createSlidingDoorMountsCGFBottomFrameSortConfig(space),
    createSlidingDoorMountsMullionsHorizontalConfig(space),
    createSlidingDoorMountsMullionsVerticalConfig(space),
    createSlidingDoorMountsCGFFrameConfig(space),
    createSlidingDoorMountsGlazingGasketConfig(space),
];
