<template>
  <div class="table">
    <div class="table__wrapper">
      <div class="table__block">
        <div class="table__block-scroll">
          <div class="table__block-head">
            <div class="table__block-item">
              <span>Status</span>
            </div>
            <div class="table__block-item">
              <select-table @findEstimate="findTrackNumber"
                >Track №</select-table
              >
            </div>
            <div class="table__block-item">
              <select-table @findEstimate="findEstimateNumber"
                >Estimate №</select-table
              >
            </div>
            <div class="table__block-item">
              <span>Price date</span>
            </div>
            <div class="table__block-item">
              <span>Start</span>
            </div>
            <div class="table__block-item">
              <span>End</span>
            </div>
            <div class="table__block-item">
              <span>System</span>
            </div>
            <div class="table__block-item">
              <span>Quantity (sq. ft.)</span>
            </div>
            <div class="table__block-item">
              <span>Price per sq. ft.</span>
            </div>
            <div class="table__block-item">
              <span>Price</span>
            </div>
            <div class="table__block-item">
              <span>Dscnt</span>
            </div>
            <div class="table__block-item">
              <select-table @findEstimate="findProjectName"
                >Project Name</select-table
              >
            </div>
            <div class="table__block-item">
              <select-table @findEstimate="findClient">Client</select-table>
            </div>
            <div class="table__block-item">
              <span>Business</span>
            </div>
            <div class="table__block-item">
              <select-table @findEstimate="findEmail">Email</select-table>
            </div>
            <div class="table__block-item">
              <select-table @findEstimate="findPhone">Phone</select-table>
            </div>
            <div class="table__block-item">
              <span>State</span>
            </div>
            <div class="table__block-item">
              <span>Delivery</span>
            </div>
            <div class="table__block-item">
              <span>Install</span>
            </div>
            <div class="table__block-item">
              <span>Manager</span>
            </div>
            <div class="table__block-item">
              <span>Estimator</span>
            </div>
            <div class="table__block-item">
              <span>Comments</span>
            </div>
          </div>
          <div class="table__block-body">
            <div
              class="table__block-container"
              v-for="estimate in estimates.data"
              :data-code="estimate[0].header.code"
              :key="estimate[0].header.code"
              @click.capture="clickDataRow($event, estimate[0].header.code)"
              @dblclick.capture="
                doubleClickDataRow($event, estimate[0].header.code)
              "
            >
              <div class="table__block-estimate">
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.status]"
                  :key="estimate[0].header.status"
                >
                  <label class="table__checkbox">
                    <input
                      type="checkbox"
                      @change="initCheckbox"
                      v-model="selected"
                      :value="estimate.id"
                    />
                    <div class="table__checkbox-block">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="9"
                        viewBox="0 0 9 9"
                      >
                        <path d="M1 4.5L4 8L8 1" />
                      </svg>
                    </div>
                  </label>

                  <div class="table__block-nest">
                    <div
                      v-if="estimate.nested && estimate.nested.length > 0"
                      class="table__block-overlay"
                      @click="
                        estimate.isVisibleNestedEstimates =
                          !estimate.isVisibleNestedEstimates
                      "
                    ></div>
                    <span>{{ estimate[0].header.status }}</span>
                    <svg
                      :class="[
                        'table__block-svg',
                        {
                          'table__block-svg--active':
                            estimate.isVisibleNestedEstimates,
                        },
                      ]"
                      v-if="estimate.nested && estimate.nested.length > 0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="7"
                      viewBox="0 0 12 7"
                    >
                      <!-- <mask id="path-1-inside-1_134_912">
                                            <path d="M12 0.208008L6 6.20801L0 0.208007"/>
                                            </mask> -->
                      <path
                        d="M11.1515 -0.64052L12 -1.48905L13.6971 0.208008L12.8485 1.05654L11.1515 -0.64052ZM6 6.20801L6.84853 7.05654C6.3799 7.52516 5.6201 7.52516 5.15147 7.05654L6 6.20801ZM-0.848528 1.05653L-1.69706 0.208007L1.48361e-07 -1.48905L0.848528 -0.640521L-0.848528 1.05653ZM12.8485 1.05654L6.84853 7.05654L5.15147 5.35948L11.1515 -0.64052L12.8485 1.05654ZM5.15147 7.05654L-0.848528 1.05653L0.848528 -0.640521L6.84853 5.35948L5.15147 7.05654Z"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.trackCode]"
                  :key="estimate[0].header.trackCode"
                >
                  <span>{{ estimate[0].header.trackCode }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.estimateN]"
                  :key="estimate[0].header.code"
                >
                  <input
                    class="est-num-inp"
                    :id="'est-num-inp' + estimate[0].header.code"
                    v-model="estimate[0].header.estimateN"
                    type="text"
                    @click="focusInput(estimate[0].header.code)"
                  />
                  <button
                    type="button"
                    class="est-num-btn"
                    :id="'est-num-btn' + estimate[0].header.code"
                    @click="saveEstNumb($event, estimate[0].header.code)"
                  >
                    Save
                  </button>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.priceDate]"
                  :key="estimate[0].header.priceDate"
                >
                  <span>{{
                    formatDate(estimate[0].header.priceDate, "MM/DD/YYYY")
                  }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.startedDate]"
                  :key="estimate[0].header.startedDate"
                >
                  <span>{{
                    formatDateTime(estimate[0].header.startedDate, "MM/DD/YYYY")
                  }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.updatedDate]"
                  :key="estimate[0].header.updatedDate"
                >
                  <span>{{
                    formatDateTime(estimate[0].header.updatedDate, "MM/DD/YYYY")
                  }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.installedSystem]"
                  :key="estimate[0].header.installedSystem"
                >
                  <span>{{ estimate[0].header.installedSystem }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.quantity]"
                  :key="estimate[0].header.quantity"
                >
                  <span>{{
                    parseFloat(
                      EstimateService.calculateEstimatePartitionsArea(
                        estimate[0].estimate,
                      ),
                    ).toFixed(0)
                  }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.pricePer]"
                  :key="estimate[0].header.pricePer"
                >
                  <span>{{
                    formatMoneyWithRound(
                      estimate[0].header.price /
                        EstimateService.calculateEstimatePartitionsArea(
                          estimate[0].estimate,
                        ),
                    )
                  }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.price]"
                  :key="estimate[0].header.price"
                >
                  <span>{{
                    formatMoneyWithRound(estimate[0].header.price)
                  }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.discount]"
                  :key="estimate[0].header.discount"
                >
                  <span>{{ estimate[0].header.discount + "%" }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.title]"
                  :key="estimate[0].header.title"
                >
                  <span>{{ estimate[0].header.title }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.client]"
                  :key="estimate[0].header.client"
                >
                  <span>{{ estimate[0].header.client }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.business]"
                  :key="estimate[0].header.business"
                >
                  <span>{{ estimate[0].header.business }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.email]"
                  :key="estimate[0].header.email"
                >
                  <a :href="'mailto:' + estimate[0].header.email">{{
                    estimate[0].header.email
                  }}</a>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.phone]"
                  :key="estimate[0].header.phone"
                >
                  <a :href="'tel:' + estimate[0].header.phone">{{
                    estimate[0].header.phone
                  }}</a>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.state]"
                  :key="estimate[0].header.state"
                >
                  <span>{{ estimate[0].header.state }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.delivery]"
                  :key="estimate[0].header.delivery"
                >
                  <span>{{ estimate[0].header.delivery }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.installationPlaceTitle]"
                  :key="estimate[0].header.installationPlaceTitle"
                >
                  <span>{{ estimate[0].header.installationPlaceTitle }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.manager]"
                  :key="estimate[0].header.manager"
                >
                  <span>{{ estimate[0].estimate.info.managerCode.title }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.estimator]"
                  :key="estimate[0].header.estimator"
                >
                  <span>{{
                    estimate[0].estimate.info.estimatorCode?.title ??
                    estimate[0].estimate.info.estimatorCode
                  }}</span>
                </div>
                <div
                  class="table__block-item"
                  :class="['item-' + estimate[0].header.comments]"
                  :key="estimate[0].header.comments"
                >
                  <span>{{ estimate[0].header.comments }}</span>
                </div>
              </div>
              <div v-if="estimate.nested && estimate.isVisibleNestedEstimates">
                <div
                  class="table__block-estimate"
                  v-for="item in estimate.nested"
                  :key="item.trackN"
                >
                  <div
                    class="table__block-item"
                    :class="[idx ? 'item-' + idx : '']"
                    v-for="(item, idx) in item"
                    :key="idx"
                  >
                    <label v-if="idx === 'status'" class="table__checkbox">
                      <input
                        type="checkbox"
                        @change="initCheckbox"
                        value="@{{ item.id }}"
                      />
                      <div class="table__checkbox-block">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="9"
                          height="9"
                          viewBox="0 0 9 9"
                        >
                          <path d="M1 4.5L4 8L8 1" />
                        </svg>
                      </div>
                    </label>
                    <a
                      style="text-decoration: underline"
                      v-if="idx === 'business'"
                      target="_blank"
                      :href="'https://' + item"
                      >{{ item }}</a
                    >
                    <a v-else-if="idx === 'phone'" :href="'tel:' + item">{{
                      item
                    }}</a>
                    <a v-else-if="idx === 'email'" :href="'mailto:' + item">{{
                      item
                    }}</a>
                    <span v-else-if="idx === 'delivery'">{{ item }}</span>
                    <span v-else-if="idx === 'client'">{{ item.name }}</span>
                    <span v-else-if="idx === 'manager'">{{ item.name }}</span>
                    <span v-else-if="idx === 'estimator'">{{ item.name }}</span>
                    <span v-else-if="idx !== 'related_estimate'">{{
                      item
                    }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <Pagination
                :data="estimates"
                :limit="3"
                @pagination-change-page="(page) => fetchEstimates(page)"
                class="mt-4"
                style="--bs-pagination-focus-box-shadow: none"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="table__control">
        <div class="table__control-left">
          <div
            @blur="closeSelectControl"
            tabindex="0"
            :class="[
              'table__select',
              { 'table__select--active': isSelectControlActive },
            ]"
          >
            <div class="table__select-head">
              <div
                @click="toggleSelectControl"
                class="table__select-overlay"
              ></div>
              <span>Choose an action</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="6"
                viewBox="0 0 12 6"
                fill="none"
              >
                <mask id="path-1-inside-1_1_735" fill="white">
                  <path d="M12 6L6 5.24537e-07L0 6" />
                </mask>
                <path
                  d="M10.9393 7.06066L12 8.12132L14.1213 6L13.0607 4.93934L10.9393 7.06066ZM6 5.24537e-07L7.06066 -1.06066C6.47487 -1.64645 5.52513 -1.64645 4.93934 -1.06066L6 5.24537e-07ZM-1.06066 4.93934L-2.12132 6L1.85452e-07 8.12132L1.06066 7.06066L-1.06066 4.93934ZM13.0607 4.93934L7.06066 -1.06066L4.93934 1.06066L10.9393 7.06066L13.0607 4.93934ZM4.93934 -1.06066L-1.06066 4.93934L1.06066 7.06066L7.06066 1.06066L4.93934 -1.06066Z"
                  fill="white"
                  mask="url(#path-1-inside-1_1_735)"
                />
              </svg>
            </div>
            <ul
              @blur="toggleSelectControl"
              @click="isSelectControlActive = false"
              tabindex="0"
              class="table__select-list"
            >
              <li>Get the estimate</li>
              <li>Get a detailed description</li>
              <li>Send to the Quickbook</li>
            </ul>
          </div>
        </div>
        <div class="table__control-right">
          <button class="table__control-button">
            <span>Upload log</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
            >
              <path
                d="M14.25 10.2917V15.0417C14.25 15.4616 14.0832 15.8643 13.7863 16.1613C13.4893 16.4582 13.0866 16.625 12.6667 16.625H3.95833C3.53841 16.625 3.13568 16.4582 2.83875 16.1613C2.54181 15.8643 2.375 15.4616 2.375 15.0417V6.33333C2.375 5.91341 2.54181 5.51068 2.83875 5.21375C3.13568 4.91681 3.53841 4.75 3.95833 4.75H8.70833"
                stroke="white"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
              <path
                d="M11.875 2.375H16.625V7.125"
                stroke="white"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
              <path
                d="M7.91699 11.0833L16.6253 2.375"
                stroke="white"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <a
            :href="
              '/estimates/export?' +
              selected.map((id) => `ids[]=${id}`).join('&')
            "
          >
            <button class="table__control-button" :disabled="!selected.length">
              <span>Compare</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="16"
                viewBox="0 0 21 16"
                fill="none"
              >
                <path
                  d="M1 15H12.875"
                  stroke="white"
                  stroke-width="1.2"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
                <path
                  d="M1 8H20"
                  stroke="white"
                  stroke-width="1.2"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
                <path
                  d="M1 1L8.125 1"
                  stroke="white"
                  stroke-width="1.2"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </a>
          <button class="table__control-button" @click="cloneAction()">
            <span>Copy</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="21"
              viewBox="0 0 18 21"
              fill="none"
            >
              <path
                d="M15.4006 7.6499H8.20059C7.31693 7.6499 6.60059 8.50056 6.60059 9.5499V18.0999C6.60059 19.1492 7.31693 19.9999 8.20059 19.9999H15.4006C16.2842 19.9999 17.0006 19.1492 17.0006 18.0999V9.5499C17.0006 8.50056 16.2842 7.6499 15.4006 7.6499Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.4 13.35H2.6C2.17565 13.35 1.76869 13.1498 1.46863 12.7935C1.16857 12.4372 1 11.9539 1 11.45V2.9C1 2.39609 1.16857 1.91282 1.46863 1.5565C1.76869 1.20018 2.17565 1 2.6 1H9.8C10.2243 1 10.6313 1.20018 10.9314 1.5565C11.2314 1.91282 11.4 2.39609 11.4 2.9V3.85"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button class="table__control-button" @click="deleteAction()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M1 4.80005H3H19"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17 4.8V18.1C17 18.6039 16.7893 19.0872 16.4142 19.4435C16.0391 19.7998 15.5304 20 15 20H5C4.46957 20 3.96086 19.7998 3.58579 19.4435C3.21071 19.0872 3 18.6039 3 18.1V4.8M6 4.8V2.9C6 2.39609 6.21071 1.91282 6.58579 1.5565C6.96086 1.20018 7.46957 1 8 1H12C12.5304 1 13.0391 1.20018 13.4142 1.5565C13.7893 1.91282 14 2.39609 14 2.9V4.8"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 9.55005V15.25"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 9.55005V15.25"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>Delete</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SelectTable from "@/components/UI/SelectTable.vue";
import { Bootstrap5Pagination as Pagination } from "laravel-vue-pagination";
import { useEstimateStore } from "@/stores/estimateStore";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useUiStore } from "@/stores/uiStore";
//import {formatDate} from "@vueuse/shared";
import { formatDateTime, formatDate } from "@/utils/common";
import { usePriceStore } from "../stores/priceStore";
import { EstimateService } from "../services/estimateService";
import { formatMoneyWithRound } from "../utils/common";
import { cloneDeep } from "lodash";
import { postEstimate } from "../utils/api";
import { useNewEstimateStore } from "@/stores/new/estimateStore";

usePriceStore().initCurrentPriceRule();
const uiStore = useUiStore();
const estimates = ref([]);
const page = ref(1);
const isSelectControlActive = ref(false);
const selected = ref([]);
const searchData = ref([]);
const selectedEstimateCode = ref(null);
const router = useRouter();

fetchEstimates(page);

function initCheckbox(e) {
  e.target.checked
    ? e.target.parentNode.classList.add("table__checkbox--active")
    : e.target.parentNode.classList.remove("table__checkbox--active");
}

async function findTrackNumber(value) {
  searchData.value["search_track_number"] = value.toLowerCase();
  await fetchEstimates(1).then();
}

async function findEstimateNumber(value) {
  searchData.value["search_estimate_number"] = value.toLowerCase();
  await fetchEstimates(1).then();
}

function findProjectName(value) {
  searchData.value["search_project_name"] = value.toLowerCase();
  fetchEstimates(1);
}

function findClient(value) {
  searchData.value["search_client"] = value.toLowerCase();
  fetchEstimates(1);
}

function findEmail(value) {
  searchData.value["search_email"] = value.toLowerCase();
  fetchEstimates(1);
}

function findPhone(value) {
  searchData.value["search_phone"] = value.toLowerCase();
  fetchEstimates(1);
}

function closeSelectControl() {
  isSelectControlActive.value = false;
}

function toggleSelectControl() {
  isSelectControlActive.value = !isSelectControlActive.value;
}

function deleteAction() {
  // console.log(selected);
  const itemPromises = [];
  selected.value.forEach((item) => {
    // console.log(item);
    itemPromises.push(
      new Promise((resolve, reject) => {
        axios
          .delete("/api/estimates/" + item)
          .then(function (response) {
            resolve(item);
            // console.log(response.data);
          })
          .catch(function (error) {
            reject(error);
            console.log(error);
          });
      }),
    );
  });

  console.debug("deleted");
  selected.value = [];

  Promise.all(itemPromises).then(() => {
    fetchEstimates(page.value);
  });
}

async function cloneAction() {
  await useEstimateStore().cloneEstimate(selectedEstimateCode.value);
  fetchEstimates(page.value);
  return;

  // todo remove
  // console.log(selected.value);
  // const itemPromises = [];
  // selected.value.forEach((item) => {
  //     // console.log(item);
  //     itemPromises.push(new Promise((resolve, reject) => {
  //         axios.post('/api/estimates/clone', {
  //             id: item,
  //         }).then(function (response) {
  //             resolve(item)
  //             // console.log(response.data);
  //         }).catch(function (error) {
  //             reject(error)
  //             console.log(error);
  //         });
  //     }))
  // })

  // // console.log('cloned');
  // Promise.all(itemPromises)
  //     .then(() => {
  //         fetchEstimates(page.value);
  //     })
}

watch(
  () => uiStore.tableEstimatesSelectedRowCode,
  () => {
    if (selectedEstimateCode.value !== uiStore.tableEstimatesSelectedRowCode) {
      selectRowByCode(uiStore.tableEstimatesSelectedRowCode);
    }
  },
);

function selectRowByCode(estimateCode: string) {
  const rowEl = document.querySelector(
    `.table__block-estimate [data-code="${estimateCode}"]`,
  );
  if (!rowEl) {
    console.info("estimate not found in table", estimateCode);
    return false;
  }
  rowEl.classList.add("active");
}

async function fetchEstimates(page) {
  try {
    const response = await axios.get("/api/estimates", {
      params: {
        page: page,
        ...searchData.value,
      },
    });
    console.log(response.data);
    estimates.value = response.data;
  } catch (e) {
    console.log(e);
  } finally {
  }
}

function clickDataRow(event, estimateCode) {
  document
    .querySelectorAll(".table__block-estimate")
    .forEach((el) => el.classList.remove("active"));
  event.target.closest(".table__block-estimate").classList.add("active");
  selectedEstimateCode.value = estimateCode;
}

async function doubleClickDataRow(event, estimateCode) {
  // await useEstimateStore().load(estimateCode, true);
  await useNewEstimateStore().load(estimateCode, true);
  router.push({ name: "calculationPages", params: { id: 1 } });
}
const focusInput = (code) => {
  document.querySelector("#est-num-btn" + code).style.display = "block";
  console.log(code);
};
async function saveEstNumb($event, code) {
  const est = estimates.value.data.filter((row) => {
    if (row[0].header.code == code) {
      return row[0];
    }
  });
  est[0][0].estimate.info.estimateNo = est[0][0].header.estimateN;
  try {
    await postEstimate(est[0][0], est[0][0].estimate.info.code, false);
    document.querySelector("#est-num-btn" + code).style.display = "none";
  } catch (error) {
    console.error(error);
  }
}
</script>

<style scoped>
.table,
.table__wrapper {
  position: relative;
  height: 100vh;
}

.table__wrapper {
  padding: 20px 0;
}

.table {
  width: 100%;
  overflow: hidden;
}

.table__block-nest {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.table__block-svg {
  position: relative;
  transform: scale(0.8) rotate(0);
  fill: #fff;
  opacity: 0.8;
  transition: 0.5s ease;
}

.table__block-svg--active {
  transform: scale(0.8) rotate(-180deg);
  transition: 0.5s ease;
}

.table__block-svg path {
  fill: #404d63;
  transition: 0.5s ease;
}

.table__block-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  cursor: pointer;
}

.table__block-item {
  position: relative;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-right: 10px;
}

.table__block-estimate .table__block-item:first-child,
.table__block-head .table__block-item:first-child {
  padding-left: 50px !important;
}

.table__block-head .table__block-item {
  color: #404d63;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.table__block-body .table__block-item,
.table__block-body .table__block-item a {
  color: #404d63;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.5s ease;
}

.table__block-item::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #d2d8e3;
}

.table__block-head,
.table__block-estimate {
  position: relative;
  display: grid;
  /* grid-gap: 0 20px; */
  grid-auto-flow: column;
  grid-auto-columns: 150px;
  padding: 0 20px 0px 0;
}

.table__block-container {
  position: relative;
}

.table__checkbox {
  position: absolute;
  left: 13px;
  top: 56%;
  transform: translateY(-50%);
  z-index: 5;
}

.table__checkbox input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.table__checkbox-block {
  width: 14px;
  height: 14px;
  border: 1px solid #d2d8e2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.table__checkbox-block svg {
  fill: none;
  opacity: 0;
  transition: 0.5s ease;
}

.table__checkbox-block svg path {
  stroke: black;
  transition: 0.5s ease;
}

.table__checkbox--active .table__checkbox-block svg {
  opacity: 1;
  transition: 0.5s ease;
}

.table__block-item {
  background-color: #fff;
  transition: 0.5s ease;
}

.table__block-estimate:hover .table__block-item {
  background-color: #2491eb;
  transition: 0.5s ease;
}

.table__block-estimate:hover .table__checkbox-block svg path {
  stroke: #fff !important;
  transition: 0.5s ease;
}

.table__block-estimate:hover .table__block-svg path {
  fill: #fff;
  transition: 0.5s ease;
}

.table__block-estimate.active .table__block-svg path {
  fill: #fff;
}

.table__block-estimate.active .table__block-item {
  background-color: #2491eb;
}

.table__block-estimate:hover .table__block-item a,
.table__block-estimate:hover .table__block-item {
  color: #fff !important;
  transition: 0.5s ease;
}

.table__block-line {
  width: 100%;
  height: 1px;
  background-color: #d2d8e3;
}

.table__block-scroll {
  height: 100%;
}

.table__block {
  width: calc(100vw - 380px);
  height: calc(100% - 58px);
  border-radius: 20px;
  background-color: #fff;
  overflow-x: auto;
  overflow-y: auto;
}

.table__control {
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table__control-right {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.table__control-button {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  border-radius: 30px;
  border: 1px solid #fff;
}

.table__select {
  position: relative;
  border-radius: 30px;
  border: 1px solid #fff;
}

.table__select--active .table__select-list {
  bottom: calc(100% + 10%);
  opacity: 1;
  pointer-events: auto;
  transition: 0.5s ease;
}

.table__select-list {
  position: absolute;
  bottom: 90%;
  left: 0;
  border-radius: 5px;
  background: #f3f3f6;
  box-shadow: 0px 0px 24px 0px rgba(32, 92, 148, 0.15);
  opacity: 0;
  pointer-events: none;
  transition: 0.5s ease;
  z-index: 5;
}

.table__select-list li {
  padding: 8px 20px;
  white-space: nowrap;
  cursor: pointer;
  color: #404d63;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.5s ease;
}

.table__select-list li:hover {
  color: #2491eb;
  transition: 0.5s ease;
}

.table__select-head {
  position: relative;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.table__select-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  cursor: pointer;
}

.card-footer {
  margin: 15px;
}

.pagination {
  --bs-pagination-focus-box-shadow: none;
}

.item-nested,
.item-isVisibleNestedEstimates,
.item-id,
.item-startedDate {
  display: none;
}
.est-num-btn {
  border: 1px solid #474749;
  border-radius: 5px;
  display: none;
}
.est-num-inp:focus {
  border: 1px solid #474749;
  border-radius: 5px;
  margin-bottom: 3px;
}
</style>
