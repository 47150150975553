import { isRef } from "vue";
import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";
import { isDeliveryInstallation } from "@/app/predicates/delivery.predicates";

import { ReactiveComponent } from "../../entities/base-component";
import { CalculationStrategy, PriceResult } from "../base/calculation.strategy";

export class DeliveryCalculationStrategy extends CalculationStrategy {
    calculate(component: ReactiveComponent, [isHalfInstallation]): PriceResult {
        if (!component) {
            return this.getDefaultResult();
        }

        return {
            price: this.calculatePriceForType(
                component,
                CostDealType,
                isHalfInstallation,
            ),
            priceB2C: this.calculatePriceForType(
                component,
                B2BDealType,
                isHalfInstallation,
            ),
            priceB2B: this.calculatePriceForType(
                component,
                B2CDealType,
                isHalfInstallation,
            ),
        };
    }

    private calculatePriceForType(
        component: ReactiveComponent,
        dealType: string,
        isHalfInstallation: boolean,
    ) {
        const qty = isRef(component.qty) ? component.qty.value : component.qty;
        const price = component.price.getSystemPrice(dealType).getValue();
        const structureCode = isRef(component.structureType)
            ? component.structureType.value
            : component.structureType;

        if (isHalfInstallation && isDeliveryInstallation(structureCode || "")) {
            return (price * qty) / 2;
        }

        return price * qty;
    }
}
