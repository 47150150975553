import { Space } from "@/app/domain";
import {
    createMounts3MTapeConfig,
    createMountsCGFFrameConfig,
    createMountsKompasHorizontalConfig,
    createMountsKompasVerticalConfig,
    createMountsMullionsBottomConfig,
    createMountsMullionsTopConfig,
    createMountsToptrackSlidingDoorConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";

const createSlidingDoorMountsToptrackSlidingDoorConfig = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    space: Space,
): IComponentConfig => {
    return createMountsToptrackSlidingDoorConfig().qty(1).build();
};

const createSlidingDoorMountsKompasHorizontalConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorSections = 3;

    const qty = totalWidth * doorSections;

    return createMountsKompasHorizontalConfig().qty(qty).build();
};

const createSlidingDoorMountsKompasVerticalConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const totalWidth = MountsService.getTotalWidth(space);

    const doorSections = 3;
    const sides = 2;

    const sectionWidth = totalWidth / doorSections;

    const qty = totalHeight * (sides + sectionWidth) - 1;

    return createMountsKompasVerticalConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsTopConfig = (
    space: Space,
): IComponentConfig => {
    const doorWidth = MountsService.getDoorTotalWidth(space);
    const sides = 2;

    const qty = doorWidth * sides;

    return createMountsMullionsTopConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsBottomConfig = (
    space: Space,
): IComponentConfig => {
    const doorWidth = MountsService.getDoorTotalWidth(space);
    const sides = 2;

    const qty = doorWidth * sides;

    return createMountsMullionsBottomConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFFrameConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const doorMultiplier = MountsService.isForDoubleDoor(space) ? 2 : 1;
    const sides = 2;

    const qty = totalHeight * sides * doorMultiplier;

    return createMountsCGFFrameConfig().qty(qty).build();
};

const createSlidingDoorMounts3MTapeConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const doorMultiplier = MountsService.isForDoubleDoor(space) ? 2 : 1;
    const sides = 2;

    const qty = totalHeight * sides * doorMultiplier;

    return createMounts3MTapeConfig().qty(qty).build();
};

export const KompasSlidingDoorsConfigs = (space: Space) => [
    createSlidingDoorMountsToptrackSlidingDoorConfig(space),
    createSlidingDoorMountsKompasHorizontalConfig(space),
    createSlidingDoorMountsKompasVerticalConfig(space),
    createSlidingDoorMountsMullionsTopConfig(space),
    createSlidingDoorMountsMullionsBottomConfig(space),
    createSlidingDoorMountsCGFFrameConfig(space),
    createSlidingDoorMounts3MTapeConfig(space),
];
