<template>
  <select-dict
    v-model="structureType"
    :selectList="structureTypes"
  ></select-dict>
</template>

<script setup lang="ts">
import { defineModel } from "vue";
import { useDictStore } from "@/stores/dictStore";
import SelectDict from "@/components/UI/SelectDict.vue";

const dictStore = useDictStore();
const structureTypes = dictStore.getStructureTypes("partition");

const structureType = defineModel<string>({ default: "" });
</script>

<style lang="scss" scoped></style>
