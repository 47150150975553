import { IComponentConfig } from "@/app/@types/base-component";
import { ReactiveComponent } from "./base-component";

export class Hardware extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "hardware" });
    }
}

export class MountHardware extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "mount-hardware" });
    }
}
