import {
    Estimate,
    Estimate as NewEstimate,
} from "@/app/domain/entities/estimate";
import {
    Estimate as OldEstimate,
    EstimateInfo,
    ProgressInfo,
    DeliveryInfo,
    EstimatedSpace,
} from "@/models/estimate";
import { getTitleFromDictByCode, useDictStore } from "@/stores/dictStore";
import { CostDealType, B2CDealType, B2BDealType } from "../constants";
import { CalculationItem } from "@/models/calculate";
import { Door, Hardware, Partition, ReactiveComponent, Space } from "../domain";
import { Delivery, DeliveryItem } from "../domain/entities/delivery";

export class EstimateConverterService {
    /**
     * Converts new estimate format to old format
     */
    static convertToOldFormat = (
        newEstimate: NewEstimate,
        spaces: Space[],
        delivery: Delivery,
    ): OldEstimate => {
        const deliveryItems = delivery.getDeliveryItems();

        // Convert base estimate info
        const estimateInfo: EstimateInfo = {
            started:
                typeof newEstimate.started === "string"
                    ? new Date(newEstimate.started).getUTCDate()
                    : newEstimate.started.getUTCDate(),
            priceDate: new Date(newEstimate.priceDate) || new Date(),
            priceDateFormated: newEstimate.priceDateFormated || "",
            subtotal:
                newEstimate.subtotal
                    ?.getSystemPrice(newEstimate.dealTypeCode)
                    .getValue() || 0,
            estimatePrice:
                newEstimate.estimatePrice
                    ?.getSystemPrice(newEstimate.dealTypeCode)
                    .getValue() || 0,
            code: newEstimate.code || "",
            estimateNo: newEstimate.estimateNo || "",
            trackCode: newEstimate.trackCode || "",
            title: newEstimate.title || "",
            client: newEstimate.client,
            managerCode: newEstimate.managerCode || "",
            estimatorCode: newEstimate.estimatorCode || "",
            dealTypeCode: newEstimate.dealTypeCode || "",
            installationPlaceCode: newEstimate.installationPlaceCode || "",
            installationUsageCode: newEstimate.installationUsageCode || "",
            installationPlaceTitle: null, // This field seems to be removed in new format
            installedSystemCode: newEstimate.installedSystemCode || "",
            usageCode: newEstimate.installationUsageCode || "",
            colorCode: newEstimate.colorCode || "",
            shippingAddress: newEstimate.client.shippingAddress || "",
            shippingZip: newEstimate.shippingZip || "",
            shippingState: newEstimate.shippingState || "",
            delivery: newEstimate.delivery || false,
            installation: newEstimate.installation || false,
            isHaflInstallation: newEstimate.isHalfInstallation || false,
            halfInstallation: newEstimate.halfInstallation || 0,
            halfInstallationWithDiscount:
                newEstimate.halfInstallationWithDiscount || 0,
            totalSquareFt: newEstimate.totalSquareFt?.format() || "",
        };

        // Convert progress info
        const progressInfo: ProgressInfo = {
            status: newEstimate.progress.status || "",
            updated: newEstimate.progress.updated || "",
            validatedPage: newEstimate.progress.validatedPage || 1,
            activePage: newEstimate.progress.activePage || 1,
            activeSpaceCode: newEstimate.progress.activeSpaceCode || "",
            activeStructureCode: newEstimate.progress.activeStructureCode || "",
            activeDoorCode: newEstimate.progress.activeDoorCode || "",
            activeFillerCode: newEstimate.progress.activeFillerCode || "",
            isSaved: newEstimate.progress.isSaved || false,
        };

        // Create delivery info with empty arrays since they are not present in new format
        const deliveryInfo: DeliveryInfo = {
            deliveryAddress: {
                state: newEstimate.shippingState || "",
                zip: newEstimate.shippingZip || "",
                address: newEstimate.client.shippingAddress || "",
            },
            deliveryCosts: deliveryItems.map((item) =>
                this.convertToOldDeliveryItem(item),
            ),
            extraCosts: [],
            multiplicator: newEstimate.multiplicator || 1,
            discountPercent: newEstimate.discountPercent ?? 0,
            discountSum: newEstimate.discountSum ?? 0,
        };

        // Construct the final old format estimate
        const oldEstimate: OldEstimate = {
            overhead: [],
            spaces: this.convertToOldEstimateSpace(spaces),
            info: estimateInfo,
            delivery: deliveryInfo,
            progress: progressInfo,
        };

        return oldEstimate;
    };

    static convertToOldDeliveryItem = (deliveryItem: DeliveryItem) => {
        return {
            code: deliveryItem.code,
            costTypeCode: deliveryItem.typeCode,
            costValueCode: deliveryItem.structureType,
            title: deliveryItem.title,
            description: "",
            unit: deliveryItem.unit,
            qty: deliveryItem.qty,
            price: deliveryItem.price.getSystemPrice(CostDealType).getValue(),
            priceB2C: deliveryItem.price.getSystemPrice(B2CDealType).getValue(),
            priceB2B: deliveryItem.price.getSystemPrice(B2BDealType).getValue(),
            sum: deliveryItem.sum.getSystemPrice(CostDealType).getValue(),
        };
    };

    static convertToOldBackedHeader(estimate: Estimate) {
        const dictStore = useDictStore();

        const estimatePrice = estimate.estimatePrice;
        const subtotalPrice = estimate.subtotal;
        const installedSystemCode = estimate.installedSystemCode;

        return {
            startedDate:
                typeof estimate.started === "string"
                    ? new Date(estimate.started)
                    : estimate.started.getUTCDate(),
            priceDate: estimate.priceDate || new Date(),
            priceDateFormated: estimate.priceDateFormated || "",
            code: estimate.code || "",
            status: estimate.progress.status || "",
            estimateN: estimate.estimateNo || "",
            trackCode: estimate.trackCode || "",
            title: estimate.title || "",
            client: estimate.client.name || "",
            manager:
                getTitleFromDictByCode(
                    dictStore.getManagers(),
                    estimate.managerCode,
                ) || "",
            dealTypeCode:
                getTitleFromDictByCode(
                    dictStore.getDealTypes(),
                    estimate.dealTypeCode,
                ) || "",
            installationPlaceTitle:
                getTitleFromDictByCode(
                    dictStore.getInstallationPlaces(),
                    estimate.installationPlaceCode,
                ) || "",
            installedSystem:
                getTitleFromDictByCode(
                    dictStore.getSystemTypes(),
                    estimate.installedSystemCode,
                ) || "",
            usage:
                getTitleFromDictByCode(
                    dictStore.getSystemUsages(),
                    estimate.installationUsageCode,
                ) || "",
            finishColor:
                getTitleFromDictByCode(
                    dictStore.getFinishColors(),
                    estimate.colorCode,
                ) || "",
            shippingAddress:
                estimate.shippingZip + ", " + estimate.shippingState || "",
            installation: JSON.stringify(estimate.installation) || "",
            delivery: JSON.stringify(estimate.delivery) || "",
            estimator: estimate.estimatorCode
                ? estimate.estimatorCode
                : "not set",
            updatedDate: estimate.progress.updated.toLocaleString() || "",
            quantity: "1",
            pricePer: estimatePrice
                .getSystemPrice(installedSystemCode)
                .format(),
            price: estimatePrice.getSystemPrice(CostDealType).format(),
            priceB2C: estimatePrice.getSystemPrice(B2CDealType).format(),
            priceB2B: estimatePrice.getSystemPrice(B2BDealType).format(),
            subPrice: subtotalPrice
                .getSystemPrice(installedSystemCode)
                .format(),
            discount: estimate.discountPercent,
            business: estimate.client.businessName || "",
            email: estimate.client.email || "",
            phone: estimate.client.phone || "",
            state: estimate.shippingState || "",
            comments: "",
            lastValidatedPage: estimate.progress.validatedPage || 1,
        };
    }

    static convertCalculationToOldFormat(
        estimate: NewEstimate,
        spaces: Space[],
    ): CalculationItem {
        return {
            elementType: "estimate",
            elementCode: estimate.code,
            qty: 1,
            multiplier: estimate.multiplicator,
            discountSum: estimate.discountPercent,
            discountPercent: estimate.discountPercent,
            subitems: {
                items: spaces.map((space) =>
                    this.convertStructureToOldCaclulationItem(estimate, space),
                ),
            },
        };
    }

    private static convertStructureToOldCaclulationItem = (
        estimate: NewEstimate,
        structure: ReactiveComponent,
    ): CalculationItem => {
        return {
            elementCode: structure.code,
            elementType: structure.type || "",
            elementParams: null,
            subitems: {
                items: structure.components.map((component) =>
                    this.convertStructureToOldCaclulationItem(
                        estimate,
                        component,
                    ),
                ),
            },
            price: structure.price.getSystemPrice(CostDealType).getValue(),
            priceB2C: structure.price.getSystemPrice(B2CDealType).getValue(),
            priceB2B: structure.price.getSystemPrice(B2BDealType).getValue(),
            qty: structure.qty,
            qtyDisplay: structure.qty,
            sum: structure.sum.getSystemPrice(CostDealType).getValue(),
            sumB2C: structure.sum.getSystemPrice(B2CDealType).getValue(),
            sumB2B: structure.sum.getSystemPrice(B2BDealType).getValue(),
            subSum: estimate.subtotal
                .getSystemPrice(estimate.dealTypeCode)
                .getValue(),
            sumOrig: estimate.subtotal
                .getSystemPrice(estimate.dealTypeCode)
                .getValue(),
            multiplier: 0,
            discountSum: 0,
            discountPercent: 0,
        };
    };
    static convertToOldEstimateSpace = (spaces: Space[]): EstimatedSpace[] => {
        return spaces
            .filter((space) => space.components.length > 0)
            .map((space) => {
                const partitions = space.getAllPartitions();

                return {
                    code: space.code,
                    title: space.title,
                    structures: partitions.map((partition) =>
                        this.convertToOldSpaceStructure(partition),
                    ),
                };
            });
    };

    static convertToOldSpaceStructure = (partition: Partition) => {
        const doors = partition.getAllDoors();
        const fillers = partition.getAllOverdoors();
        const mounts = partition.getActiveMount();

        return {
            code: partition.code,
            partition: this.convertToOldPartitionStructureItem(partition),
            doors: doors.map((door) =>
                this.convertToOldDoorStructureItem(door),
            ),
            fillers: fillers.map((filler) =>
                this.convertToOldStructureItem(filler),
            ), // transom and fillers
            mounts: this.convertToOldStructureItem(mounts), // CHANEL, TRACKS, PROFILES
            additionalCosts: [],
        };
    };

    static convertToOldStructureItem = (component: ReactiveComponent) => {
        return {
            id: component.id || "",
            code: component.code || "",
            structureTypeCode: component.structureType || "",
            title: component.title || "",
            qty: component.qty || 0,
            dimensions: {
                width: {
                    ft: component.dimensions.width.ft,
                    inch: component.dimensions.width.inch,
                    fractionA: component.dimensions.width.fractionDivisor,
                    fractionB: component.dimensions.width.fractionDividend,
                },
                height: {
                    ft: component.dimensions.height.ft,
                    inch: component.dimensions.height.inch,
                    fractionA: component.dimensions.height.fractionDivisor,
                    fractionB: component.dimensions.height.fractionDividend,
                },
                perimeterFt: 0,
                areaFt: component.dimensions.getAreaFt().toSquareFt().format(),
            },
            glassSortCode: "",
            glassThicknessCode: "",
            hardwares: [],
            note: "",
            outOfSquareQty: 0,
            additionalCosts: [],
            // filler specific
            fillerTypeCode: "", // glass , aluminum sheet, other
            fillerMaterialCode: "",
            fillerAssetsQty: 0,
        };
    };

    static convertToOldPartitionStructureItem = (component: Partition) => {
        const glass = component.getActiveGlass();
        const glassThickness = component.getActiveGlassThinkness();
        const outofsquare = component.getActiveOutOfSquare();

        return {
            id: component.id || "",
            code: component.code || "",
            structureTypeCode: component.structureType || "",
            title: component.title || "",
            qty: component.qty || 0,
            dimensions: {
                width: {
                    ft: component.dimensions.width.ft,
                    inch: component.dimensions.width.inch,
                    fractionA: component.dimensions.width.fractionDivisor,
                    fractionB: component.dimensions.width.fractionDividend,
                },
                height: {
                    ft: component.dimensions.height.ft,
                    inch: component.dimensions.height.inch,
                    fractionA: component.dimensions.height.fractionDivisor,
                    fractionB: component.dimensions.height.fractionDividend,
                },
                perimeterFt: 0,
                areaFt: component.dimensions.getAreaFt().toSquareFt().format(),
            },
            glassSortCode: glass?.structureType || "",
            glassThicknessCode: glassThickness?.structureType || "",
            hardwares: [],
            note: "",
            outOfSquareQty: outofsquare?.qty || 0,
            additionalCosts: [],
            // filler specific
            fillerTypeCode: "",
            fillerMaterialCode: "",
            fillerAssetsQty: "",
        };
    };

    static convertToOldDoorStructureItem = (component: Door) => {
        const hardwares = component.getActiveHardwares();
        const glass = component.getActiveGlassPartition();
        const glassThickness = component.getActiveGlassThinkness();

        return {
            id: component.id || "",
            code: component.code || "",
            structureTypeCode: component.structureType || "",
            title: component.title || "",
            qty: component.qty || 0,
            dimensions: {
                width: {
                    ft: component.dimensions.width.ft,
                    inch: component.dimensions.width.inch,
                    fractionA: component.dimensions.width.fractionDivisor,
                    fractionB: component.dimensions.width.fractionDividend,
                },
                height: {
                    ft: component.dimensions.height.ft,
                    inch: component.dimensions.height.inch,
                    fractionA: component.dimensions.height.fractionDivisor,
                    fractionB: component.dimensions.height.fractionDividend,
                },
                perimeterFt: 0,
                areaFt: component.dimensions.getAreaFt().toSquareFt().format(),
            },
            glassSortCode: glass?.structureType || "",
            glassThicknessCode: glassThickness?.structureType || "",
            hardwares: hardwares.map((hardware) =>
                this.convertToOldHardwareItem(hardware),
            ),
            note: "",
            outOfSquareQty: 0,
            additionalCosts: [],
            // filler specific
            fillerTypeCode: "", // glass , aluminum sheet, other
            fillerMaterialCode: "",
            fillerAssetsQty: 0,
        };
    };

    static convertToOldHardwareItem = (hardware: Hardware) => {
        return {
            code: hardware.code || "",
            hardwareTypeCode: hardware.typeCode || "",
            hardwareValueCode: hardware.structureType || "",
            qty: hardware.qty || 0,
            price: hardware.price.getSystemPrice(CostDealType).getValue() || 0,
            priceB2C:
                hardware.price.getSystemPrice(B2CDealType).getValue() || 0,
            priceB2B:
                hardware.price.getSystemPrice(B2BDealType).getValue() || 0,
            sum: hardware.sum.getSystemPrice(B2CDealType).getValue() || 0,
        };
    };
}
