<template>
  <select-dict v-model="structureType" :selectList="hardwareValues" />
</template>

<script setup lang="ts">
import { defineProps, defineModel } from "vue";

import { useDictStore } from "@/stores/dictStore";
import SelectDict from "@/components/UI/SelectDict.vue";

type MountHardwareValueSelectProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  typeCode: any;
};

const dictStore = useDictStore();

const props = defineProps<MountHardwareValueSelectProps>();
const hardwareValues = dictStore.getHardwareValues(props.typeCode);
const structureType = defineModel<string>();
</script>

<style lang="scss" scoped></style>
