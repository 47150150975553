import { IComponentConfig } from "@/app/@types/base-component";
import { ReactiveComponent } from "./base-component";
import { MountHardware } from "./hardware";

export class Mounts extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "mounts" });
    }

    replaceMountHardwares = (components: MountHardware[]) => {
        this.replaceComponents("mount-hardware", components);
    };

    getHardwares = (): MountHardware[] => {
        return this.components.filter(
            (component) => component.type === "mount-hardware",
        );
    };
}
