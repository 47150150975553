import { IComponentConfig } from "@/app/@types/base-component";
import {
    createMountsBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsStripesConfig,
    createMountsTopFrameSortConfig,
} from "../../base";
import { Space } from "@/app/domain/entities/space";
import { MountsService } from "@/app/services/mounts.service";

const createSwingDoorMountsTopFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTotalWidth(space);

    return createMountsTopFrameSortConfig().qty(qty).build();
};

const createSwingDoorMountsBottomFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const qty = totalWidth - doorWidth;

    return createMountsBottomFrameSortConfig().qty(qty).build();
};

const createSwingDoorMountsGlazingGasketConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);
    const hasTransom = MountsService.hasOverdoorTransom(space);
    const sides = 2;

    const doorDeduction = doorWidth - (hasTransom ? 0 : doorWidth);
    const qty = (totalWidth * sides - doorDeduction) * sides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

const createSwingDoorMountsStripesConfig = (space: Space): IComponentConfig => {
    const qty = MountsService.getStipJointsQty(space);

    return createMountsStripesConfig().qty(qty).build();
};

export const CGS200SwingDoorsConfigs = (space: Space) => [
    createSwingDoorMountsTopFrameSortConfig(space),
    createSwingDoorMountsBottomFrameSortConfig(space),
    createSwingDoorMountsGlazingGasketConfig(space),
    createSwingDoorMountsStripesConfig(space),
];
