import { Constructor, DoorType } from "../@types/shared";
import {
    DoubleSlidingDoors,
    DoubleSwingDoors,
    KompasDoubleFramedDoorCode,
    KompasSingleFramedDoorCode,
    SingleSlidingDoors,
    SingleSwingDoors,
} from "../constants";
import { DoorCutsCreationStrategies } from "../domain/strategies";
import { ICreateManyStrategy } from "../domain/strategies/base/creation.strategy";

export class DoorCutsStrategyRegistry {
    private strategies = new Map<string, Constructor<ICreateManyStrategy>>();

    // prettier-ignore
    constructor() {
        this.register(SingleSwingDoors as DoorType[], DoorCutsCreationStrategies.SingleSwingDoorCutsStrategy);
        this.register(SingleSlidingDoors as DoorType[], DoorCutsCreationStrategies.SingleSlidingDoorCutsStrategy);
        this.register(DoubleSwingDoors as DoorType[], DoorCutsCreationStrategies.DoubleSwingDoorCutsStrategy);
        this.register(DoubleSlidingDoors as DoorType[], DoorCutsCreationStrategies.DoubleSlidingDoorCutsStrategy);
        this.register([KompasSingleFramedDoorCode] as DoorType[], DoorCutsCreationStrategies.KompasSingleDoorCutsStrategy);
        this.register([KompasDoubleFramedDoorCode] as DoorType[], DoorCutsCreationStrategies.KompasDoubleDoorCutsStrategy);
    }

    getStrategy = (
        doorCode: DoorType,
    ): Constructor<ICreateManyStrategy> | null => {
        return this.strategies.get(doorCode) || null;
    };

    private register = (
        doorTypes: DoorType[],
        strategy: Constructor<ICreateManyStrategy>,
    ) => {
        for (const doorType of doorTypes) {
            this.strategies.set(doorType, strategy);
        }
    };
}
