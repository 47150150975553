<template>
  <div class="step__block-field" v-if="activeOverdoor">
    <div class="step__block-field-block">
      <h3>{{ overdoorName }} type:</h3>
    </div>
  </div>
  <div class="step__block-doortype step__block-doortype--transform">
    <div>
      <span>{{ capitalize(overdoorName) }} type:</span>
    </div>
    <div>
      <OverdoorMaterialTypeSelect v-model="overdoorMaterialType" />
    </div>
  </div>
  <div class="step__block-field" v-if="hasMaterials">
    <div class="step__block-field-block">
      <h3>{{ upperCase(overdoorName) }} {{ overdoorMaterialType }}:</h3>
    </div>
  </div>

  <GlassOverdoorPriceTable v-if="isGlassOverdoor" />
  <AluminiumOverdoorPriceTable v-if="isAluminiumOverdoor" />
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { capitalize, upperCase } from "lodash";
import { useOverdoorStore } from "@/stores/new/overdoor.store";

import OverdoorMaterialTypeSelect from "./OverdoorMaterialTypeSelect.vue";
import GlassOverdoorPriceTable from "./GlassOverdoorPriceTable.vue";
import AluminiumOverdoorPriceTable from "./AluminiumOverdoorPriceTable.vue";

import { OverdoorPredicates } from "@/app/predicates";

const overdoorStore = useOverdoorStore();

const activeOverdoor = computed(() => overdoorStore.activeOverdoor);
const activeOverdoorMaterials = computed(
  () => overdoorStore.activeOverdoorMaterials,
);

const overdoorMaterialType = computed(() => overdoorStore.overdoorMaterialType);
const overdoorName = computed(() => activeOverdoor.value?.structureType || "");
const hasMaterials = computed(() => activeOverdoorMaterials.value.length > 0);

const isGlassOverdoor = computed(() =>
  OverdoorPredicates.isOverdoorGlassMaterialType(overdoorMaterialType.value),
);
const isAluminiumOverdoor = computed(() =>
  OverdoorPredicates.isOverdoorAluminiumMaterialType(
    overdoorMaterialType.value,
  ),
);

watch(
  () => overdoorMaterialType.value,
  (type) => {
    if (hasMaterials.value) {
      return;
    }

    overdoorStore.addOverdoorMaterial(type);
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.step__block-fillertype-container {
  display: grid;
  row-gap: 5px;
  padding: 15px 0;
}

.step__block-fillertype-container .step__block-fillertype-wrap:first-child {
  padding-bottom: 5px;
}

.step__block-fillertype {
  color: #404d63;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-fillertype-wrap div:first-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.step__block-fillertype-wrap div {
  align-self: center;
}

.step__block-fillertype-wrap {
  display: grid;
  grid-template-columns: 70px 316px repeat(2, 1fr);
  column-gap: 50px;
}

.step__block-hardwareset {
  padding: 15px 0;
  display: grid;
  row-gap: 5px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-hardwareset-item {
  display: grid;
  grid-template-columns: 100px 1fr;
  column-gap: 31px;
}

.step__block-hardwareset-item div:first-child {
  align-self: center;
}

.step__block-doortype {
  display: grid;
  grid-template-columns: minmax(0, max-content) 316px;
  column-gap: 26px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 15px;
}

.step__block-doortype--transform {
  padding-top: 15px;
}

.step__block-doortype div:first-child {
  align-self: center;
  justify-self: center;
}

.step__block-field {
  display: flex;
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  border-bottom: 1px solid #d2d8e3;
}

.step__block-field h3 {
  color: #404d63;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.step__block-field--hardware .step__block-field-block {
  justify-content: flex-start;
  column-gap: 10px;
}

.step__block-dimensions {
  padding: 15px 0;
}

.step__block-dimensions-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step__block-dimensions-right {
  display: flex;
  column-gap: 92px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-dimensions-right div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.step__block-dimensions-right div:last-child {
  width: 65px;
}

.step__block-dimensions {
  padding: 15px 0;
}

.step__block-dimensions-left {
  display: grid;
  grid-template-columns: minmax(0, 46px) repeat(2, 1fr);
  column-gap: 10px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-dimensions-units {
  display: grid;
  grid-template-columns: repeat(3, 60px);
  padding-bottom: 11px;
}

.step__block-dimensions-units div {
  padding-left: 11px;
}

.step__block-dimensions-container {
  display: grid;
  grid-template-columns: repeat(4, 60px);
}

.step__block-dimensions-container div {
  background-color: #fff;
  height: 35px;
}

.step__block-dimensions-container div input {
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 7px;
}

.step__block-dimensions-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.step__block-dimensions-block--table:first-child
  .step__block-dimensions-container
  div {
  border-bottom: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table .step__block-dimensions-container div {
  border-right: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table
  .step__block-dimensions-container
  div:last-child {
  border-right: none;
}

.step__block-partitionglass {
  padding: 15px 0;
}

.step__block-partitionglass-block {
  display: grid;
  grid-template-columns: 35% 150px repeat(2, 1fr);
  column-gap: 15px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  column-gap: 15px;
}

.step__block-partitionglass-block--selects {
  padding-top: 10px;
}

.step__block-partitionglass-subblock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.step__block-partitionglass-subblock--first {
  align-items: flex-start;
}

.step__block-partitionglass-subblock--unit {
  padding-right: 10px;
}

.step__block-partitionglass-subblock input {
  width: 50px;
  height: 35px;
  border-radius: 20px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
}

.step__block-partitionglass-total {
  padding: 15px 0;
  display: grid;
  row-gap: 15px;
}

.step__block-partitionglass-line {
  width: 100%;
  height: 1px;
  background-color: #d2d8e3;
}

.step__block-dimensions-warning {
  align-self: flex-start;
  justify-self: flex-start;
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: #ff6255;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 25px;
}
</style>
