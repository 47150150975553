import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";

import { ReactiveComponent } from "../../entities/base-component";
import { CalculationStrategy, PriceResult } from "../base/calculation.strategy";

export class SpaceCalculationStrategy extends CalculationStrategy {
    public calculate(component: ReactiveComponent): PriceResult {
        return {
            price: this.calculateComponentsPriceForType(
                component,
                CostDealType,
            ),
            priceB2C: this.calculateComponentsPriceForType(
                component,
                B2CDealType,
            ),
            priceB2B: this.calculateComponentsPriceForType(
                component,
                B2BDealType,
            ),
        };
    }

    private calculateComponentsPriceForType(
        component: ReactiveComponent,
        dealType: string,
    ) {
        const children = component.components;

        return children.reduce((total, child) => {
            const sum = child.sum.getSystemPrice(dealType).getValue();

            return total + sum;
        }, 0);
    }
}
