import { IComponentConfig } from "@/app/@types/base-component";
import {
    createMountsBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsSideFrameSortConfig,
    createMountsStripesConfig,
    createMountsToptrackConfig,
    createMountsToptrackSlidingDoorConfig,
} from "../../base";
import { Space } from "@/app/domain/entities/space";
import { MountsService } from "@/app/services/mounts.service";

const createMountsToptrackSlidingDoorHardwaresConfig = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    space: Space,
): IComponentConfig => {
    return createMountsToptrackSlidingDoorConfig().qty(1).build();
};

const createMountsToptrackHardwaresConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTotalWidth(space);

    return createMountsToptrackConfig().qty(qty).build();
};

const createMountsSideFrameSortHardwaresConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const sides = 2;

    const qty = totalHeight * sides;

    return createMountsSideFrameSortConfig().qty(qty).build();
};

const createMountsBottomFrameSortHardwaresConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const qty = totalWidth - doorWidth;

    return createMountsBottomFrameSortConfig().qty(qty).build();
};

const createMountsGlazingGasketHardwaresConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);
    const hasTransom = MountsService.hasOverdoorTransom(space);

    const sides = 2;

    const horizontalGaskets = totalWidth * sides;
    const doorDeduction = doorWidth - (hasTransom ? 0 : doorWidth);
    const verticalGaskets = totalHeight * sides - doorDeduction;

    const qty = (horizontalGaskets + verticalGaskets) * sides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

const createMountsStripesHardwaresConfig = (space: Space): IComponentConfig => {
    const qty = MountsService.getStipJointsQty(space);

    return createMountsStripesConfig().qty(qty).build();
};

export const CGS200SlidingDoorsConfigs = (space: Space) => [
    createMountsToptrackHardwaresConfig(space),
    createMountsToptrackSlidingDoorHardwaresConfig(space),
    createMountsSideFrameSortHardwaresConfig(space),
    createMountsBottomFrameSortHardwaresConfig(space),
    createMountsGlazingGasketHardwaresConfig(space),
    createMountsStripesHardwaresConfig(space),
];
