export const RollerSetTypeCode = "dr-roller";
export const PatchesSetTypeCode = "dr-patches";
export const UHolderTypeCode = "dr-uholder";
export const HandleTypeCode = "dr-handle";
export const LockTypeCode = "dr-lock";
export const SingleFabricProducedDoorTypeCode = "dr-door-kmp-s";
export const DoubleFabricProducedDoorTypeCode = "dr-door-kmp-d";

export const CutsHolesTypeCode = "holes-01";
export const HardwareNoneTypeCode = "none";

export const LockTitle = "Lock";
export const HandleTitle = "Handle";
export const PatchesSetTitle = "Patches set";
