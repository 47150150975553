<template>
  <input
    class="step__left-input"
    type="text"
    @input="onInputChange"
    :value="estimate.title"
    placeholder="Enter Project Name..."
  />
</template>

<script setup lang="ts">
import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { computed } from "vue";

const estimateStore = useNewEstimateStore();
const estimate = computed(() => estimateStore.estimate);

const onInputChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const value = target.value;

  estimateStore.renameEstimate(value);
};
</script>

<style lang="scss" scoped>
.step__left-input {
  color: rgba(64, 77, 99, 1);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d2d8e3;
  width: 100%;
}
</style>
