import { IComponentConfig } from "@/app/@types/base-component";

import {
    createOverdoorAluminiumMaterialConfig,
    createOverdoorGlassMaterialConfig,
    createOverdoorGlassMaterialThinknessConfig,
} from "../../configs/overdoor-material.config";

import { OverdoorMaterial } from "../../entities/overdoor-material";
import { CreateManyStrategy } from "../base/creation.strategy";

const OVERDOOR_GLASS_MATERIAL: IComponentConfig[] = [
    createOverdoorGlassMaterialConfig().build(),
    createOverdoorGlassMaterialThinknessConfig().build(),
];

const OVERDOOR_ALUMINIUM_MATERIAL: IComponentConfig[] = [
    createOverdoorAluminiumMaterialConfig().build(),
];

export class OverdoorGlassMaterialStrategy extends CreateManyStrategy {
    constructor() {
        super(OVERDOOR_GLASS_MATERIAL, OverdoorMaterial);
    }
}

export class OverdoorAluminiumMaterialStrategy extends CreateManyStrategy {
    constructor() {
        super(OVERDOOR_ALUMINIUM_MATERIAL, OverdoorMaterial);
    }
}
