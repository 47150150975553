import { getFromDictByCode, useDictStore } from "@/stores/dictStore";
import { DeliveryItemConfig } from "../entities/delivery";
import {
    DeliveryCombinedCode,
    DeliveryCombinedTypeCode,
    DeliveryDirectCode,
    DeliveryDirectTypeCode,
    DeliveryInstallationCode,
    DeliveryInstallationTypeCode,
} from "@/app/constants";

export type ConfigBuilder = {
    typeCode(value: string): ConfigBuilder;
    structureCode(value: string): ConfigBuilder;
    withTitle(): ConfigBuilder;
    withUnit(): ConfigBuilder;
    build(): DeliveryItemConfig;
};

const createCombineDeliveryConfig = (): DeliveryItemConfig => {
    return createDeliveryConfig()
        .typeCode(DeliveryCombinedTypeCode)
        .structureCode(DeliveryCombinedCode)
        .withTitle()
        .withUnit()
        .build();
};

const createDirectDeliveryConfig = (): DeliveryItemConfig => {
    return createDeliveryConfig()
        .typeCode(DeliveryDirectTypeCode)
        .structureCode(DeliveryDirectCode)
        .withTitle()
        .withUnit()
        .build();
};

const createInstallationDeliveryConfig = (): DeliveryItemConfig => {
    return createDeliveryConfig()
        .typeCode(DeliveryInstallationTypeCode)
        .structureCode(DeliveryInstallationCode)
        .withTitle()
        .withUnit()
        .build();
};

export const DeliveryItemsConfigs = () => [
    createCombineDeliveryConfig(),
    createDirectDeliveryConfig(),
    createInstallationDeliveryConfig(),
];

const createDeliveryConfig = (): ConfigBuilder => {
    const dictStore = useDictStore();
    const config = {
        title: "",
        unit: "",
        typeCode: "",
        structureType: "",
        qty: 0,
    };

    const builder = {
        typeCode(value: string) {
            config.typeCode = value;
            return builder;
        },
        structureCode(value: string) {
            config.structureType = value;
            return builder;
        },
        withTitle() {
            config.title = getFromDictByCode(
                dictStore.getAdditionalCosts("delivery"),
                config.typeCode,
            )?.title;
            return builder;
        },
        withUnit() {
            config.unit =
                getFromDictByCode(
                    dictStore.getAdditionalCosts("delivery"),
                    config.typeCode,
                )?.unit || "";
            return builder;
        },
        build() {
            return config;
        },
    };

    return builder;
};
