import { MountMullionsTopbottom1ValueCode } from "@/app/constants/mounts";
import {
    createMountsCGFBottomFrameSortConfig,
    createMountsCGFFrameConfig,
    createMountsGlazingGasketConfig,
    createMountsMullionsBottomConfig,
    createMountsMullionsTopConfig,
    createMountsToptrackConfig,
} from "../../base";
import { Space } from "@/app/domain";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";

const createSlidingDoorMountsToptrackConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTotalWidth(space);

    return createMountsToptrackConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const qty = totalWidth - doorWidth;

    return createMountsCGFBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsTopConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const sides = 2;
    const qty = (totalWidth + doorWidth) * sides;

    return createMountsMullionsTopConfig()
        .structureType(MountMullionsTopbottom1ValueCode)
        .qty(qty)
        .build();
};

const createSlidingDoorMountsMullionsBottomConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const sides = 2;
    const qty = (totalWidth + doorWidth) * sides;

    return createMountsMullionsBottomConfig()
        .structureType(MountMullionsTopbottom1ValueCode)
        .qty(qty)
        .build();
};

const createSlidingDoorMountsCGFFrameConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const doorSections = MountsService.isForDoubleDoor(space) ? 4 : 3;
    const sides = 2;

    const verticalProfiles = totalHeight * sides;

    const qty = verticalProfiles * doorSections;

    return createMountsCGFFrameConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);
    const sides = 2;

    const totalGaskets = totalWidth * sides;
    const doorGaskets = doorWidth * sides;

    const qty = (totalGaskets - doorGaskets) * 2;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

export const CGF300SlidingDoorsConfigs = (space: Space) => [
    createSlidingDoorMountsToptrackConfig(space),
    createSlidingDoorMountsCGFBottomFrameSortConfig(space),
    createSlidingDoorMountsGlazingGasketConfig(space),
    createSlidingDoorMountsMullionsTopConfig(space),
    createSlidingDoorMountsMullionsBottomConfig(space),
    createSlidingDoorMountsCGFFrameConfig(space),
];
