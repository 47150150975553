import {
    DividerPartitionCode,
    DoorPartitionCode,
    GlassPartitionCode,
    MultiFoldingPartitionCode,
} from "@/app/constants";
import { IComponentConfig } from "@/app/@types/base-component";
import { ReactiveComponent } from "./base-component";
import { ref, Ref } from "vue";
import { DimensionValue } from "./dimensions";
import { IDimensionValue } from "@/app/@types/dimensions";
import { Door } from "./door";
import { Overdoor } from "./overdoor";
import { Mounts } from "./mounts";

export class Partition extends ReactiveComponent {
    totalAreaSq: Ref<IDimensionValue>;

    constructor(config: IComponentConfig) {
        super({ ...config, type: "partition" });

        this.totalAreaSq = ref(new DimensionValue(0, this.dimensions.width));
    }

    updateTotalAreaSq = (value: number | undefined = 0): void => {
        this.totalAreaSq.value = new DimensionValue(
            value,
            this.dimensions.width,
        );
    };

    calculateTotalAreaSq = (): void => {
        this.totalAreaSq.value = this.dimensions.getAreaFt();
    };

    substractDoorIfExistAndUpdateDimensions = (door: Door | null): void => {
        const doorAreaFt =
            door?.dimensions.getAreaFt().toInches().getValue() ?? 0;
        const totalAreaFt = this.totalAreaSq.value.toInches().getValue();

        const partitionAreaFt = totalAreaFt - doorAreaFt;

        this.updateTotalAreaSq(partitionAreaFt);
    };

    substractOverdoorIfExistAndUpdateDimensions = (
        overdoor: Overdoor | null,
    ): void => {
        const overdoorAreaFt =
            overdoor?.dimensions.getAreaFt().toSquareFt().getValue() ?? 0;
        const totalAreaFt = this.totalAreaSq.value.toSquareFt().getValue();

        const partitionAreaFt = totalAreaFt - overdoorAreaFt;

        this.updateTotalAreaSq(partitionAreaFt);
    };

    isDivider = (): boolean => {
        return this.structureType?.value === DividerPartitionCode;
    };

    isGlassPartition = (): boolean => {
        return this.structureType?.value === GlassPartitionCode;
    };

    isDoorPartition = (): boolean => {
        return this.structureType?.value === DoorPartitionCode;
    };

    isMultiFoldingPartition = (): boolean => {
        return this.structureType?.value === MultiFoldingPartitionCode;
    };

    getAllOverdoors = () => {
        return this.components.filter(
            (component) => component.type === "overdoor",
        );
    };

    getExtra = () => {
        return this.components.find((component) => component.type === "extra");
    };

    getAllDoors = () => {
        return this.components.filter((component) => component.type === "door");
    };

    getActiveDoor = (): Door => {
        return this.components.find(
            (component) => component.type === "door",
        ) as unknown as Door;
    };

    getActiveOverdoor = () => {
        return this.components.find(
            (component) => component.type === "overdoor",
        ) as unknown as Overdoor;
    };

    getActiveMount = () => {
        return this.components.find(
            (component) => component.type === "mounts",
        ) as unknown as Mounts;
    };

    getActiveGlass = () => {
        return this.components.find(
            (component) => component.type === "glassPartition",
        );
    };

    getActiveGlassThinkness = () => {
        const glass = this.getActiveGlass();
        return glass?.components.find(
            (component) => component.type === "glassThinknessPartition",
        );
    };

    getActiveOutOfSquare = () => {
        return this.components.find(
            (component) => component.type === "outOfSquare",
        );
    };
}
