import { IComponentConfig } from "@/app/@types/base-component";

import { getFromDictByCode, useDictStore } from "@/stores/dictStore";
import { OverheadConfig } from "../entities/overhead";
import {
    OverheadCuttingMaterialCode,
    OverheadCuttingMaterialTypeCode,
    OverheadDrawingCode,
    OverheadDrawingTypeCode,
    OverheadGasParkingTollCode,
    OverheadGasParkingTollTypeCode,
    OverheadMeasurementsCode,
    OverheadMeasurementsTypeCode,
    OverheadPackingCode,
    OverheadPackingTypeCode,
} from "@/app/constants";
import { OverheadService } from "@/app/services/overhead.service";

export type ConfigBuilder = {
    typeCode(value: string): ConfigBuilder;
    structureType(value: string): ConfigBuilder;
    qty(value: number): ConfigBuilder;
    withTitle(): ConfigBuilder;
    withUnit(): ConfigBuilder;
    build(): IComponentConfig;
};

const createDrawingOverheadConfig = (areaFt: number): OverheadConfig => {
    const qty = areaFt === 0 ? 0 : OverheadService.calcOhDrawingQty(areaFt);

    return createOverheadConfig()
        .structureType(OverheadDrawingCode)
        .typeCode(OverheadDrawingTypeCode)
        .withTitle()
        .withUnit()
        .qty(qty)
        .build() as OverheadConfig;
};

const createCuttingMaterialOverheadConfig = (
    areaFt: number,
): OverheadConfig => {
    const qty = areaFt === 0 ? 0 : OverheadService.calcOhCuttingQty(areaFt);

    return createOverheadConfig()
        .structureType(OverheadCuttingMaterialCode)
        .typeCode(OverheadCuttingMaterialTypeCode)
        .withTitle()
        .withUnit()
        .qty(qty)
        .build() as OverheadConfig;
};

const createPackingOverheadConfig = (areaFt: number): OverheadConfig => {
    const qty = areaFt === 0 ? 0 : OverheadService.calcOhPackaingQty(areaFt);

    return createOverheadConfig()
        .structureType(OverheadPackingCode)
        .typeCode(OverheadPackingTypeCode)
        .withTitle()
        .withUnit()
        .qty(qty)
        .build() as OverheadConfig;
};

const createGasParkingTollWithDeliveryOverheadConfig = (
    areaFt: number,
): OverheadConfig => {
    const qty = areaFt === 0 ? 0 : OverheadService.calcOhPackaingQty(areaFt);

    return createOverheadConfig()
        .structureType(OverheadGasParkingTollCode)
        .typeCode(OverheadGasParkingTollTypeCode)
        .withTitle()
        .withUnit()
        .qty(qty)
        .build() as OverheadConfig;
};

const createGasParkingTollWithoutDeliveryOverheadConfig = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    areaFt: number,
): OverheadConfig => {
    return createOverheadConfig()
        .structureType(OverheadGasParkingTollCode)
        .typeCode(OverheadGasParkingTollTypeCode)
        .withTitle()
        .withUnit()
        .qty(0)
        .build() as OverheadConfig;
};

const createMeasurementsWithInstallationOverheadConfig = (
    areaFt: number,
): OverheadConfig => {
    const qty = areaFt === 0 ? 0 : OverheadService.calcOhParkingQty(areaFt);

    return createOverheadConfig()
        .structureType(OverheadMeasurementsCode)
        .typeCode(OverheadMeasurementsTypeCode)
        .withTitle()
        .withUnit()
        .qty(qty)
        .build() as OverheadConfig;
};

const createMeasurementsWithoutInstallationOverheadConfig =
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (areaFt: number): OverheadConfig => {
        return createOverheadConfig()
            .structureType(OverheadMeasurementsCode)
            .typeCode(OverheadMeasurementsTypeCode)
            .withTitle()
            .withUnit()
            .qty(0)
            .build() as OverheadConfig;
    };

export const OverheadItemsDefaultConfigs = (areaFt: number) => [
    createDrawingOverheadConfig(areaFt),
    createCuttingMaterialOverheadConfig(areaFt),
    createPackingOverheadConfig(areaFt),
    createGasParkingTollWithDeliveryOverheadConfig(areaFt),
    createMeasurementsWithInstallationOverheadConfig(areaFt),
];

export const OverheadItemsWithoutDeliveryConfigs = (areaFt: number) => [
    createDrawingOverheadConfig(areaFt),
    createCuttingMaterialOverheadConfig(areaFt),
    createPackingOverheadConfig(areaFt),
    createGasParkingTollWithoutDeliveryOverheadConfig(areaFt),
    createMeasurementsWithInstallationOverheadConfig(areaFt),
];

export const OverheadItemsWithoutInstallationConfigs = (areaFt: number) => [
    createDrawingOverheadConfig(areaFt),
    createCuttingMaterialOverheadConfig(areaFt),
    createPackingOverheadConfig(areaFt),
    createGasParkingTollWithDeliveryOverheadConfig(areaFt),
    createMeasurementsWithoutInstallationOverheadConfig(areaFt),
];

export const OverheadItemsWithoutDeliveryAndInstallationConfigs = (
    areaFt: number,
) => [
    createDrawingOverheadConfig(areaFt),
    createCuttingMaterialOverheadConfig(areaFt),
    createPackingOverheadConfig(areaFt),
    createGasParkingTollWithoutDeliveryOverheadConfig(areaFt),
    createMeasurementsWithoutInstallationOverheadConfig(areaFt),
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const OverheadItemsWithoutQtyConfigs = (areaFt: number) => [
    createDrawingOverheadConfig(0),
    createCuttingMaterialOverheadConfig(0),
    createPackingOverheadConfig(0),
    createGasParkingTollWithDeliveryOverheadConfig(0),
    createMeasurementsWithInstallationOverheadConfig(0),
];

const createOverheadConfig = (): ConfigBuilder => {
    const dictStore = useDictStore();
    const additionalCostTypes = dictStore.getAdditionalCosts("overhead");

    const config = {
        title: "",
        typeCode: "",
        structureType: "",
        unit: "",
        qty: 1,
    };

    const builder = {
        typeCode(value: string) {
            config.typeCode = value;
            return builder;
        },
        structureType(value: string) {
            config.structureType = value;
            return builder;
        },
        withTitle() {
            const title = getFromDictByCode(
                additionalCostTypes,
                config.typeCode,
            )?.title;

            config.title = title || "";
            return builder;
        },
        withUnit() {
            const unit = getFromDictByCode(additionalCostTypes, config.typeCode)
                ?.unit;

            config.unit = unit || "";
            return builder;
        },
        qty(value: number) {
            config.qty = value;
            return builder;
        },
        build() {
            return config;
        },
    };

    return builder;
};
