<template>
  <li v-if="line.type === 'keyval'" v-bind:key="line.code">
    {{ line.title }}: {{ line.value }}
  </li>

  <li v-if="line.type === 'empty'" v-bind:key="line.code"></li>

  <li v-if="line.type === 'qtysum'" class="step__accordion-block-item">
    <div class="step__accordion-block-left">
      <div class="step__accordion-block-dot"></div>
      <h3>{{ line.title }}</h3>
    </div>
    <div class="step__accordion-block-container">
      <div class="step__accordion-block-count">
        <span>{{ line.qty }}</span>
      </div>
      <div class="step__accordion-block-right">
        <div>
          <span>{{ format(price) }}</span>
        </div>
        <div>
          <span>{{ format(sum) }}</span>
        </div>
      </div>
    </div>
  </li>
</template>

<script setup lang="ts">
import { defineProps, Ref, toRef } from "vue";
import { SummaryLine } from "@/models/summary";
import { usePriceStore } from "../../../stores/priceStore";

const sumKey = usePriceStore().data.currentSumKey;
const priceCode = usePriceStore().data.currentPriceKey;

const props = defineProps({
  summaryLine: {
    type: Object,
    required: true,
  },
});

const line: Ref<SummaryLine> = toRef(props.summaryLine);

const price = line.value && priceCode ? line.value[priceCode] : 0;
const sum = line.value && sumKey ? line.value[sumKey] : 0;

const format = (price: number) => {
  if (!price) {
    return "0.00";
  }

  return Number(price).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};
</script>

<style scoped></style>
