<template>
  <div class="step__block-partitionglass-block" v-if="hasHeader">
    <div></div>
    <div
      class="step__block-partitionglass-subblock step__block-partitionglass-subblock--unit"
    >
      <span>Q-ty</span>
    </div>
    <div class="step__block-partitionglass-subblock">
      <span>{{ dealTypeCode }}</span>
    </div>
    <div class="step__block-partitionglass-subblock">
      <span>SUM</span>
    </div>
  </div>
  <div class="step__block-partitionglass-block" v-if="resource">
    <div
      class="step__block-partitionglass-subblock step__block-partitionglass-subblock step__block-partitionglass-subblock--first"
    >
      <span>{{ title }}</span>
    </div>
    <div class="step__block-partitionglass-subblock">
      <input type="number" v-model.number="qty" min="0" max="99" step="1" />
    </div>
    <div class="step__block-partitionglass-subblock">
      <span>
        {{ resource.price.getSystemPrice(dealType).format() }}
      </span>
    </div>
    <div class="step__block-partitionglass-subblock">
      <span>
        {{ resource.sum.getSystemPrice(dealType).format() }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineModel } from "vue";
import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { IStructureComponent } from "@/app/domain";

type PriceTableViewProps = {
  title: string;
  resource: IStructureComponent;
  hasHeader?: boolean;
};

const estimateStore = useNewEstimateStore();
const dealType = computed(() => estimateStore.estimate.dealTypeCode);
const dealTypeCode = computed(() => dealType.value?.toUpperCase());

const qty = defineModel<number>("qty");

withDefaults(defineProps<PriceTableViewProps>(), { hasHeader: true });
</script>

<style lang="scss" scoped>
.step__block-partitionglass {
  padding: 15px 0;
}

.step__block-partitionglass-block {
  display: grid;
  grid-template-columns: 35% 150px repeat(2, 1fr);
  column-gap: 15px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  column-gap: 15px;
}

.step__block-partitionglass-block--selects {
  padding-top: 10px;
}

.step__block-partitionglass-subblock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.step__block-partitionglass-subblock--first {
  align-items: flex-start;
}

.step__block-partitionglass-subblock--unit {
  padding-right: 10px;
}

.step__block-partitionglass-subblock input {
  width: 50px;
  height: 35px;
  border-radius: 20px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
}

.step__block-partitionglass-total {
  padding: 15px 0;
  display: grid;
  row-gap: 15px;
}

.step__block-partitionglass-line {
  width: 100%;
  height: 1px;
  background-color: #d2d8e3;
}
</style>
