import { IComponentConfig } from "@/app/@types/base-component";
import { ReactiveComponent } from "./base-component";
import { ref, Ref } from "vue";
import {
    DeliveryCombinedCode,
    DeliveryDirectCode,
    DeliveryInstallationCode,
} from "@/app/constants";

export type DeliveryItemConfig = IComponentConfig & {
    unit: string;
};

export class Delivery extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "delivery" });
    }

    replaceDeliveryItems(deliveryItems: DeliveryItem[]) {
        this.replaceComponents("delivery-item", deliveryItems);
    }

    getDeliveryItems = () => {
        return this.components.filter(
            (component) => component.type === "delivery-item",
        );
    };

    getInstallation = () => {
        return this.components.find((component: unknown) =>
            (component as DeliveryItem).isInstallationDelivery(),
        );
    };
}

export class DeliveryItem extends ReactiveComponent {
    unit: Ref<string>;
    constructor(config: DeliveryItemConfig) {
        super({ ...config, type: "delivery-item" });

        this.unit = ref(config.unit || "");
    }

    isCombinedDelivery = () => {
        return this.structureType?.value === DeliveryCombinedCode;
    };

    isDirectDelivery = () => {
        return this.structureType?.value === DeliveryDirectCode;
    };

    isInstallationDelivery = () => {
        return this.structureType?.value === DeliveryInstallationCode;
    };
}
