<template>
  <div v-if="activeGlassPartition && activeGlassThinknessPartition">
    <div class="step__block-partitionglass-block">
      <div>
        <span>Glass sort:</span>
      </div>
      <div class="step__block-partitionglass-subblock">
        <span>Glass thickness:</span>
      </div>
      <div class="step__block-partitionglass-subblock">
        <span>Per sq.ft.</span>
      </div>
      <div class="step__block-partitionglass-subblock">
        <span>SUM</span>
      </div>
    </div>
    <div
      class="step__block-partitionglass-block step__block-partitionglass-block--selects"
    >
      <div>
        <GlassSortSelect v-model="activeGlassPartition.structureType" />
      </div>
      <div class="step__block-partitionglass-subblock">
        <GlassThinknessSelect
          v-model="activeGlassThinknessPartition.structureType"
        />
      </div>
      <div class="step__block-partitionglass-subblock">
        <PriceValue :price="activeGlassPartition.price" />
      </div>
      <div class="step__block-partitionglass-subblock">
        <PriceValue :price="activeGlassPartition.sum" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";

import { usePartitionStore } from "@/stores/new/partition.store";
import { PricingService } from "@/app/services/pricing.service";

import { GlassSortSelect, GlassThinknessSelect, PriceValue } from "@/shared";
import { useDoorsStore } from "@/stores/new/doors.store";
import { useOverdoorStore } from "@/stores/new/overdoor.store";

const partitionsStore = usePartitionStore();
const doorsStore = useDoorsStore();
const overdoorStore = useOverdoorStore();

const pricingService = new PricingService();

const activePartition = computed(() => partitionsStore.activePartitions);

const activeGlassPartition = computed(
  () => partitionsStore.activeGlassPartition,
);
const activeGlassThinknessPartition = computed(
  () => partitionsStore.activeGlassThinknessPartition,
);

watch(
  () => [
    activeGlassPartition.value?.structureType,
    activeGlassThinknessPartition.value?.structureType,
  ],
  () => {
    doorsStore.syncDoorGlassWithPartition();
    overdoorStore.syncOverdoorGlassWithPartition();
  },
);

watch(
  () => [
    activeGlassPartition.value?.structureType,
    activeGlassThinknessPartition.value?.structureType,
  ],
  () => {
    const price = pricingService.getPartitionGlassPrices();
    activeGlassPartition.value?.update({ price });
  },
  { immediate: true, deep: true },
);

watch(
  () => [activeGlassPartition.value?.price, activePartition.value?.totalAreaSq],
  () => {
    const sum = pricingService.calculatePartitionGlassPrice();
    activeGlassPartition.value?.update({ sum });
  },
  { immediate: true, deep: true },
);
</script>

<style lang="scss" scoped>
.step__block-partitionglass-subblock span {
  font-size: 14px !important;
  font-weight: normal !important;
}
.step__block-partitionglass {
  padding: 15px 0;
}

.step__block-partitionglass-block {
  display: grid;
  grid-template-columns: 40% 22% repeat(2, 1fr);
  column-gap: 15px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  column-gap: 15px;
}

.step__block-partitionglass-block--selects {
  padding-top: 10px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #d2d8e3;
}

.step__block-partitionglass-subblock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.step__block-partitionglass-subblock--first {
  align-items: flex-start;
}

.step__block-partitionglass-subblock--unit {
  padding-right: 10px;
}

.step__block-partitionglass-subblock input {
  width: 70px;
  height: 35px;
  border-radius: 20px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
}

.step__block-partitionglass-total {
  display: grid;
  row-gap: 15px;
}
</style>
