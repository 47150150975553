<template>
  <input
    v-if="user.isSalesEstimator && authEstimator != undefined"
    v-model="authEstimator.title"
  />

  <select-input-dict
    v-else
    v-model="estimatorCode"
    :select-list="estimators"
    :options="estimators"
    label="title"
  />
</template>

<script setup lang="ts">
import { computed, defineModel, watch } from "vue";

import SelectInputDict from "@/components/UI/SelectInputDict.vue";

import { useDictStore } from "@/stores/dictStore";
import { useUserStore } from "@/stores/userStore";

const dictStore = useDictStore();
const user = useUserStore();
const isSalesEstimator = computed(() => user.isSalesEstimator);

const estimators = dictStore.getEstimators();
const authEstimator = computed(
  () => estimators.filter((item) => item.code === user.userCode)[0],
);

const estimatorCode = defineModel<string>({
  default: "",
});

watch(
  () => isSalesEstimator,
  () => {
    if (user.isSalesEstimator && authEstimator.value != undefined) {
      estimatorCode.value = authEstimator.value.code;
    }
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped></style>
