import { Space } from "@/app/domain/entities/space";
import { createMountsTreviFrameConfig } from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";

const createAnyDoorsMountsTreviFrameConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);

    const qty = totalWidth * totalHeight;

    return createMountsTreviFrameConfig().qty(qty).build();
};

export const TreviAnyDoorsConfigs = (space: Space) => [
    createAnyDoorsMountsTreviFrameConfig(space),
];
