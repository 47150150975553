import { Space } from "@/app/domain/entities/space";
import {
    createMountsKompasHorizontalConfig,
    createMountsKompasVerticalConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";

const createSlidingDoorMountsKompasHorizontalConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorSections = 3;

    const qty = totalWidth * doorSections;

    return createMountsKompasHorizontalConfig().qty(qty).build();
};

const createSlidingDoorMountsKompasVerticalConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const totalWidth = MountsService.getTotalWidth(space);

    const doorSections = 3;
    const sides = 2;

    const sectionWidth = Math.round(totalWidth / doorSections) - 1;

    const qty = totalHeight * (sides + sectionWidth);

    return createMountsKompasVerticalConfig().qty(qty).build();
};

export const KompasNoDoorsConfigs = (space: Space) => [
    createSlidingDoorMountsKompasHorizontalConfig(space),
    createSlidingDoorMountsKompasVerticalConfig(space),
];
