<template>
  <span>{{ unit }}</span>
</template>

<script setup lang="ts">
import { getFromDictByCode, useDictStore } from "@/stores/dictStore";

type MountHardwareUnitProps = {
  typeCode: string | undefined;
};

const dictStore = useDictStore();
const props = defineProps<MountHardwareUnitProps>();

const unit = getFromDictByCode(
  dictStore.getHardwareTypes("mounts"),
  props.typeCode,
)?.unit;
</script>

<style lang="scss" scoped></style>
