import { CGF350Code, CGF400Code } from "../constants";

interface HeightRange {
    rangeMin: number;
    rangeMax: number;
    value: number;
}

interface SystemRanges {
    [key: string]: HeightRange[];
}

export class LineDividerCalculationService {
    private static readonly SYSTEM_RANGES: SystemRanges = {
        [CGF350Code]: [
            { rangeMin: -1, rangeMax: 3, value: 0 },
            { rangeMin: 3, rangeMax: 6, value: 1 },
            { rangeMin: 6, rangeMax: 7, value: 2 },
            { rangeMin: 7, rangeMax: 8, value: 2 },
            { rangeMin: 8, rangeMax: 9, value: 3 },
            { rangeMin: 9, rangeMax: 10, value: 3 },
            { rangeMin: 10, rangeMax: 11, value: 3 },
            { rangeMin: 11, rangeMax: 12, value: 4 },
        ],
        [CGF400Code]: [
            { rangeMin: -1, rangeMax: 3, value: 0 },
            { rangeMin: 3, rangeMax: 6, value: 1 },
            { rangeMin: 6, rangeMax: 7, value: 2 },
            { rangeMin: 7, rangeMax: 8, value: 2 },
            { rangeMin: 8, rangeMax: 9, value: 3 },
            { rangeMin: 9, rangeMax: 10, value: 3 },
            { rangeMin: 10, rangeMax: 11, value: 3 },
            { rangeMin: 11, rangeMax: 12, value: 4 },
        ],
    };

    private static readonly VERTICAL_DIVIDER_CONFIG = {
        [CGF350Code]: {
            minWidth: 3,
            divisionFactor: 3,
        },
        [CGF400Code]: {
            minWidth: 3,
            divisionFactor: 3,
        },
    };

    /**
     * Calculates the number of horizontal dividers based on the system type and height.
     *
     * @param {string} systemType - The type of the system for which dividers are being calculated.
     * @param {number} height - The height for which the dividers need to be calculated.
     * @returns {number} - The number of horizontal dividers. Returns 0 if the system type is not found or an error occurs.
     *
     * @throws {Error} - Throws an error if the input validation fails.
     */
    static calculateHorizontalDividers(
        systemType: string,
        height: number,
    ): number {
        try {
            this.validateInputs(systemType, height);

            const ranges = this.SYSTEM_RANGES[systemType];
            if (!ranges) {
                return 0;
            }

            const range = this.findRangeForHeight(ranges, height);
            return range?.value ?? 0;
        } catch (error) {
            this.handleError(
                "Error calculating horizontal dividers",
                error,
                height,
            );
            return 0;
        }
    }

    /**
     * Calculates the number of vertical dividers based on the system type and width.
     *
     * @param systemType - The type of the system for which the dividers are being calculated.
     * @param width - The width of the area where the dividers will be placed.
     * @returns The number of vertical dividers, or 0 if the configuration is not found or the width is less than the minimum width.
     * @throws Will throw an error if the input validation fails.
     */
    static calculateVerticalDividers(
        systemType: string,
        width: number,
    ): number {
        try {
            this.validateInputs(systemType, width);

            const config = this.VERTICAL_DIVIDER_CONFIG[systemType];

            if (!config) {
                return 0;
            }

            if (width < config.minWidth) {
                return 0;
            }

            return Math.round(width / config.divisionFactor - 1);
        } catch (error) {
            this.handleError(
                "Error calculating vertical dividers",
                error,
                width,
            );
            return 0;
        }
    }

    private static validateInputs(systemType: string, size: number): void {
        if (!systemType || typeof size !== "number") {
            throw new Error("Invalid input parameters");
        }

        if (size < 0) {
            throw new Error("Height cannot be negative");
        }

        if (!this.VERTICAL_DIVIDER_CONFIG[systemType]) {
            throw new Error(`Unsupported system type: ${systemType}`);
        }
    }

    private static findRangeForHeight(
        ranges: HeightRange[],
        height: number,
    ): HeightRange | undefined {
        return ranges.find(
            (range) => range.rangeMin < height && range.rangeMax >= height,
        );
    }

    private static handleError(
        message: string,
        error: unknown,
        height: number,
    ): void {
        console.error(`${message}:`, error);
        console.error(`Height ${height} is out of defined ranges`);
    }
}
