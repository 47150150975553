<template>
  <div class="step__block-dimensions" v-if="dimensions">
    <div class="step__block-dimensions-block">
      <div class="step__block-dimensions-left">
        <div></div>
        <div class="step__block-dimensions-units">
          <div>
            <span>Ft</span>
          </div>
          <div>
            <span>Inch</span>
          </div>
          <div>
            <span>Fractions</span>
          </div>
        </div>
      </div>
      <div class="step__block-dimensions-right">
        <div>
          <span>Size, sq.ft:</span>
        </div>
      </div>
    </div>
    <div>
      <div
        class="step__block-dimensions-block step__block-dimensions-block--table"
      >
        <div class="step__block-dimensions-left">
          <div class="step__block-dimensions-name">
            <span>Width</span>
          </div>
          <div class="step__block-dimensions-container">
            <div>
              <input-num v-model.number="dimensions.width.ft" />
            </div>
            <div>
              <input-num v-model.number="dimensions.width.inch" />
            </div>
            <div>
              <input-num v-model.number="dimensions.width.fractionDividend" />
            </div>
            <div>
              <input-num v-model.number="dimensions.width.fractionDivisor" />
            </div>
          </div>
        </div>
        <div class="step__block-dimensions-right">
          <div>
            <span>{{ dimensions.width.getLength().toFt().format() }}</span>
          </div>
          <div></div>
        </div>
      </div>
      <div
        class="step__block-dimensions-block step__block-dimensions-block--table"
      >
        <div class="step__block-dimensions-left">
          <div class="step__block-dimensions-name">
            <span>Height</span>
          </div>
          <div class="step__block-dimensions-container">
            <div>
              <input-num v-model.number="dimensions.height.ft" />
            </div>
            <div>
              <input-num v-model.number="dimensions.height.inch" />
            </div>
            <div>
              <input-num v-model.number="dimensions.height.fractionDividend" />
            </div>
            <div>
              <input-num v-model.number="dimensions.height.fractionDivisor" />
            </div>
          </div>
        </div>
        <div class="step__block-dimensions-right">
          <div>
            <span>{{ dimensions.height.getLength().toFt().format() }}</span>
          </div>
          <div>
            <span>
              {{ activePartition?.totalAreaSq.toSquareFt().format() }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineModel, watch } from "vue";

import InputNum from "@/components/UI/InputNum.vue";

import { usePartitionStore } from "@/stores/new/partition.store";
import { ReactiveComponentDimensions } from "@/app/domain";

const partitionStore = usePartitionStore();
const activePartition = computed(() => partitionStore.activePartitions);
const dimensions = defineModel<ReactiveComponentDimensions>();

watch(
  () => activePartition.value?.dimensions,
  () => {
    activePartition.value?.calculateTotalAreaSq();
  },
  { deep: true, immediate: true },
);
</script>

<style lang="scss" scoped>
.step__block-dimensions {
  padding: 15px 0;
}

.step__block-dimensions-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step__block-dimensions-right {
  display: flex;
  column-gap: 92px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-dimensions-right div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.step__block-dimensions-right div:last-child {
  width: 65px;
}

.step__block-dimensions {
  padding: 15px 0;
}

.step__block-dimensions-left {
  display: grid;
  grid-template-columns: minmax(0, 46px) repeat(2, 1fr);
  column-gap: 10px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-dimensions-units {
  display: grid;
  grid-template-columns: repeat(3, 60px);
  padding-bottom: 11px;
}

.step__block-dimensions-units div {
  padding-left: 11px;
}

.step__block-dimensions-container {
  display: grid;
  grid-template-columns: repeat(4, 60px);
}

.step__block-dimensions-container div {
  background-color: #fff;
  height: 35px;
}

.step__block-dimensions-container div input {
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 7px;
}

.step__block-dimensions-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.step__block-dimensions-block--table:first-child
  .step__block-dimensions-container
  div {
  border-bottom: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table .step__block-dimensions-container div {
  border-right: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table
  .step__block-dimensions-container
  div:last-child {
  border-right: none;
}
</style>
