<template>
  <SelectInput
    v-model="title"
    :select-list="spaceDescriptions"
    :select-first="true"
  />
</template>

<script setup lang="ts">
import { defineModel } from "vue";
import SelectInput from "@/components/UI/SelectInput.vue";

import { useDictStore } from "@/stores/dictStore";

const dictStore = useDictStore();
const spaceDescriptions = dictStore.getSpaceDescriptions();

const title = defineModel<string>({ default: "" });
</script>

<style lang="scss" scoped></style>
