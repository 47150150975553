import { Space } from "@/app/domain/entities/space";
import {
    createMountsCGFBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsCGFTopFrameSortConfig,
    createMountsCGFSideFrameSortConfig,
    createMountsMullionsHorizontalConfig,
    createMountsMullionsVerticalConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";
import { LineDividerCalculationService } from "@/app/services/line-dividers.service";
import { CGF400Code } from "@/app/constants";

const createSlidingDoorMountsCGFTopFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(space);
    return createMountsCGFTopFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFSideFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const sides = 2;

    const qty = totalHeight * sides;

    return createMountsCGFSideFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getTotalWidth(space);

    const qty = totalWidth - doorWidth;

    return createMountsCGFBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);

    const sides = 2;

    const horizontalGaskets = totalWidth * sides;
    const verticalGaskets = totalHeight * sides;

    const qty = (horizontalGaskets + verticalGaskets) * sides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsHorizontalConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);
    const sides = 2;

    const horizontalLineQty =
        LineDividerCalculationService.calculateHorizontalDividers(
            CGF400Code,
            totalHeight,
        );

    const qty = totalWidth * horizontalLineQty * sides;

    return createMountsMullionsHorizontalConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsVerticalConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);
    const isDoubleDoor = MountsService.isForDoubleDoor(space);

    const totalSectios = 3;
    const totalSides = 2;
    const doorSections = isDoubleDoor ? 3 : 2;
    const horizontalLineQty = totalWidth / totalSectios - doorSections;

    const qty = horizontalLineQty * totalSides * totalHeight;

    return createMountsMullionsVerticalConfig().qty(qty).build();
};

export const CGF400NoDoorsConfigs = (space: Space) => [
    createSlidingDoorMountsCGFSideFrameSortConfig(space),
    createSlidingDoorMountsCGFTopFrameSortConfig(space),
    createSlidingDoorMountsCGFBottomFrameSortConfig(space),
    createSlidingDoorMountsGlazingGasketConfig(space),
    createSlidingDoorMountsMullionsHorizontalConfig(space),
    createSlidingDoorMountsMullionsVerticalConfig(space),
];
