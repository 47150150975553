import { computed } from "vue";

import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { useDoorsStore } from "@/stores/new/doors.store";

import {
    DoubleSwingDoorsCode,
    KompasDblCode,
    KompasDoubleFramedDoorCode,
    KompasSglCode,
    KompasSingleFramedDoorCode,
    SingleSwingDoorCode,
} from "../constants";
import { DoorGlassThinknessSelectionStrategies } from "../domain/strategies";
import { Dictionary } from "@/models/dictionary";

const {
    KompasSystemDoorGlassThicknessSelectionStrategy,
    KompasSystemSwingDoorGlassThicknessSelectionStrategy,
    DefaultGlassThicknessSelectionStrategy,
} = DoorGlassThinknessSelectionStrategies;

export class GlassThinknessService {
    private estimateStore = useNewEstimateStore();
    private doorStore = useDoorsStore();

    getDoorGlassThinkness(): Dictionary[] {
        const installedSystemCode = computed(
            () => this.estimateStore.estimate.installedSystemCode,
        );
        const doorType = computed(
            () => this.doorStore.activeDoor?.structureType,
        );

        const strategies = {
            [KompasDblCode]: {
                [KompasSingleFramedDoorCode]:
                    KompasSystemDoorGlassThicknessSelectionStrategy,
                [KompasDoubleFramedDoorCode]:
                    KompasSystemDoorGlassThicknessSelectionStrategy,
                [SingleSwingDoorCode]:
                    KompasSystemSwingDoorGlassThicknessSelectionStrategy,
                [DoubleSwingDoorsCode]:
                    KompasSystemSwingDoorGlassThicknessSelectionStrategy,
            },
            [KompasSglCode]: {
                [KompasSingleFramedDoorCode]:
                    KompasSystemDoorGlassThicknessSelectionStrategy,
                [KompasDoubleFramedDoorCode]:
                    KompasSystemDoorGlassThicknessSelectionStrategy,
                [SingleSwingDoorCode]:
                    KompasSystemSwingDoorGlassThicknessSelectionStrategy,
                [DoubleSwingDoorsCode]:
                    KompasSystemSwingDoorGlassThicknessSelectionStrategy,
            },
        };

        const strategy =
            strategies[installedSystemCode.value]?.[doorType.value] ||
            DefaultGlassThicknessSelectionStrategy;

        return new strategy().select();
    }

    getDefaultGlassThinkness(): Dictionary[] {
        return new DefaultGlassThicknessSelectionStrategy().select();
    }
}
