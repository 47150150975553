import {
    CGFSystemCodes,
    CGS200Code,
    KompasDblCode,
    KompasSglCode,
    SteelSystemCodes,
} from "../constants";

export const isKompasDlb = (systemCode: string) => systemCode === KompasDblCode;
export const isKompasSgl = (systemCode: string) => systemCode === KompasSglCode;

export const isKompasSystem = (systemCode: string) =>
    isKompasDlb(systemCode) || isKompasSgl(systemCode);

export const isCGFSystem = (systemCode: string) =>
    CGFSystemCodes.includes(systemCode);

export const isCGSSystem = (systemCode: string) => systemCode === CGS200Code;

export const isSteelSystem = (systeCode: string) =>
    SteelSystemCodes.includes(systeCode);
