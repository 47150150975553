<template>
  <StepTotalPrice
    v-if="activeOverdoor"
    title="Page total"
    :price="activeOverdoor.sum"
  />
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { StepTotalPrice } from "@/shared";
import { useOverdoorStore } from "@/stores/new/overdoor.store";
import { PricingService } from "@/app/services/pricing.service";

const overdoorStore = useOverdoorStore();
const pricingService = new PricingService();
const activeOverdoor = computed(() => overdoorStore.activeOverdoor);

watch(
  () => activeOverdoor.value?.components,
  () => {
    const sum = pricingService.calculateOverdoorTotalPrice();
    activeOverdoor.value?.update({ sum });
  },
  { immediate: true, deep: true },
);
</script>
