export const KompasDblCode = "kompas-dbl";
export const KompasSglCode = "kompas-sgl";
export const RomanoCode = "romano";
export const TreviCode = "trevi";

export const CGF300Code = "cgf300";
export const CGF350Code = "cgf350";
export const CGF400Code = "cgf400";
export const CGS200Code = "cgs200";

export const CGFSystemCodes = [CGF300Code, CGF350Code, CGF400Code];
export const NonSteelSystemCodes = [
    CGS200Code,
    CGF300Code,
    CGF350Code,
    CGF400Code,
    KompasDblCode,
    KompasSglCode,
];
export const SteelSystemCodes = [TreviCode, RomanoCode];

export const OutOfSquareCode = "outsq-01";
export const CostDealType = "cost";
export const B2BDealType = "b2b";
export const B2CDealType = "b2c";

export const DividerPartitionCode = "div";
export const GlassPartitionCode = "fxp";
export const DoorPartitionCode = "dr";
export const MultiFoldingPartitionCode = "foldp";
export const MultiSlidingPartitionCode = "foldp";

export const GlassSortCode = "clear";
export const GlassSortClearCode = "clear";
export const GlassThicknessCode = "1-4";
export const GlassThicknessCode1_4 = "1-4";
export const GlassThicknessCode1_2 = "1-2";
export const GlassThicknessCode3_8 = "3-8";

export const PartitionGlassCode = "fxp";

// Door Types
export const DoorNoneCode = "dr-none";
export const SingleSwingDoorCode = "s-sw";
export const SingleSlidingDoorsCode = "s-sl";
export const KompasSingleFramedDoorCode = "kmp-s";
export const SteelSingleSwingDoorCode = "st-s-sw";
export const SteelSingleSlidingDoorCode = "st-s-sl";
export const SteelArchSingleSwingDoorCode = "st-s-ar";

export const DoubleSwingDoorsCode = "d-sw";
export const DoubleSlidingDoorsCode = "d-sl";
export const KompasDoubleFramedDoorCode = "kmp-d";
export const SteelDoubleSwingDoorsCode = "st-d-sw";
export const SteelDoubleSlidingDoorsCode = "st-d-sl";
export const SteelArchDoubleSwingDoorsCode = "st-d-ar";

export const DividerCode = "div";

export const SwingDoors = [
    SingleSwingDoorCode,
    DoubleSwingDoorsCode,
    SteelSingleSwingDoorCode,
    SteelDoubleSwingDoorsCode,
    SteelArchSingleSwingDoorCode,
    SteelArchDoubleSwingDoorsCode,
];

export const SlidingDoors = [
    SingleSlidingDoorsCode,
    DoubleSlidingDoorsCode,
    SteelSingleSlidingDoorCode,
    SteelDoubleSlidingDoorsCode,
];

export const SingleDoors = [
    SingleSwingDoorCode,
    SingleSlidingDoorsCode,
    KompasSingleFramedDoorCode,
    SteelSingleSwingDoorCode,
    SteelSingleSlidingDoorCode,
    SteelArchSingleSwingDoorCode,
];

export const DoubleDoors = [
    DoubleSwingDoorsCode,
    DoubleSlidingDoorsCode,
    KompasDoubleFramedDoorCode,
    SteelDoubleSwingDoorsCode,
    SteelDoubleSlidingDoorsCode,
    SteelArchDoubleSwingDoorsCode,
];

export const SingleSwingDoors = [
    SingleSwingDoorCode,
    SteelSingleSwingDoorCode,
    SteelArchSingleSwingDoorCode,
];

export const SingleSlidingDoors = [
    SingleSlidingDoorsCode,
    SteelSingleSlidingDoorCode,
];

export const DoubleSwingDoors = [
    DoubleSwingDoorsCode,
    SteelDoubleSwingDoorsCode,
    SteelArchDoubleSwingDoorsCode,
];

export const DoubleSlidingDoors = [
    DoubleSlidingDoorsCode,
    SteelDoubleSlidingDoorsCode,
];

export const KompasDoors = [
    KompasDoubleFramedDoorCode,
    KompasSingleFramedDoorCode,
];

export const NoDoors = [DoorNoneCode, DividerCode];

// Fillers

export const OverdoorNoneCode = "none";
export const OverdoorFillerCode = "filler";
export const OverdoorTransomCode = "transom";

export const GlassMaterialCode = "glass";
export const SheetMaterialCode = "aluminum";

export const OverdoorAssetsCode = "assets-01";

export const MountPerimeterCode = "mnt-perimeter";
export const MountTopBottomCode = "mnt-topbottom";
export const NoMountCode = "mnt-none";

export const OverheadDrawingCode = "oh-dr-01";
export const OverheadDrawingTypeCode = "oh-dr";

export const OverheadCuttingMaterialCode = "oh-cut-01";
export const OverheadCuttingMaterialTypeCode = "oh-cut";

export const OverheadPackingCode = "oh-pack-01";
export const OverheadPackingTypeCode = "oh-pack";

export const OverheadGasParkingTollCode = "oh-gas-01";
export const OverheadGasParkingTollTypeCode = "oh-gas";

export const OverheadMeasurementsCode = "oh-msr-01";
export const OverheadMeasurementsTypeCode = "oh-msr";

export const DeliveryCombinedTypeCode = "dlvr-combine";
export const DeliveryDirectTypeCode = "dlvr-direct";
export const DeliveryInstallationTypeCode = "dlvr-install";

export const DeliveryCombinedCode = "dlvr-combine-01";
export const DeliveryDirectCode = "dlvr-direct-01";
export const DeliveryInstallationCode = "dlvr-install-01";
