<template>
  <button @click="onButtonClick" class="step__block-extra-add">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
    >
      <path
        d="M7 0.5V12.5M1 6.5H13"
        stroke="#404D63"
        stroke-opacity="0.6"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
    </svg>
    <span>Add position</span>
  </button>
</template>

<script setup lang="ts">
import { useExtraStore } from "@/stores/new/extra.store";

const extraStore = useExtraStore();

const onButtonClick = () => {
  extraStore.addExtraItem();
};
</script>

<style lang="scss" scoped></style>
