import { getFromDictByCode, useDictStore } from "@/stores/dictStore";

import { PricingStrategy, PriceResult } from "../base/pricing.strategy";

export class ExtraPricingStrategy extends PricingStrategy {
    private dictStore = useDictStore();

    public getPrices(...args: unknown[]): PriceResult {
        const [extraValueCode] = args as string[];

        const pricesFromDict = getFromDictByCode(
            this.dictStore.getHardwareValues(),
            extraValueCode,
        );

        if (!this.validatePricesFromDict(pricesFromDict)) {
            return this.getDefaultPrices();
        }

        return {
            price: pricesFromDict.price!,
            priceB2C: pricesFromDict.priceB2C!,
            priceB2B: pricesFromDict.priceB2B!,
        };
    }
}
