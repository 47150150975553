<template>
  <div class="checkbox-block" @click="toggle">
    <svg
      v-show="checked === true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M20 6 9 17l-5-5" />
    </svg>
  </div>
</template>

<script setup lang="ts">
import { defineModel } from "vue";

const checked = defineModel<boolean>();

const toggle = () => {
  checked.value = !checked.value;
};
</script>

<style scoped lang="scss">
.checkbox-block {
  width: 22px;
  height: 22px;
  border: 1px solid #d2d8e2;
  background-color: #fff;
  padding: 2px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
