import http from "@/utils/http.ts";
import { useEstimateStore } from "../stores/estimateStore";
import { de } from "@faker-js/faker";

async function getCsrfCooke() {
    try {
        const { data } = await http.get("/sanctum/csrf-cookie"); //.catch((err)=>{console.log(err)});
        localStorage.setItem("XSRF-TOKEN", data);
        // console.log('getCsrfCooke', data);
    } catch (error) {
        console.log(error);
    }
}

export async function useLogin(email: string, password: string) {
    //    getCsrfCooke();

    return await http.post("/auth/login", { email: email, password: password });
    // return await http.post<{
    //     code: number;
    //     cookie: string;
    //     token: string;
    // }>("login/", {login: login, password: password});
}

export async function useLogout() {
    return await http.post("/auth/logout");
}

export async function useLoginStatus() {
    //getCsrfCooke();
    return await http.get("/auth/status");
}

// export async function getEstimatesListPaged(
//     pageNo: number,
//     filter: string[],
//     sort: string[],
// ) {
//     return await http.get<{
//         items: any[],
//         meta: any[],
//     }>(`/estimates`, {page:pageNo})
// }

export async function getEstimate(estimateCode: string) {
    const resp = await http.get(`/api/estimates/${estimateCode}`);
    return JSON.parse(resp.data);
}

export async function postEstimate(
    estimate: object,
    estimateCode,
    isNew: boolean = false,
): any {
    let resp = null;
    try {
        console.debug("api save start");
        if (isNew) {
            // create
            resp = await http.post(`/api/estimates`, estimate);
        } else {
            // update
            resp = await http.put(`/api/estimates/${estimateCode}`, estimate);
        }
        console.debug("api save finish");
        return resp?.data;
    } catch (error) {
        console.error(error);
    }
    return null;
}

export async function postHubspot(estimateCode: string): any {
    try {
        const resp = await http.post(
            `/api/estimates/${estimateCode}/hubspot`,
            estimateCode,
        );
        return resp?.data;
    } catch (error) {
        console.error(error);
    }
    return null;
}

export async function useDictSaver(name: string, data: object[]): any {
    let resp = null;
    try {
        resp = await http.put(`/api/dicts/${name}`, data);
        return resp?.data;
    } catch (error) {
        console.error(error);
    }
    return null;
}

export async function useDictLoaderByDate(name: string, date: string): any {
    let resp = null;
    try {
        resp = await http.get(`/api/dicts-by-date/${name}/${date}`);
        return resp?.data;
    } catch (error) {
        console.error(error);
    }
    return null;
}

export async function useDictLoader(name: string): any {
    const estimateStore = useEstimateStore();
    if (estimateStore?.estimate?.info?.priceDateFormated) {
        return useDictLoaderByDate(
            name,
            estimateStore?.estimate?.info?.priceDateFormated,
        );
    }
    let resp = null;
    try {
        resp = await http.get(`/api/dicts/${name}`);
        return resp?.data;
    } catch (error) {
        console.error(error);
    }
    return null;
}

export async function useDiscount(): any {
    let resp = null;
    try {
        resp = await http.get(`/api/discount`);
        return resp;
    } catch (error) {
        console.error(error);
    }

    return null;
}

export async function useUsersLoader(role: string): any {
    if (!["estimators", "managers"].includes(role)) {
        console.error(`dict ${role} dosen't exist `);
        return null;
    }
    let resp = null;
    try {
        resp = await http.get(`/api/${role}`);
        return resp?.data;
    } catch (error) {
        console.error(error);
    }
    return null;
}

export async function useClientsDictLoader(): any {
    let resp = null;
    try {
        resp = await http.get(`/api/clients`);
        return resp?.data;
    } catch (error) {
        console.error(error);
    }
    return null;
}
