import { reactive, ref, Ref, toRef, UnwrapNestedRefs } from "vue";
import { ReactiveComponentDimensions } from "./dimensions";
import { UpdatableComponent } from "./updatable-compontent";
import { Pricing } from "./pricing";

import { generateCode } from "@/utils/common";
import * as BaseComponent from "@/app/@types/base-component";
import * as Dimensions from "@/app/@types/dimensions";

export const COMPONENT_TYPE = Symbol("componentType");

export class ReactiveComponent
    extends UpdatableComponent
    implements BaseComponent.IStructureComponent
{
    id: Ref<string>;
    code: Ref<string>;
    qty: Ref<number>;
    type: Ref<string>;
    title?: Ref<string>;
    structureType?: Ref<string>;
    typeCode?: Ref<string>;
    parent: Ref<BaseComponent.IStructureComponent | null>;

    price: UnwrapNestedRefs<Pricing>;
    sum: UnwrapNestedRefs<Pricing>;

    components: UnwrapNestedRefs<BaseComponent.IStructureComponent[]>;
    dimensions: UnwrapNestedRefs<Dimensions.ComponentDimensions>;

    [COMPONENT_TYPE]: Ref<string>;

    constructor(config: BaseComponent.IComponentConfig) {
        super();
        this.id = ref(generateCode());
        this.code = ref(generateCode());
        this.structureType = ref(config.structureType || "");
        this.type = ref(config.type || "");

        this.typeCode = ref(config.typeCode || "");
        this.qty = ref(config.qty || 0);

        this.title = ref(config.title || "");
        this.components = reactive([]);
        this.dimensions = reactive(
            new ReactiveComponentDimensions(config.dimensions),
        );
        this.price = reactive(
            new Pricing(
                this.normalizePrice(config.price?.price),
                this.normalizePrice(config.price?.priceB2C),
                this.normalizePrice(config.price?.priceB2B),
            ),
        );
        this.sum = reactive(
            new Pricing(
                this.normalizePrice(config.sum?.price),
                this.normalizePrice(config.sum?.priceB2C),
                this.normalizePrice(config.sum?.priceB2B),
            ),
        );
        this.parent = ref(null);

        this[COMPONENT_TYPE] = ref(this.getComponentTypeFromClass());
    }

    private getComponentTypeFromClass() {
        return this.constructor.name.toLowerCase();
    }

    /**
     * Retrieves the type of the component.
     *
     * @returns {string} The type of the component.
     */
    getComponentType = (): string => {
        return this[COMPONENT_TYPE].value;
    };

    /**
     * Retrieves the parent component of the current component.
     *
     * @returns {BaseComponent.IStructureComponent | null} The parent component if it exists, otherwise null.
     */

    getParent = (): BaseComponent.IStructureComponent | null => {
        return this.parent.value;
    };

    /**
     * Sets the parent component for the current component.
     *
     * @param parent - The parent component to be set. It can be an instance of `BaseComponent.IStructureComponent` or `null`.
     */
    setParent(parent: BaseComponent.IStructureComponent | null): void {
        this.parent.value = parent;
    }

    /**
     * Replaces components of a specified type with new components.
     *
     * @param type - The type of components to be replaced.
     * @param newComponents - An array of new components to be added.
     *
     * @example
     * ```typescript
     * const newComponents: BaseComponent.IStructureComponent[] = [
     *     { type: 'newType', ... },
     *     { type: 'newType', ... }
     * ];
     * replaceComponents('oldType', newComponents);
     * ```
     */

    replaceComponents = (
        type: string,
        newComponents: BaseComponent.IStructureComponent[],
    ) => {
        this.components = this.components.filter(
            (component) => component.type !== type,
        );

        this.addManyComponents(newComponents);
    };

    /**
     * Adds a component to the current component's list of components.
     *
     * @param component - The component to be added. It should implement the `BaseComponent.IStructureComponent` interface.
     *
     * @remarks
     * This method casts the provided component to a `ReactiveComponent` and sets its parent to the current instance.
     * The component is then added to the `components` array of the current instance.
     */
    addComponent(component: BaseComponent.IStructureComponent) {
        if (!component) {
            return;
        }
        (component as ReactiveComponent).parent.value = this;
        this.components = [...this.components, toRef(component).value];
    }

    /**
     * Adds multiple components to the current component.
     *
     * @param components - An array of components to be added. Each component should adhere to the `BaseComponent.IStructureComponent` interface.
     */
    addManyComponents(components: BaseComponent.IStructureComponent[]) {
        components?.forEach((c) => this.addComponent(c));
    }

    /**
     * Removes a component from the components array by its ID.
     *
     * @param id - The unique identifier of the component to be removed.
     */
    removeComponent = (
        id: string,
        type: string,
    ): BaseComponent.IStructureComponent[] => {
        const filteredComponents = this.components.filter(
            (c) => c.type !== type && c.id !== id,
        );

        this.components.length = 0;

        filteredComponents.forEach((component) => {
            this.components.push(component);
        });

        return filteredComponents as unknown as BaseComponent.IStructureComponent[];
    };

    /**
     * Retrieves components of a specific type from the list of components.
     *
     * @param type - The type of components to filter by.
     * @returns An array of components that match the specified type.
     */
    getComponentsByType(
        type: string,
    ): UnwrapNestedRefs<BaseComponent.IStructureComponent[]> {
        return this.components.filter((c) => c.type === type);
    }

    serialize = () => {
        return {
            ...this.intanceToPlainObject(this),
            parent: this.intanceToPlainObject(this.parent.value),
            components: this.components.map(
                (component) => component?.serialize(),
            ),
        };
    };

    private normalizePrice = (price: any) => {
        return typeof price === "string" ? parseFloat(price) : price;
    };

    private intanceToPlainObject = (
        instance: BaseComponent.IStructureComponent | null,
    ) => {
        if (!instance) {
            return {};
        }

        return {
            id: instance.id.value,
            code: instance.code.value,
            type: instance.type.value,
            structureType: instance.structureType?.value || "",
            typeCode: instance.typeCode?.value || "",
            title: instance.title?.value || "",
            qty: instance.qty.value,
            price: {
                price: instance.price.price,
                priceB2B: instance.price.priceB2B,
                priceB2C: instance.price.priceB2C,
            },
            sum: {
                price: instance.price.price,
                priceB2B: instance.price.priceB2B,
                priceB2C: instance.price.priceB2C,
            },
            dimensions: instance.dimensions.serialize(),
        };
    };
}
