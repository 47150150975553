<template>
  <StepTotalPrice title="Page total" :price="activeMount?.sum" />
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import StepTotalPrice from "@/shared/ui/StepTotalPrice.vue";
import { useMountsStore } from "@/stores/new/mounts.store";
import { PricingService } from "@/app/services/pricing.service";

const mountsStore = useMountsStore();
const activeMount = computed(() => mountsStore.activeMount);

const pricingService = new PricingService();

watch(
  () => activeMount.value?.components,
  () => {
    const sum = pricingService.calculateMountPrice();

    activeMount.value?.update({ sum });
  },
  { immediate: true, deep: true },
);
</script>

<style lang="scss" scoped></style>
