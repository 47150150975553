<template>
  <select-dict
    v-model="doorType"
    :selectList="doorTypes"
    :select-first-item="true"
  />
</template>

<script setup lang="ts">
import { computed, defineModel } from "vue";

import SelectDict from "@/components/UI/SelectDict.vue";

import { useDictStore } from "@/stores/dictStore";
import { usePartitionStore } from "@/stores/new/partition.store";
import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { DoorPredicates } from "@/app/predicates";

const doorType = defineModel<string>();

const estimateStore = useNewEstimateStore();
const dictStore = useDictStore();
const partitionStore = usePartitionStore();

const activePartition = computed(() => partitionStore.activePartitions);
const installedSystemCode = computed(
  () => estimateStore.estimate.installedSystemCode,
);

const doorTypes = computed(() => {
  // todo костыль, чтобы не добавлять ко всем типам дверей все типы партишн кроме dr-none, вернем руками dr-none
  if (activePartition.value?.isDivider()) {
    // divider
    return dictStore
      .getDoorTypes(installedSystemCode.value)
      .filter((item) => DoorPredicates.isDoorNone(item.code));
  }

  return dictStore.getDoorTypes(installedSystemCode.value);
});
</script>

<style lang="scss" scoped></style>
