import { IComponentConfig } from "@/app/@types/base-component";
import { ReactiveComponent } from "./base-component";

export class GlassPartition extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "glassPartition" });
    }
}

export class GlassThinknessPartition extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "glassThinknessPartition" });
    }
}
