import { defineStore } from "pinia";
import { ref, computed } from "vue";

import {
    Partition,
    GlassPartition,
    GlassThinknessPartition,
} from "@/app/domain";
import { useSpacesStore } from "./spaces.store";
import { DimensionsService } from "@/services/dimensions.service";
import { OutOfSquare } from "@/app/domain/entities/out-of-square";
import {
    GlassSortClearCode,
    GlassThicknessCode3_8,
    PartitionGlassCode,
} from "@/app/constants";

export const usePartitionStore = defineStore(
    "partition-store",
    () => {
        const spacesStore = useSpacesStore();
        const activeSpace = computed(() => spacesStore.activeSpace);
        const activePartitions = ref<Partition | null>(null);

        const activeGlassPartition = ref<GlassPartition | null>();
        const activeGlassThinknessPartition =
            ref<GlassThinknessPartition | null>();
        const activeOutOfSquare = ref<OutOfSquare | null>();

        const hasActivePartition = computed(
            () => activePartitions.value !== null,
        );

        const $reset = () => {
            activeGlassPartition.value = null;
            activeGlassThinknessPartition.value = null;
            activePartitions.value = null;
            activeOutOfSquare.value = null;
        };

        const addPartition = () => {
            if (!activeSpace.value) {
                return;
            }

            const partition = new Partition({
                qty: 1,
                structureType: PartitionGlassCode,
            });

            activeSpace.value.addComponent(partition);
            activePartitions.value = partition;

            addGlassPartition();
            addGlassThinknessPartition();
            addOutOfSquare();
        };

        const addGlassPartition = () => {
            const glassPartition = new GlassPartition({
                structureType: GlassSortClearCode,
            });

            activePartitions.value?.addComponent(glassPartition);
            activeGlassPartition.value = glassPartition;
        };

        const addGlassThinknessPartition = () => {
            const glassThinksnessPartition = new GlassThinknessPartition({
                structureType: GlassThicknessCode3_8,
            });

            activeGlassPartition.value?.addComponent(glassThinksnessPartition);
            activeGlassThinknessPartition.value = glassThinksnessPartition;
        };

        const addOutOfSquare = () => {
            const outOfSquare = new OutOfSquare({});

            activePartitions.value?.addComponent(outOfSquare);
            activeOutOfSquare.value = outOfSquare;
        };

        const getPartitionsTotalArea = () => {
            if (!activeSpace.value) {
                return 0;
            }

            const partitions = activeSpace.value.components;
            return DimensionsService.calculateEstimatePartitionsArea(
                partitions,
            );
        };

        return {
            activeSpace,
            activePartitions,
            activeGlassPartition,
            activeGlassThinknessPartition,
            activeOutOfSquare,
            hasActivePartition,
            addPartition,
            addGlassPartition,
            addGlassThinknessPartition,
            addOutOfSquare,
            getPartitionsTotalArea,
            $reset,
        };
    },
    { persist: true },
);
