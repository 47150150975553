<template>
  <div v-if="header.type === 'header'" class="step__accordion-block-head">
    <div class="step__accordion-block-container">
      <h3>{{ header.title !== "" ? header.title : header.objectType }}</h3>
      <!--            <div class="edit-button">
                <a @click="emit('headerClickEdit', header.objectType, header.objectCode)">edit
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.5 1.62132C11.8978 1.2235 12.4374 1 13 1C13.5626 1 14.1022 1.2235 14.5 1.62132C14.8978 2.01915 15.1213 2.55871 15.1213 3.12132C15.1213 3.68393 14.8978 4.2235 14.5 4.62132L5 14.1213L1 15.1213L2 11.1213L11.5 1.62132Z"
                            stroke="#9FB7CD" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
            </div>-->
      <div class="delete-button">
        <a
          v-if="canDelete"
          @click="
            emit('headerClickDelete', header.objectType, header.objectCode)
          "
          >delete
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="16"
            height="16"
            viewBox="0,0,256,256"
          >
            <g
              fill="#ffffff"
              fill-rule="nonzero"
              stroke="none"
              stroke-width="1"
              stroke-linecap="butt"
              stroke-linejoin="miter"
              stroke-miterlimit="10"
              stroke-dasharray=""
              stroke-dashoffset="0"
              font-family="none"
              font-weight="none"
              font-size="none"
              text-anchor="none"
              style="mix-blend-mode: normal"
            >
              <g transform="scale(10.66667,10.66667)">
                <path
                  d="M4.70703,3.29297l-1.41406,1.41406l7.29297,7.29297l-7.29297,7.29297l1.41406,1.41406l7.29297,-7.29297l7.29297,7.29297l1.41406,-1.41406l-7.29297,-7.29297l7.29297,-7.29297l-1.41406,-1.41406l-7.29297,7.29297z"
                ></path>
              </g>
            </g>
          </svg>
        </a>
      </div>
    </div>
    <div class="step__accordion-block-container">
      <div>
        <span>{{ header.qtyTitle }}</span>
      </div>
      <div class="step__accordion-block-units">
        <div>
          <span>{{ header.priceTitle }}</span>
        </div>
        <div>
          <span>{{ header.sumTitle }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";
import { SummaryHeader } from "@/models/summary";

const props = defineProps({
  summaryHeader: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["headerClickEdit", "headerClickDelete"]);

const header = <SummaryHeader>props.summaryHeader;
const canDelete = computed(() => header.objectType === "partition");
</script>

<style scoped>
h3::first-letter {
  text-transform: capitalize;
}
</style>
