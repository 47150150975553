import { CostDealType, B2CDealType, B2BDealType } from "@/app/constants";
import { ReactiveComponent } from "../../entities/base-component";
import { Pricing } from "../../entities/pricing";
import { CalculationStrategy, PriceResult } from "../base/calculation.strategy";

export class EstimateCalculationStrategy extends CalculationStrategy {
    calculate = (
        component: ReactiveComponent,
        [discountSum, subtotal],
    ): PriceResult => {
        return {
            price: this.calculatePriceForType(
                discountSum,
                subtotal,
                CostDealType,
            ),
            priceB2C: this.calculatePriceForType(
                discountSum,
                subtotal,
                B2CDealType,
            ),
            priceB2B: this.calculatePriceForType(
                discountSum,
                subtotal,
                B2BDealType,
            ),
        };
    };

    private calculatePriceForType(
        discountSum: number,
        subtotal: Pricing,
        dealType: string,
    ): number {
        const price = subtotal?.getSystemPrice(dealType).getValue();

        return price - discountSum;
    }
}
