import { defineStore } from "pinia";
import {
    BackendHeader,
    DeliveryInfo,
    Estimate,
    EstimatedSpace,
    EstimateInfo,
    ProgressInfo,
} from "../models/estimate";
import { getEstimate, postEstimate, postHubspot } from "../utils/api";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import {
    AdditionalCostItem,
    OneDimension,
    Structure,
    StructureItem,
} from "../models/structure";
import {
    EstimateService,
    isDoubleDoor,
    isKompasSystem,
    isCgfSystem,
} from "../services/estimateService";
import { SummaryService } from "../services/summaryService";
import { computed, ComputedRef, reactive, watch, ref } from "vue";
import {
    CalculateService,
    EstimateCalulatorBuilder,
} from "../services/calculateService";
import { SummaryBlock } from "../models/summary";
import {
    dimensionCompare,
    dimensionSubtract,
    generateCode,
} from "../utils/common";
import { useUserStore } from "./userStore";
import { useDictStore, getTitleFromDictByCode } from "./dictStore";
import { structureTypes } from "../data/initDicts";

const LOCAL_STORAGE_ESTIMATE_KEY = "estimate";
const LOCAL_STORAGE_CALCULATION_KEY = "calculation";
const LOCAL_STORAGE_SUMMARY_KEY = "summary";

export const useEstimateStore = defineStore("estimate", () => {
    const userStore = useUserStore();
    const data = ref({
        user: userStore,
    });
    const estimate: Estimate = ref({
        info: {
            priceDate: Date.now(),
            priceDateFormated: dayjs().format("YYYY-MM-DD"),
            code: generateCode(),
            subtotal: null,
            estimatePrice: null,
        },
    });

    const summary = computed(() => {
        if (!estimate.value) return null;
        return SummaryService.fromEstimate(estimate, calculation.value);
    });

    const calculation = computed(() => {
        if (!estimate.value) return null;
        const calculation = EstimateCalulatorBuilder.fromEstimate(estimate);
        CalculateService.deepCalculateSubitems(calculation);
        CalculateService.deepCalculateSubItemsB2C(calculation);
        CalculateService.deepCalculateSubItemsB2B(calculation);
        return calculation;
    });

    const isEstimateReadonly: ComputedRef<boolean> = computed(
        () => !(estimate.value?.progress.status === "DRAFT"),
    );

    function getEstimateReadonly(): boolean {
        let isReadOnly = true;
        const estimate = this.estimate;
        if (
            estimate?.progress.status === "DRAFT" ||
            estimate?.progress.status === "DONE" ||
            estimate?.progress.status === "QUOTE"
        ) {
            isReadOnly = false;
        }
        if (
            estimate?.progress.status === "OFFICIAL" ||
            estimate?.progress.status === "ORDER"
        ) {
            if (data.value?.user?.profile?.roles?.length > 0) {
                data.value.user.profile.roles.forEach(function (role) {
                    if (role.slug == "admin") {
                        isReadOnly = false;
                    }
                    if (role.slug == "manager") {
                        if (
                            estimate?.info?.managerCode !== null &&
                            data.value?.user?.profile?.email !== null
                        ) {
                            // if manager is author estimate to be false
                            isReadOnly =
                                estimate?.info?.managerCode !=
                                data.value.user.profile.email;
                        } else {
                            isReadOnly = true;
                        }
                    }
                    if (
                        estimate?.progress.status === "ORDER" &&
                        role.slug === "estimator"
                    ) {
                        isReadOnly = false;
                    }
                });
            }
        }
        if (data.value?.user?.profile?.roles?.length > 0) {
            data.value.user.profile.roles.forEach(function (role) {
                if (role.slug == "admin") {
                    isReadOnly = false;
                }
            });
        }

        return isReadOnly;
    }

    function getEstimateSystemTypeCode() {
        return this.estimate?.info.installedSystemCode;
    }

    //    const estimate = computed(() => ref(estimate));

    function closeActiveEstimate(): void {
        this.activeEstimateCode = null;
        this.estimate = null;
        // localStorage.removeItem(LOCAL_STORAGE_ESTIMATE_KEY);
        // localStorage.removeItem(LOCAL_STORAGE_CALCULATION_KEY);
        // localStorage.removeItem(LOCAL_STORAGE_SUMMARY_KEY);
    }

    function reset(initialData?: any): void {
        console.debug("reset estimate");
        localStorage.setItem(LOCAL_STORAGE_ESTIMATE_KEY, JSON.stringify({}));
        localStorage.setItem(LOCAL_STORAGE_CALCULATION_KEY, JSON.stringify({}));
        localStorage.setItem(LOCAL_STORAGE_SUMMARY_KEY, JSON.stringify({}));
        this.estimate = EstimateService.estimateFactory({
            ...{
                info: {
                    estimatorCode: useUserStore().userCode,
                },
            },
            ...initialData,
        });
        this.estimateService = new EstimateService(this.estimate);
        this.activeEstimateCode = this.estimate.info.code;
    }

    function loadDraft(): void {
        console.debug("load draft");
        try {
            const json = localStorage.getItem(LOCAL_STORAGE_ESTIMATE_KEY);
            this.estimate = JSON.parse(json);
        } catch (e) {
            console.error("error loading draft estimate", e.message);
            console.info("reset draft data");
            this.reset();
            return;
        }

        //        this.summary = (new CalculateService(this.estimate).getSummary());
        if (!(this.activeEstimateCode = this.estimate?.info?.code)) {
            console.error("estimate.info.code is empty, resetting");
            this.reset();
        }
    }

    async function save() {
        // todo this data get from estimate
        const calculation = EstimateCalulatorBuilder.fromEstimate(
            this.estimate,
        );
        const summary = SummaryService.fromEstimate(this.estimate, calculation);
        // const summaryToBacked = summary.lines.filter(line => line.sendToBackend)

        const isNew = !this.estimate.progress.isSaved;
        console.debug(
            (isNew ? "save" : "update") + " to remote",
            this.estimate.info.code,
        );
        this.estimate.progress.isSaved = true;
        this.estimate.progress.updated = dayjs().unix().toString();
        const savingData = {
            estimate: cloneDeep(this.estimate),
            calculation: calculation,
            summary: summary,
            header: this.getBackendHeader(),
        };
        try {
            const data = await postEstimate(
                savingData,
                this.estimate.info.code,
                isNew,
            );
            this.estimate.progress.isSaved = true;
        } catch (error) {
            console.error(error);
            this.estimate.progress.isSaved = !isNew;
        }
        this.saveLocal();
    }

    async function load(estimateCode: string, force = false) {
        if (force) {
            console.debug("load from api", estimateCode);
            const estimateData = await getEstimate(estimateCode);
            useDictStore().loadAllDictsByDate(
                estimateData.estimate?.info?.priceDateFormated,
            );
            this.estimate = estimateData.estimate;
            this.estimate.progress.isSaved = true;
            localStorage.setItem(
                LOCAL_STORAGE_ESTIMATE_KEY,
                JSON.stringify(this.estimate),
            );
        } else {
            console.debug("load from localstorage", estimateCode);
            const storedEstimate = JSON.parse(
                localStorage.getItem(LOCAL_STORAGE_ESTIMATE_KEY),
            );
            if (storedEstimate && storedEstimate.info.code === estimateCode) {
                return;
            }
        }
        this.activeEstimateCode = estimateCode;
    }

    /** clone active estimate */
    async function cloneEstimate(estimateCode: string = null) {
        if (estimateCode) {
            await this.load(estimateCode, true);
        } else {
            await this.save();
        }
        if (!this.estimate) {
            return;
        }
        this.estimate.info.code = generateCode();
        // todo calculatin && summary code
        this.activeEstimateCode = this.estimate.info.code;
        this.estimate.progress.isSaved = false;
        this.setEstimateStatus("DRAFT");
        await this.save();
    }

    async function saveWithStatus(status: string) {
        if (
            !["DRAFT", "DONE", "OFFICIAL", "QUOTE", "ORDER"].includes(
                status.toUpperCase(),
            )
        ) {
            console.error("wrong estimate status ", status);
            return;
        }
        this.setEstimateStatus(status.toUpperCase());
        this.saveLocal();
        await this.save();
    }

    async function sendHubspot() {
        return await postHubspot(this.estimate.info.code);
    }

    function updateInfo(info: EstimateInfo): void {
        this.estimate.info = info;
        this.saveLocal();
    }

    function renameEstimate(title: string): void {
        this.estimate.info.title = title;
        this.saveLocal();
    }

    function setEstimateStatus(newStatus) {
        //todo validate data
        this.estimate.progress.status = newStatus;
    }

    function setEstimateNo() {
        //todo validate data
        this.estimate.info.estimateNo = dayjs().format("YYMMDD-hm");
        this.saveLocal();
    }

    /** save local draft copy of estimate */
    function saveLocal() {
        console.debug("save draft local");
        localStorage.setItem(
            LOCAL_STORAGE_ESTIMATE_KEY,
            JSON.stringify(this.estimate),
        );
        //        this.summary = (new CalculateService(this.estimate)).getSummary()
        // return await postEstimate(this.estimate.value.id, this.estimate.value);
    }

    function newEstimate(): void {
        this.reset();
    }

    function getSummary(): SummaryBlock {
        if (this.estimate === null) return null;
        // test, move to computed var
        //                const calculation = EstimateCalulatorBuilder.fromEstimate(this.estimate);
        //                CalculateService.deepCalculateSubitems(calculation);
        // end test
        return SummaryService.fromEstimate(this.estimate, this.calculation);
    }

    function getBackendHeader(): BackendHeader {
        const dictStore = useDictStore();

        // todo move to calculate
        const clc = EstimateCalulatorBuilder.fromEstimate(this.estimate);

        return <any>{
            startedDate: this.estimate.info.started,
            priceDate: this.estimate.info.priceDate,
            priceDateFormated: this.estimate.info.priceDateFormated,
            code: this.estimate.info.code,
            status: this.estimate.progress.status,
            estimateN: this.estimate.info.estimateNo,
            trackCode: this.estimate.info.trackCode,
            title: this.estimate.info.title,
            client: this.estimate.info.client.name,
            manager: getTitleFromDictByCode(
                dictStore.getManagers(),
                this.estimate.info.managerCode,
            ),
            dealTypeCode: getTitleFromDictByCode(
                dictStore.getDealTypes(),
                this.estimate.info.dealTypeCode,
            ),
            installationPlaceTitle: getTitleFromDictByCode(
                dictStore.getInstallationPlaces(),
                this.estimate.info.installationPlaceCode,
            ),
            installedSystem: getTitleFromDictByCode(
                dictStore.getSystemTypes(),
                this.estimate.info.installedSystemCode,
            ),
            usage: getTitleFromDictByCode(
                dictStore.getSystemUsages(),
                this.estimate.info.usageCode,
            ),
            finishColor: getTitleFromDictByCode(
                dictStore.getFinishColors(),
                this.estimate.info.colorCode,
            ),
            shippingAddress:
                this.estimate.info.shippingZip +
                ", " +
                this.estimate.info.shippingState,
            installation: this.estimate.info?.isHaflInstallation
                ? this.estimate.info?.halfInstallation
                : this.estimate.info?.installation,
            isHaflInstallation: this.estimate.info.isHaflInstallation,
            delivery: this.estimate.info.delivery,
            estimator: this.estimate.info.estimatorCode?.title
                ? this.estimate.info.estimatorCode?.title
                : "not set",
            updatedDate: this.estimate.progress.updated,
            quantity: this.calculation?.qty,
            pricePer: clc.sum.toFixed(2),
            price: clc.sum.toFixed(2),
            priceB2C: clc.sumB2C.toFixed(2),
            priceB2B: clc.sumB2B.toFixed(2),
            subPrice: clc.subSum.toFixed(2),
            discount: this.estimate.delivery.discountPercent, //TODO: add from price rules
            business: this.estimate.info.client.businessName,
            email: this.estimate.info.client.email,
            phone: this.estimate.info.client.phone,
            state: this.estimate.info.shippingState,
            comments: "",
            lastValidatedPage: this.estimate.progress.validatedPage,
        };
    }

    function getActiveEstimateCode(): string {
        return this.activeEstimateCode;
    }

    function getProgress(): ProgressInfo {
        return this.estimate.progress;
    }

    function getDeliveryInfo(): DeliveryInfo {
        return this.estimate.delivery;
    }

    function updateDeliveryInfo(deliveryInfo: DeliveryInfo) {
        this.estimate.delivery = deliveryInfo;
        this.saveLocal();
    }

    function deleteElementByType(elementType: string, elementCode: string) {
        console.debug("deleteElementByType", elementType, elementCode);

        switch (elementType) {
            case "space":
                this.estimate.spaces = this.estimate.spaces.filter(
                    (item) => item.code !== elementCode,
                );
                break;
            case "partition":
                this.estimate.spaces?.forEach((space: EstimatedSpace) => {
                    space.structures = space.structures.filter(function (
                        structure: Structure,
                    ) {
                        if (structure.partition.code !== elementCode) {
                            return true;
                        }
                    });
                });
                this.estimate.spaces = this.estimate.spaces?.filter(function (
                    space: EstimatedSpace,
                ) {
                    if (space.structures.length > 0) {
                        return true;
                    }
                });
                break;
            case "door":
                this.estimate.spaces?.forEach((space: EstimatedSpace) => {
                    space.structures.forEach((room: Structure) => {
                        room.doors = room.doors.filter(
                            (door) => door.code !== elementCode,
                        );
                    });
                });
                break;
            case "transom":
            case "filler":
                this.estimate.spaces?.forEach((space: EstimatedSpace) => {
                    space.structures.forEach((room: Structure) => {
                        if (Array.isArray(room.fillers)) {
                            room.fillers = room.fillers.filter(
                                (filler) => filler.code !== elementCode,
                            );
                        }
                    });
                });
                break;
            case "mounts":
                this.estimate.spaces?.forEach((space: EstimatedSpace) => {
                    space.structures.forEach((room: Structure) => {
                        if (Array.isArray(room.mounts)) {
                            room.mounts = room.mounts.filter(
                                (mount) => mount.code !== elementCode,
                            );
                        }
                    });
                });
                break;
            case "info":
            case "delivery":
            case "overhead":
                // do none
                break;
        }
    }

    function setActiveElementByType(elementType: string, elementCode: string) {
        console.debug("setActiveElementByType", elementType, elementCode);

        switch (elementType) {
            case "space":
                this.selectSpace(elementCode);
                break;
            case "partition":
                this.selectPartition(elementCode, false);
                break;
            case "door":
                this.selectDoor(elementCode, false);
                break;
            case "transom":
            case "filler":
                this.selectFiller(elementCode, false);
                break;
            case "mounts":
                this.selectMounts(elementCode, false);
                break;
            case "info":
            case "delivery":
            case "overhead":
                // do none
                break;
        }
    }

    function getActiveSpace(): EstimatedSpace {
        if (!this.estimate.spaces.length) {
            // create new
            console.debug("no spaces in estimate, create new");
            this.addSpaceAndSetActive();
        }

        if (!this.estimate.progress.activeSpaceCode) {
            console.debug("no selected active space");
            console.debug("select first");
            this.estimate.progress.activeSpaceCode =
                this.estimate.spaces[0].code;
        }

        const space: EstimatedSpace = this.estimate.spaces.find(
            (item: EstimatedSpace) =>
                item.code === this.estimate.progress.activeSpaceCode,
        );

        if (!space) {
            console.error("error finding active space");
            return null;
        }

        return space;
    }

    function getActiveStructure(): Structure {
        const activeSpace = this.getActiveSpace();
        if (!activeSpace) {
            return null;
        }

        let structure: Structure = null;
        if (!activeSpace.structures.length) {
            console.debug("no structures in for active space, create new");
            this.addStructureAndSetActive();
            //                    structure = EstimateService.structureFactory({title: ''});
            //                    activeSpace.structures.push(structure);
            //                    this.estimate.progress.activeStructureCode = structure.code;
        } else {
            if (!this.estimate.progress.activeStructureCode) {
                console.debug("structure code is empty, select first in space");
                this.estimate.progress.activeStructureCode =
                    activeSpace.structures[0].code;
            }
        }
        // if not new created
        if (!structure) {
            structure = activeSpace.structures.find(
                (item) =>
                    item.code === this.estimate.progress.activeStructureCode,
            );
        }

        if (!this.estimate.progress.activeStructureCode) {
            console.error("no selected active structure");
            return null;
        }

        if (!structure) {
            console.error("can`t find active structure");
            return null;
        }

        return structure;
    }

    function getActiveDoor(): StructureItem {
        const activeStructure = this.getActiveStructure();
        if (!activeStructure) {
            return null;
        }

        if (!activeStructure.doors.length) {
            console.debug("no doors in active structure, create new");
            // door = EstimateService.structureDoorFactory({});
            // activeStructure.doors.push(door);
            // this.estimate.progress.activeDoorCode = door.code;
            this.addDoorAndSetActive();
        }

        if (!this.estimate.progress.activeDoorCode) {
            console.debug("no selected active door, select first");
            this.estimate.progress.activeDoorCode =
                activeStructure.doors[0].code;
        }

        let door = activeStructure.doors.find(
            (item) => item.code === this.estimate.progress.activeDoorCode,
        );

        if (!door) {
            console.error("can`t find active door, select first door in room");
            // выбираем первую дверь в текущей комнате
            door = activeStructure.doors.find((item, idx) => idx === 0);
            // если так ничего и не нашли
            if (!door) {
                console.error("can`t find active first door in room");
                return null;
            }
            this.estimate.progress.activeDoorCode = door.code;
            return door;
        }

        return door;
    }

    function getActiveFiller(): StructureItem {
        const activeStructure: Structure = this.getActiveStructure();
        if (!activeStructure) {
            return null;
        }

        if (!activeStructure.fillers.length) {
            console.debug("no fillers in active structure, create new");
            this.addFillerAndSetActive();
        } else {
            if (!this.estimate.progress.activeFillerCode) {
                console.debug("no selected active filler, select first");
                this.estimate.progress.activeFillerCode =
                    activeStructure.fillers[0].code;
            }
        }
        const filler = activeStructure.fillers.find(
            (item) => item.code === this.estimate.progress.activeFillerCode,
        );
        if (!filler) {
            console.error("can`t find active filler");
            return null;
        }

        return filler;
    }

    function getActiveMounts(): StructureItem {
        /**
         * maunts переделаны из массива в объект, т.к. только один набор может быть для структуры
         */
        const activeStructure: Structure = this.getActiveStructure();
        if (!activeStructure) {
            return null;
        }

        let mounts: StructureItem = null;
        if (
            !(
                activeStructure.mounts &&
                Array.isArray(activeStructure.mounts) &&
                activeStructure.mounts.length > 0
            )
        ) {
            console.debug("no mount sets in active structure, create new");
            this.addMountsAndSetActive();
        } else {
            if (!this.estimate.progress.activeMountsCode) {
                console.debug("no selected active mounts, select first");
                this.estimate.progress.activeMountsCode =
                    activeStructure.mounts.code;
            }
        }

        // mounts = activeStructure.mounts.find(item => item.code === this.estimate.progress.activeMountsCode);
        mounts = activeStructure.mounts;

        if (!mounts) {
            console.error("can`t find active mount set");
            return null;
        }
        return mounts;
    }

    function selectSpace(spaceCode: string): void {
        const space: EstimatedSpace = this.estimate.spaces.find(
            (item: EstimatedSpace) => item.code === spaceCode,
        );
        if (space) {
            this.estimate.progress.activeSpaceCode = space.code;
            this.estimate.progress.activeStructureCode = null;
            this.estimate.progress.activeDoorCode = null;
            this.estimate.progress.activeFillerCode = null;
        } else {
            console.error(`space with code ${spaceCode} not found`);
        }
    }

    function selectStructure(
        structureCode: string,
        onlyInActiveSpace: boolean = true,
    ): void {
        // todo search in spaces
        if (!onlyInActiveSpace) {
            const spaceCode = EstimateService.findSpacesForElementByCode(
                this.estimate.spaces,
                structureCode,
            );
            this.selectSpace(spaceCode);
        }
        this.estimate.progress.activeStructureCode = structureCode;
    }

    function selectPartition(
        partitionCode: string,
        onlyInActiveStructure: boolean = true,
    ): void {
        // todo search in spaces
        let structureCode = null;
        if (!onlyInActiveStructure) {
            const spaceCode = EstimateService.findSpacesForElementByCode(
                this.estimate.spaces,
                partitionCode,
            );
            this.selectSpace(spaceCode);
            structureCode = EstimateService.findStructuresForElementByCode(
                this.getActiveSpace().structures,
                partitionCode,
            );
            this.selectStructure(structureCode);
        }
        this.estimate.progress.activeStructureCode = structureCode;
    }

    function selectDoor(
        doorCode: string,
        onlyInActiveStructure: boolean = true,
    ): void {
        let structureCode = null;
        if (!onlyInActiveStructure) {
            const spaceCode = EstimateService.findSpacesForElementByCode(
                this.estimate.spaces,
                doorCode,
            );
            this.selectSpace(spaceCode);
            structureCode = EstimateService.findStructuresForElementByCode(
                this.getActiveSpace().structures,
                doorCode,
            );
            this.selectStructure(structureCode);
        }
        this.estimate.progress.activeDoorCode = doorCode;
    }

    function selectFiller(
        fillerCode: string,
        onlyInActiveStructure: boolean = true,
    ): void {
        let structureCode = null;
        if (!onlyInActiveStructure) {
            const spaceCode = EstimateService.findSpacesForElementByCode(
                this.estimate.spaces,
                fillerCode,
            );
            this.selectSpace(spaceCode);
            structureCode = EstimateService.findStructuresForElementByCode(
                this.getActiveSpace().structures,
                fillerCode,
            );
            this.selectStructure(structureCode);
        }
        this.estimate.progress.activeFillerCode = fillerCode;
    }

    function selectMounts(
        mountsCode: string,
        onlyInActiveStructure: boolean = true,
    ): void {
        if (!onlyInActiveStructure) {
            const spaceCode = EstimateService.findSpacesForElementByCode(
                this.estimate.spaces,
                mountsCode,
            );
            this.selectSpace(spaceCode);
            const structureCode =
                EstimateService.findStructuresForElementByCode(
                    this.getActiveSpace().structures,
                    mountsCode,
                );
            this.selectStructure(structureCode);
        }
        this.estimate.progress.activeMountsCode = mountsCode;
    }

    function validateEstimateInfo(estimate: EstimateInfo): boolean {
        console.debug(`validate estimate info`, estimate);
        return true;
    }

    function validateSpaceInfo(space: EstimatedSpace): boolean {
        return true;
    }

    function validateStructure(structure: Structure): boolean {
        return true;
    }

    function renameSpace(title: string) {
        console.debug("rename space", title);
        const updatedSpace: EstimatedSpace = this.getActiveSpace();
        updatedSpace.title = title;
        this.saveLocal();
    }

    function updateProgressInfo(data: object): void {
        console.debug("updateProgressInfo", data);
        this.estimate.progress = { ...this.estimate.progress, ...data };
        this.saveLocal();
    }

    function updateStructure(structure: Structure): void {
        // search structure or use active structure
        const updatedStructure = this.getActiveStructure();
        if (!updatedStructure) {
            console.debug("no active structure, update refused");
            return;
        }
        Object.assign(updatedStructure, structure);
        console.debug(
            "structure updated",
            structure.code,
            "new",
            structure,
            "updated",
            updatedStructure,
        );
        this.saveLocal();
    }

    function updatePartition(partition: StructureItem): void {
        // search structure or use active structure
        const updatedStructure: Structure = this.getActiveStructure();
        if (!updatedStructure) {
            console.debug("no active partition, update refused");
            return;
        }
        Object.assign(updatedStructure.partition, partition);
        console.debug(
            "partition updated",
            partition.code,
            "new",
            partition,
            "updated",
            updatedStructure.partition,
        );
        this.saveLocal();
    }

    function updateDoor(door: StructureItem): void {
        // search structure or use active structure
        const updatedDoor: StructureItem = this.getActiveDoor();
        if (!updatedDoor) {
            console.debug("no active door, update refused");
            return;
        }
        Object.assign(updatedDoor, door);
        console.debug(
            "door updated",
            door.code,
            "new",
            door,
            "updated",
            updatedDoor,
        );
        this.updateActivePartitionGlassArea();
        this.saveLocal();
    }

    function updateFiller(filler: StructureItem): void {
        // search structure or use active structure
        const updatedFiller: StructureItem = this.getActiveFiller();
        if (!updatedFiller) {
            console.debug("no active filler, update refused");
            return;
        }
        Object.assign(updatedFiller, filler);
        console.debug(
            "filler updated",
            filler.code,
            "new",
            filler,
            "updated",
            updatedFiller,
        );
        this.updateActivePartitionGlassArea();
        this.saveLocal();
    }

    function updateMounts(mounts: StructureItem): void {
        // search structure or use active structure
        const updatedMounts: StructureItem = this.getActiveMounts();
        if (!updatedMounts) {
            console.debug("no active mounts, update refused");
            return;
        }
        Object.assign(updatedMounts, mounts);
        console.debug(
            "mounts updated",
            mounts.code,
            "new",
            mounts,
            "updated",
            updatedMounts,
        );
        this.saveLocal();
    }

    function updateOverheadCosts(overheadCosts: AdditionalCostItem[]) {
        // can be null, `cause not created in estimate start
        if (this.estimate.overhead) {
            const updatedOverheads: AdditionalCostItem[] =
                this.estimate.overhead;
            Object.assign(updatedOverheads, overheadCosts);
        } else {
            this.estimate.overhead = overheadCosts;
        }
        console.debug("оverheads updated", "updated", overheadCosts);
        this.saveLocal();
    }

    function addSpaceAndSetActive(): void {
        const space: EstimatedSpace = EstimateService.estimatedSpacesFactory({
            title: "estimated space",
        });
        this.estimate.spaces.push(space);
        this.estimate.progress.activeSpaceCode = space.code;
        this.estimate.progress.activeStructureCode = null;
        this.estimate.progress.activeDoorCode = null;
        this.estimate.progress.activeFillerCode = null;
        console.debug("new space added", space.code);
    }

    function addStructureAndSetActive(): void {
        // для добавления структуры, нужно указать тип
        // костыль, надо выносить значения по умолчанию
        // непонятно, надо ли для партиции использовать значения по умолчанию
        let partitionDefaults = {};
        if (
            this.estimate.info.installedSystemCode === "trevi" ||
            this.estimate.info.installedSystemCode === "romano"
        ) {
            partitionDefaults = {
                glassThicknessCode: "3-16",
            };
        }
        const structure: Structure = EstimateService.structureFactory({
            partition: partitionDefaults,
        });
        this.getActiveSpace().structures.push(structure);
        this.selectStructure(structure.code);
        this.estimate.progress.activeDoorCode = null;
        this.estimate.progress.activeFillerCode = null;

        console.debug("new structure added", structure.code);
    }

    function addDoorAndSetActive(): void {
        const structure = this.getActiveStructure();
        // вся информация о расчете находится здесь, поэтому
        // начальные параметры для двери, которые зависят от сторонних данных тоже определяем здесь
        // то что параметри только двери, определяем в фабрике
        const systemCode = this.estimate.info.installedSystemCode;
        const partitionType = structure.partition.structureTypeCode;

        // для компасов дверь по умолчанию 3*7
        // для остальных  3 * высоту партишна

        let defaultDimensions = {
            height: structure.partition.dimensions.height,
            width: <OneDimension>{ ft: 3, inch: 0, fractionA: 0, fractionB: 0 },
        };
        if (partitionType === "dr") {
            // structureTypes dict
            defaultDimensions = {
                height: structure.partition.dimensions.height,
                width: structure.partition.dimensions.width,
            };
        }
        if (isKompasSystem(systemCode)) {
            defaultDimensions = {
                height:
                    structure.partition.dimensions.height.ft >= 7
                        ? {
                              ft: 7,
                              inch: 0,
                              fractionA: 0,
                              fractionB: 0,
                          }
                        : structure.partition.dimensions.height,
                width: <OneDimension>{
                    ft: 3 * (systemCode === "kompas-dbl" ? 2 : 1),
                    inch: 0,
                    fractionA: 0,
                    fractionB: 0,
                },
            };
        }

        const door: StructureItem = EstimateService.structureDoorFactory(
            {
                glassSortCode: structure.partition.glassSortCode,
                glassThicknessCode: structure.partition.glassThicknessCode,
                dimensions: defaultDimensions,
                qty: structure.partition.qty,
            },
            systemCode,
        );
        this.getActiveStructure().doors.push(door);
        this.selectDoor(door.code);
        console.debug("new door added and set active", door.code);
    }

    function addFillerAndSetActive(): void {
        const structure = this.getActiveStructure();
        const door = this.getActiveDoor();

        // вся информация о расчете находится здесь, поэтому начальные параметры для трансома и филлера тоже определяем здесь
        let filler: StructureItem = null;

        // !!! если добавляем не первый филлер, ставим тип в тот что выбран сейчас
        if (structure.fillers?.length) {
            const activeFiller = this.getActiveFiller();
            filler = EstimateService.structureItemFactory({
                structureTypeCode: activeFiller.structureTypeCode,
                glassSortCode: activeFiller.glassSortCode,
                glassThicknessCode: activeFiller.glassThicknessCode,
                dimensions: {
                    width: structure.partition.dimensions.width,
                    height: structure.partition.dimensions.height,
                },
                fillerAssetsQty: 0,
            });
        }

        if (
            !filler &&
            door.structureTypeCode !== "dr-none" &&
            dimensionCompare(
                structure.partition.dimensions.height,
                door.dimensions.height,
            ) > 0 &&
            !["kompas-sgl", "kompas-dbl", "trevi", "romano"].includes(
                this.estimate.info.installedSystemCode,
            )
        ) {
            // если есть дверь
            // КРОМЕ дверей компас, треви, романо
            // если над дверью есть пустое место, делаем вставку (transom)
            filler = EstimateService.structureItemFactory({
                structureTypeCode: "transom",
                glassSortCode: structure.partition.glassSortCode,
                glassThicknessCode: structure.partition.glassThicknessCode,
                dimensions: {
                    width: door.dimensions.width,
                    height: dimensionSubtract(
                        structure.partition.dimensions.height,
                        door.dimensions.height,
                    ),
                },
                fillerAssetsQty: isDoubleDoor(door.structureTypeCode) ? 2 : 1, // remove AssetsQty, its simple Qty
                qty: door.qty,
            });
        }

        // cgf и kompas divider на 4 шаге none надо поставить по умолчанию
        if (
            !filler &&
            structure.partition.structureTypeCode === "div" &&
            (isCgfSystem(this.estimate.info.installedSystemCode) ||
                isKompasSystem(this.estimate.info.installedSystemCode))
        ) {
            filler = EstimateService.structureItemFactory({
                structureTypeCode: "none",
                fillerAssetsQty: 0, // isDoubleDoor(door.structureTypeCode) ? 2 : 1, // remove AssetsQty, its simple Qty
            });
        }

        // если дверь=none & тип проема = divider, то выбираем заполнитель
        // убрали по просьбе клиета
        /*if (!filler && structure.partition.structureTypeCode === 'div' && door.structureTypeCode === 'dr-none') {
                filler = EstimateService.structureItemFactory({
                    structureTypeCode: 'filler',
                    glassSortCode: structure.partition.glassSortCode,
                    glassThicknessCode: structure.partition.glassThicknessCode,
                    dimensions: {
                        width: structure.partition.dimensions.width,
                        height: structure.partition.dimensions.height,
                    },
                    fillerAssetsQty: 0
                })
            }*/

        if (!filler) {
            // если нет места над дверью, или нет двери -- делаем none
            filler = EstimateService.structureItemFactory({
                structureTypeCode: "none",
                fillerAssetsQty: 0, // isDoubleDoor(door.structureTypeCode) ? 2 : 1, // remove AssetsQty, its simple Qty
            });
        }

        if (
            dimensionCompare(
                structure.partition.dimensions.width,
                door.dimensions.width,
            ) > 0 &&
            filler.structureTypeCode === "none"
        ) {
            // fixme: возможно, если нет проема но есть свободное место по сторонам то можно поставить filler, тогда раскомментариваем код ниже
            // filler = EstimateService.structureItemFactory({
            //     structureTypeCode: 'filler',
            //     glassSortCode: door.glassSortCode,
            //     glassThicknessCode: door.glassThicknessCode,
            // })
        }
        this.getActiveStructure().fillers.push(filler);
        this.selectFiller(filler.code);
        console.debug("new filler added and set active", filler.code);
    }

    function addMountsAndSetActive(): void {
        let structureTypeCode;

        switch (this.getEstimateSystemTypeCode()) {
            case "kompas-sgl":
            case "kompas-dbl":
                structureTypeCode = "mnt-perimeter";
                break;
            case "trevi":
            case "romano":
                structureTypeCode = "mnt-none";
                break;
            default:
                structureTypeCode = "mnt-perimeter";
        }
        const mounts: StructureItem = EstimateService.structureMountsFactory({
            structureTypeCode: structureTypeCode,
        });

        mounts.hardwares = EstimateService.mountsHardwareFactory(
            mounts,
            this.estimate.info.installedSystemCode,
            this.getActiveStructure().partition,
            this.getActiveDoor(),
            this.getActiveStructure().fillers.find(
                (item) => item.structureTypeCode === "transom",
            ), // первый попавшийся трансом
        );

        this.getActiveStructure().mounts = mounts;
        this.selectMounts(mounts.code);
        console.debug("new mounts set added and set active", mounts.code);
    }

    /**
     * Обновить площадь текущего проема (partition)
     * (учитываются вложенные структуры, двери и трансомы)
     */
    function updateActivePartitionGlassArea() {
        this.getActiveStructure().partition.dimensions.areaFt =
            EstimateService.calcPartitionGlassArea(
                this.estimate.info.installedSystemCode,
                this.getActiveStructure().partition,
                this.getActiveStructure().doors,
                this.getActiveStructure().fillers,
            );
    }

    watch(
        () => estimate.value.info.priceDateFormated,
        () => {
            console.debug(
                "watch estimateStore.estimate.info.priceDateFormated",
            );
            estimate.value.info.priceDate = dayjs(
                estimate.value.info.priceDateFormated,
                "YYYY-MM-DD",
            )
                .toDate()
                .getTime();
        },
    );

    return {
        estimate,
        summary,
        calculation,
        isEstimateReadonly,
        getEstimateReadonly,
        getEstimateSystemTypeCode,
        reset,
        save,
        load,
        loadDraft,
        saveLocal,
        cloneEstimate,
        saveWithStatus,
        sendHubspot,
        getBackendHeader,
        selectSpace,
        selectStructure,
        selectPartition,
        selectDoor,
        selectFiller,
        selectMounts,
        setActiveElementByType,
        setEstimateStatus,
        setEstimateNo,
        closeActiveEstimate,
        newEstimate,
        getSummary,
        getProgress,
        getDeliveryInfo,
        getActiveSpace,
        getActiveStructure,
        getActiveDoor,
        getActiveFiller,
        getActiveMounts,
        getActiveEstimateCode,
        validateEstimateInfo,
        validateSpaceInfo,
        validateStructure,
        renameEstimate,
        renameSpace,
        deleteElementByType,
        updateInfo,
        updateProgressInfo,
        updateDeliveryInfo,
        updateStructure,
        updatePartition,
        updateDoor,
        updateFiller,
        updateMounts,
        updateOverheadCosts,
        addSpaceAndSetActive,
        addStructureAndSetActive,
        addDoorAndSetActive,
        addFillerAndSetActive,
        addMountsAndSetActive,
        updateActivePartitionGlassArea,
    };
});
