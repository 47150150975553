import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { Space } from "@/app/domain";

export const useSpacesStore = defineStore(
    "spaces-store",
    () => {
        const spaces = ref<Space[]>([]);
        const activeSpace = ref<Space | null>(null);
        const hasActiveSpace = computed(() => activeSpace.value !== null);

        const $reset = () => {
            spaces.value = [];
            activeSpace.value = null;
        };

        const addSpace = () => {
            activeSpace.value = null;

            const space = new Space({});

            spaces.value.push(space);
            activeSpace.value = space;
        };

        return {
            spaces,
            activeSpace,
            hasActiveSpace,
            addSpace,
            $reset,
        };
    },
    { persist: true },
);
