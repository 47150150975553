import { Space } from "@/app/domain/entities/space";
import {
    createMountsBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsSideFrameSortConfig,
    createMountsStripesConfig,
    createMountsTopFrameSortConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";

const createSwingDoorMountsTopFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(space);

    return createMountsTopFrameSortConfig().qty(qty).build();
};

const createSwingDoorMountsSideFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const sides = 2;

    const qty = totalHeight * sides;

    return createMountsSideFrameSortConfig().qty(qty).build();
};

const createSwingDoorMountsBottomFrameSortConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const qty = totalWidth - doorWidth;

    return createMountsBottomFrameSortConfig().qty(qty).build();
};

const createSwingDoorMountsGlazingGasketConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);
    const hasTransom = MountsService.hasOverdoorTransom(space);

    const sides = 2;

    const doorDeduction = (totalWidth - (hasTransom ? 0 : doorWidth)) * sides; //15,84
    const horizontalGaskets = doorDeduction + (totalWidth - doorWidth) * sides; //47,52
    const verticalGaskets = totalHeight * sides;

    const qty = horizontalGaskets + verticalGaskets * sides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

const createSwingDoorMountsStripesConfig = (space: Space): IComponentConfig => {
    const qty = MountsService.getStipJointsQty(space);

    return createMountsStripesConfig().qty(qty).build();
};

export const CGS200SwingDoorsConfigs = (space: Space) => [
    createSwingDoorMountsTopFrameSortConfig(space),
    createSwingDoorMountsSideFrameSortConfig(space),
    createSwingDoorMountsBottomFrameSortConfig(space),
    createSwingDoorMountsGlazingGasketConfig(space),
    createSwingDoorMountsStripesConfig(space),
];
