<template>
  <select-dict
    v-model="overdoorType"
    :selectList="overdoorStructureTypes"
    :select-first-item="true"
  />
</template>

<script setup lang="ts">
import { computed, defineModel } from "vue";

import SelectDict from "@/components/UI/SelectDict.vue";

import { useDictStore } from "@/stores/dictStore";
import { useDoorsStore } from "@/stores/new/doors.store";

const overdoorType = defineModel<string>();

const dictStore = useDictStore();
const doorStore = useDoorsStore();

const doorType = computed(() => doorStore.activeDoor?.structureType || "");
const overdoorStructureTypes = dictStore.getStructureTypes([
  "filler",
  doorType.value,
]);
</script>

<style lang="scss" scoped></style>
