import { z } from "zod";

export class ValidationUtils {
    static nonEmpty(): z.ZodString {
        return z.string().trim().min(1);
    }

    static nonEmptyDimensions(): z.ZodObject<{
        width: z.ZodNumber;
        height: z.ZodNumber;
    }> {
        return z.object({
            width: z.number().min(1, {
                message: "Width must be greater then 0",
            }),
            height: z.number().min(1, {
                message: "Height must be greater then 0",
            }),
        });
    }

    static maxDoorHeight(): z.ZodObject<{
        height: z.ZodNumber;
    }> {
        return z.object({
            height: z.number().max(10, "Height can`t be greater then 10 ft."),
        });
    }

    static maxOverdoorHeight(): z.ZodObject<{
        height: z.ZodNumber;
    }> {
        return z.object({
            height: z.number().max(6, "Height can`t be greater then 10 ft."),
        });
    }
}
