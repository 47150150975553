import dayjs from "dayjs";
import { nanoid } from "nanoid";
import { Dimensions, OneDimension } from "../models/structure";
import Fraction from "fraction.js";

// display format

export function formatDateTime(
    value: number,
    format: string = "MM/DD/YYYY HH:mm",
): string {
    if (!value) return "";
    return dayjs(value).format(format);
}

export function formatDate(
    value: number,
    format: string = "MM/DD/YYYY",
): string {
    if (!value) return "";
    return dayjs(value).format(format);
}

export function formatMoney(value: number): string {
    console.log("formatMoney", value);
    if (!value) return "0.00";
    return Number(value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });
}

export function formatMoneyWithRound(value: number): string {
    if (!value) return "0.00";
    return Number(Math.round(value)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });
}

export function addTextToText(
    originText: string,
    addedText: string,
    delimiter: string = ", ",
): string {
    return (originText ? originText.toString() : "").concat(
        originText ? delimiter : "",
        addedText,
    );
}

// id generation

export function formatDimension(
    value: number,
    decimalPart: number = 2,
): string {
    if (!value) return "0.00";
    return Intl.NumberFormat("en", {
        style: "decimal",
        minimumFractionDigits: decimalPart,
        maximumFractionDigits: decimalPart,
    }).format(value);
}

export function roundTo(value: number, places: number = 2): number {
    const precision = Math.pow(10, places);
    return Math.round(value * precision) / precision;
}

export function generateCode(type: string = ""): string {
    return nanoid();
}

export function generateId(type: string = ""): number {
    return Date.now();
}

// dimensions calc functions

function mod(a, b): number {
    return (a - (a % b)) / b;
}

function normalizeDimension(value: OneDimension): OneDimension {
    return <OneDimension>{
        ft: value.ft ? value.ft : 0,
        inch: value.inch ? value.inch : 0,
        fractionA: value.fractionA ? value.fractionA : 0,
        fractionB: value.fractionB ? value.fractionB : 0,
    };
}

export function calcAreaFt(dimensions: Dimensions): number {
    return (
        (dimensionToInch(dimensions.width) *
            dimensionToInch(dimensions.height)) /
        144
    );
}

export function calcPerimerFt(dimensions: Dimensions): number {
    // main calculation unit is inch, so scaling and multiply to inches
    return dimensionToFt(
        dimensionMulti(dimensionAdd(dimensions.width, dimensions.height), {
            ft: 0,
            inch: 2,
            fractionA: 0,
            fractionB: 0,
        }),
    );
}

export function dimensionToFt(value: OneDimension): number {
    return dimensionToInch({ ...value }) / 12;
}

export function dimensionToInch(value: OneDimension): number {
    value = normalizeDimension({ ...value });
    if (value.fractionB === 0) {
        // devide by zero
        value.fractionB = 1;
    }

    return Fraction(value.fractionA, value.fractionB)
        .add(value.ft * 12 + value.inch)
        .valueOf();
}

/** for negative numbers return 0, 0.0625 = 1/16 */
export function inchToDimension(
    value: number,
    simplify: number = 0.0625,
): OneDimension {
    if (value <= 0) {
        return { ft: 0, inch: 0, fractionA: 0, fractionB: 0 };
    }
    const inchFraction = Fraction(value).simplify(simplify);
    const inchIntPart = mod(inchFraction.n, inchFraction.d);
    return <OneDimension>{
        ft: Math.floor(inchIntPart / 12),
        inch: Math.floor(inchIntPart) % 12,
        fractionA: inchFraction.n % inchFraction.d,
        fractionB: inchFraction.d === 1 ? 0 : inchFraction.d,
    };
}

export function dimensionAdd(a: OneDimension, b: OneDimension): OneDimension {
    return inchToDimension(dimensionToInch(a) + dimensionToInch(b));
}

export function dimensionSubtract(
    a: OneDimension,
    b: OneDimension,
): OneDimension {
    return inchToDimension(dimensionToInch(a) - dimensionToInch(b));
}

export function dimensionDiv(a: OneDimension, b: OneDimension): OneDimension {
    if (dimensionToInch(b) === 0) {
        return { ft: 0, inch: 0, fractionA: 0, fractionB: 0 };
    }
    return inchToDimension(dimensionToInch(a) / dimensionToInch(b));
}

export function dimensionMulti(a: OneDimension, b: OneDimension): OneDimension {
    return inchToDimension(dimensionToInch(a) * dimensionToInch(b));
}

export function dimensionScale(a: OneDimension, scale: number): OneDimension {
    return inchToDimension(dimensionToInch(a) * scale);
}

/**
 *
 * @param a OneDimension
 * @param b OneDimension
 * @return numeric result of comparition <0 -first param smaller, =0 equals, >0 second param smaller
 */
export function dimensionCompare(a: OneDimension, b: OneDimension): numeric {
    return dimensionToFt(a) - dimensionToFt(b);
}
