import { IComponentConfig } from "@/app/@types/base-component";
import {
    createDoubleFabricProducedDoorConfig,
    createHandleConfig,
    createLockConfig,
    createPatchesSetConfig,
    createRollerSetConfig,
    createSingleFabricProducedDoorConfig,
    createUHolderConfig,
} from "../../configs/hardwares.config";

import { Hardware } from "../../entities/hardware";
import { CreateManyStrategy } from "../base/creation.strategy";

const SINGLE_SWING_DOOR_HARDWARES_CONFIG: IComponentConfig[] = [
    createPatchesSetConfig().build(),
    createHandleConfig().build(),
    createLockConfig().valueNone().build(),
];

const DOUBLE_SWING_DOOR_HARDWARES_CONFIG: IComponentConfig[] = [
    createPatchesSetConfig().qty(2).build(),
    createHandleConfig().qty(2).build(),
    createLockConfig().valueNone().build(),
];

const SINGLE_SLIDING_DOOR_HARDWARES_CONFIG: IComponentConfig[] = [
    createRollerSetConfig().build(),
    createUHolderConfig().build(),
    createHandleConfig().build(),
    createLockConfig().valueNone().build(),
];

const DOUBLE_SLIDING_DOOR_HARDWARES_CONFIG: IComponentConfig[] = [
    createRollerSetConfig().qty(2).build(),
    createUHolderConfig().qty(2).build(),
    createHandleConfig().qty(2).build(),
    createLockConfig().valueNone().qty(2).build(),
];

const KOMPAS_SINGLE_HARDWARES_CONFIG: IComponentConfig[] = [
    createSingleFabricProducedDoorConfig().build(),
];

const KOMPAS_DOUBLE_HARDWARES_CONFIG: IComponentConfig[] = [
    createDoubleFabricProducedDoorConfig().build(),
];

export class SingleSwingDoorHardwareStrategy extends CreateManyStrategy {
    constructor() {
        super(SINGLE_SWING_DOOR_HARDWARES_CONFIG, Hardware);
    }
}

export class DoubleSwingDoorHardwareStrategy extends CreateManyStrategy {
    constructor() {
        super(DOUBLE_SWING_DOOR_HARDWARES_CONFIG, Hardware);
    }
}

export class SingleSlidingDoorHardwareStrategy extends CreateManyStrategy {
    constructor() {
        super(SINGLE_SLIDING_DOOR_HARDWARES_CONFIG, Hardware);
    }
}

export class DoubleSlidingDoorHardwareStrategy extends CreateManyStrategy {
    constructor() {
        super(DOUBLE_SLIDING_DOOR_HARDWARES_CONFIG, Hardware);
    }
}

export class KompasSingleDoorHardwareStrategy extends CreateManyStrategy {
    constructor() {
        super(KOMPAS_SINGLE_HARDWARES_CONFIG, Hardware);
    }
}

export class KompasDoubleDoorHardwareStrategy extends CreateManyStrategy {
    constructor() {
        super(KOMPAS_DOUBLE_HARDWARES_CONFIG, Hardware);
    }
}
