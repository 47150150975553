<template>
  <select-dict
    v-model="glassThinkness"
    :selectList="glassThinknessList"
    :select-first-item="true"
  />
</template>

<script setup lang="ts">
import { defineModel } from "vue";

import SelectDict from "@/components/UI/SelectDict.vue";

import { GlassThinknessService } from "@/app/services/glass-thinkess.service";

const glassThinknessService = new GlassThinknessService();
const glassThinknessList = glassThinknessService.getDefaultGlassThinkness();

const glassThinkness = defineModel<string>();
</script>

<style lang="scss" scoped></style>
