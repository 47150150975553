import { useDictStore } from "@/stores/dictStore";

import { PricingStrategy, PriceResult } from "../base/pricing.strategy";

export class AluminiumOverdoorPricingStrategy extends PricingStrategy {
    private dictStore = useDictStore();

    public getPrices(...args: unknown[]): PriceResult {
        const [aluminumCode] = args as [string];

        const pricesFromDict =
            this.dictStore.findAluminumFillerPrice(aluminumCode);

        if (!this.validatePricesFromDict(pricesFromDict)) {
            return this.getDefaultPrices();
        }

        return {
            price: pricesFromDict.price!,
            priceB2C: pricesFromDict.priceB2C!,
            priceB2B: pricesFromDict.priceB2B!,
        };
    }
}
