import { Constructor, SystemType } from "../@types/shared";
import { NonSteelSystemCodes, SteelSystemCodes } from "../constants";
import { ICreateManyStrategy } from "../domain/strategies/base/creation.strategy";
import {
    OverheadItemsDefaultStrategy,
    OverheadItemsWithoutDeliveryInstallationStrategy,
    OverheadItemsWithoutDeliveryStrategy,
    OverheadItemsWithoutInstallationStrategy,
    TreviRomanoOverheadItemsStrategy,
} from "../domain/strategies/creation/overhead.strategy";

export class OverheadItemsStrategyRegistry {
    private strategies = new Map<string, Constructor<ICreateManyStrategy>>();

    // prettier-ignore
    constructor() {
        this.register(NonSteelSystemCodes as SystemType[], "installation", "delivery", OverheadItemsDefaultStrategy);
        this.register(NonSteelSystemCodes as SystemType[], "no-installation", "delivery", OverheadItemsWithoutInstallationStrategy);
        this.register(NonSteelSystemCodes as SystemType[], "installation", "no-delivery", OverheadItemsWithoutDeliveryStrategy);
        this.register(NonSteelSystemCodes as SystemType[], "no-installation", "no-delivery", OverheadItemsWithoutDeliveryInstallationStrategy);

        this.register(SteelSystemCodes as SystemType[], "installation", "delivery", TreviRomanoOverheadItemsStrategy);
        this.register(SteelSystemCodes as SystemType[], "no-installation", "delivery", TreviRomanoOverheadItemsStrategy);
        this.register(SteelSystemCodes as SystemType[], "installation", "no-delivery", TreviRomanoOverheadItemsStrategy);
        this.register(SteelSystemCodes as SystemType[], "no-installation", "no-delivery", TreviRomanoOverheadItemsStrategy);
    }

    getStrategy = (
        systemCode: SystemType,
        installation: string,
        delivery: string,
    ): Constructor<ICreateManyStrategy> | null => {
        const key = this.createKey(systemCode, installation, delivery);
        return this.strategies.get(key) || null;
    };

    createInstallationKey = (installation: boolean) =>
        installation ? "installation" : "no-installation";

    createDeliveryKey = (delivery: boolean) =>
        delivery ? "delivery" : "no-delivery";

    private register = (
        systemCodes: SystemType[],
        installation: string,
        delivery: string,
        strategy: Constructor<ICreateManyStrategy>,
    ) => {
        for (const systemCode of systemCodes) {
            const key = this.createKey(systemCode, installation, delivery);
            this.strategies.set(key, strategy);
        }
    };

    private createKey = (
        systemCode: SystemType,
        installation: string,
        delivery: string,
    ): string => {
        return `${systemCode}-${installation}-${delivery}`;
    };
}
