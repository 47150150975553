import { Space } from "@/app/domain/entities/space";
import { createMountsRomanoFrameConfig } from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";

const createAnyDoorsMountsRomanoFrameConfig = (
    space: Space,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);

    const qty = totalWidth * totalHeight;

    return createMountsRomanoFrameConfig().qty(qty).build();
};

export const RomanoAnyDoorsConfigs = (space: Space) => [
    createAnyDoorsMountsRomanoFrameConfig(space),
];
