import { IStructureComponent } from "@/app/domain";

export class DimensionsService {
    static calculateEstimatePartitionsArea(
        partitions: IStructureComponent[],
    ): number {
        return partitions.reduce((totalAreaFt, partition) => {
            const areaFt = partition.dimensions.getAreaFt().toFt().getValue();
            const qty = partition.qty.value;

            return totalAreaFt + areaFt * qty;
        }, 0);
    }
}
